/**
 * Normalize the provided value, so we can ignore minor differences
 * between the search value and the actual content.
 */
const normalizeSearchValue = (value: string): string => value
  .normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '')
  .replace(/\s/g, '')
  .toLowerCase();

export const itemMatchesSearch = (stack: string, searchValue: string): boolean => {
  const query: string = normalizeSearchValue(searchValue);

  return normalizeSearchValue(stack).includes(query);
};
