<div class="modal-header" mat-dialog-title>
  <h2 i18n>Which element(s) would you like to copy to this folder?</h2>
</div>
<div class="modal-content" mat-dialog-content>
  <app-select-definition (changed)="onSelectDefinitionChanged($event)"
                         [config]="selectDefinitionConfig.CropGroups"></app-select-definition>
</div>
<div class="modal-footer" mat-dialog-actions>
  <button *ngIf="this.parents?.length > 0"
          mat-button
          class="backButton with-mat-icon"
          (click)="onStepBack()">
    <i class="fas fa-angle-left"></i>
    <ng-container i18n="@@back">Back</ng-container>
  </button>
  <div class="u-flex-divider"></div>
  <button mat-flat-button i18n="@@common.cancel" [mat-dialog-close]="false" class="close">Cancel</button>
  <button mat-flat-button
          color="accent"
          mat-dialog-close
          cdkFocusInitial
          (click)="copy()"
          [disabled]="!selectedDefinitions.length && !selectedFolders.length">
    <ng-container i18n="@@common.copy">Copy</ng-container>
  </button>
</div>
