import { DefinitionType } from '@LIB_UTIL/model/definitiontype';
import { FontIcon } from '@LIB_UTIL/model/fontIcon.model';
import { getDefinitionTypeIcon } from '@LIB_UTIL/util/icons';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-definition-icon',
  templateUrl: './definition-icon.component.html',
  styleUrls: ['./definition-icon.component.scss'],
})
export class DefinitionIconComponent implements OnInit {
  @Input() public definitionType: DefinitionType = DefinitionType.none;

  public fontIcon: FontIcon = null;

  public ngOnInit(): void {
    this.fontIcon = getDefinitionTypeIcon(this.definitionType);
  }
}
