import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetLanguages } from '@LG_ROOT/app/state/settings/settings.actions';
import { environment } from '@LIB_UTIL/environments/environment';
import { AuthService } from '@LIB_UTIL/auth/services/auth.service';
import { LocaleID } from '@LIB_UTIL/util/locale';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  constructor(private authService: AuthService, private store: Store) {
  }

  public openDeviceLocation(): void {
    const url: string = `${ environment.moduleShareUrl }/DeviceLocation/EditDeviceLocationPage.aspx`
      + `?sid=${ this.authService.sid }`;

    window.open(url, '_blank');
  }

  public openLetsGrowPreferences(locale: LocaleID): void {
    const url: string = `${ environment.newPlatformUrl }/${locale}/settings/platform`
      + `?sid=${ this.authService.sid }`;

    window.open(url, '_blank');
  }

  public initSettings(): void {
    if (!environment.embeddedMode) {
      this.store.dispatch(new GetLanguages());
    }
  }
}
