import { getLocale, LocaleID } from '../locale';
// eslint-disable-next-line no-restricted-imports
import { format } from 'date-fns';
import { zhCN } from 'date-fns/locale';
import { replaceExactMatch } from '../string';
import dayjs, { Dayjs } from 'dayjs';

/**
 * The default dat format as required by the backend.
 */
const apiDateFormat: string = 'yyyy-MM-dd\'T\'HH:mm:ss';

export const translatedUnitSeconds: string = $localize`:@@date.unitSeconds:second(s)`;


/**
 * Get the translated long names of all months (January, February, etc)
 */
export const localizedMonths = (locale: LocaleID): string[] =>
  [...Array(12).keys()]
    .map((num: number) => getLocale(locale).localize.month(num));


/**
 * Get the translated short names of all months (Jan, Feb, etc)
 */
export const localizedMonthsShort = (locale: LocaleID): string[] =>
  [...Array(12).keys()]
    .map((num: number) => getLocale(locale).localize.month(num, { width: 'abbreviated' }));


/**
 * Get the translated names of all week days (Sunday, Monday, Tuesday, etc)
 */
export const localizedWeekdays = (locale: LocaleID): string[] =>
  [...Array(7).keys()]
    .map((num: number) => getLocale(locale).localize.day(num));


/**
 * Localize the formatted date. This is useful for the
 * date-fns P or p format which are formatted differently
 * depending on the app's locale.
 *
 * @example
 * 30-10-2012 or 10/30/2021
 * 23:00 or 11:00 pm
 *
 * To make sure dates are always displayed correctly the
 * user should avoid using the default date-fns format and
 * use localizedFormat instead.
 *
 * @doc https://date-fns.org/v2.20.1/docs/format
 */
export const localizedFormat = (date: Date, dateFormat: string, locale: LocaleID): string =>
  formatCheckChinese(date, dateFormat, getLocale(locale));


/**
 * Because date-fns does not format chinese properly, we have change the format string,
 * so it shows the Chinese date formats better
 */
const formatCheckChinese = (date: Date, dateFormat: string, locale: Locale): string => {
  if (locale !== zhCN) {
    return format(date, dateFormat, { locale: locale });
  }

  let newFormat: string = replaceExactMatch(dateFormat, 'PP', 'PPP');
  newFormat = replaceExactMatch(newFormat, 'P', 'PPP');
  newFormat = replaceExactMatch(newFormat, 'pp', 'kk:mm:ss');
  newFormat = replaceExactMatch(newFormat, 'p', 'kk:mm');

  return format(date, newFormat, { locale: locale });
};

export const formatNoteDates = (date: Date, dateFormat: string, localeId: LocaleID): string => {
  const locale: Locale = getLocale(localeId);
  if (locale !== zhCN) {
    dateFormat = `EEEE ${ dateFormat}`;

    return format(date, dateFormat, { locale: locale });
  }

  let newFormat: string = replaceExactMatch(dateFormat, 'P', 'PPP');
  newFormat = replaceExactMatch(newFormat, 'p', 'kk:mm');
  newFormat = `EEEE ${ newFormat}`;

  return format(date, newFormat, { locale: locale });
};

/**
 * Convert the Date object to a string.
 *
 * @example
 * 06/29/2021
 */
export const formatDateToPeriodFormat = (date: Date | Dayjs = new Date(), locale: LocaleID): string => {

  // In case of Dayjs object, convert to Date object
  if (dayjs.isDayjs(date)) {
    date = (date as Dayjs).toDate();
  }

  // Force typing to Date object
  date = date as Date;

  return localizedFormat(date, 'P', locale);
};

/**
 * Helper method to normalize the full date format.
 */
export function formatDateToFullDateFormat(date: Date | number): string {
  return format(date, 'yyyy-MM-dd HH:mm:ss');
}

/**
 * Helper method to normalize the time format.
 */
export function formatDateToTime(date: Date | Dayjs = new Date(), locale: LocaleID): string {

  // In case of Dayjs object, convert to Date object
  if (dayjs.isDayjs(date)) {
    date = (date as Dayjs).toDate();
  }

  // Force typing to Date object
  date = date as Date;

  return localizedFormat(date, 'p', locale);
}

/**
 * Convert the provided variable to the format as required
 * by the backend.
 */
export const formatDateToApiFormat = (date: Date | Dayjs = new Date()): string => {

  // In case of Dayjs object, convert to Date object
  if (dayjs.isDayjs(date)) {
    date = (date as Dayjs).toDate();
  }

  // Force typing to Date object
  date = date as Date;

  return format(date, apiDateFormat);
};

/**
 * The date-fns format method formats the date as local time. The server returns
 * the timestamps as UTC date. So we have to correct the date for date-fns.
 *
 * @see /docs/date-fns-format-unix.md
 */
export const formatUnixTimestamp = (unix: number, dateFormat: string, locale: LocaleID): string => {
  // Correct the offset of the server.

  const correctedDate: Date = new Date(unix);
  const newDate: Date = new Date(
    correctedDate.getUTCFullYear(),
    correctedDate.getUTCMonth(),
    correctedDate.getUTCDate(),
    correctedDate.getUTCHours(),
    correctedDate.getUTCMinutes(),
    correctedDate.getUTCSeconds()
  );

  // Now date-fns will output the date correctly.
  return localizedFormat(newDate, dateFormat, locale);
};

/**
 * Format the unix timestamp from the backend to the api format.
 *
 * @example 2021-04-10T00:00:00
 */
export function formatUnixToApiFormat(unix: number): string {
  return formatUnixTimestamp(unix, apiDateFormat, 'en');
}

/**
 * Format the unix timestamp from the backend to the signalR format.
 *
 * @example 2021-04-10T00:00:00Z
 */
export function formatUnixToSignalRFormat(unix: number): string {
  return formatUnixTimestamp(unix, 'yyyy-MM-dd\'T\'HH:mm:ss\'Z\'', 'en');
}
