import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProfileStateModel } from './profile.state.model';
import { DeleteAnnouncement, DeletePhoto, GetAnnouncements, SetPhoto, UpdateAnnouncementRead } from './profile.state.actions';
import { tap } from 'rxjs/operators';
import { UpdatePhotoAction } from '@LIB_UTIL/auth/state/auth.actions';
import { UsersApiService } from '@LIB_UTIL/services/users-api/users-api.service';
import { AnnouncementsModel } from '@LIB_UTIL/services/users-api/users-api.model';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { profileStateDefaults } from './profile.state.defaults';

@State<ProfileStateModel>({
  name: 'profile',
  defaults: profileStateDefaults,
})
@Injectable()
export class ProfileState {
  constructor(
    private usersApiService: UsersApiService,
    private store: Store
  ) {
  }

  @Selector() public static announcements(state: ProfileStateModel): AnnouncementsModel[] {
    return state.announcements;
  }
  @Selector() public static unreadAnnouncements(state: ProfileStateModel): number {
    return state.announcements.filter(a => !a.isRead).length;
  }


  @Action(SetPhoto)
  public setPhoto(
    {}: StateContext<ProfileStateModel>,
    { photoBase64 }: SetPhoto
  ): Observable<Object> {
    return this.usersApiService.uploadPhoto(photoBase64)
      .pipe(tap((response) => {
        // success, update the photo in the store (maintained by auth service)
        this.store.dispatch(new UpdatePhotoAction(response['photoBase64']));
      }));
  }

  @Action(DeletePhoto)
  public deletePhoto({}: StateContext<ProfileStateModel>): Observable<void> {
    return this.usersApiService.deletePhoto()
      .pipe(tap(() => {
        // success, update the photo in the store (maintained by auth service)
        this.store.dispatch(new UpdatePhotoAction(null));
      }));
  }

  @Action(GetAnnouncements)
  public getAnnouncements({ patchState }: StateContext<ProfileStateModel>): Observable<AnnouncementsModel[]> {
    return this.usersApiService.getAnnouncements()
      .pipe(
        tap((announcements: AnnouncementsModel[]) => patchState({ announcements: announcements }))
      );
  }

  @Action(UpdateAnnouncementRead)
  public updateAnnouncementRead(
    { setState }: StateContext<ProfileStateModel>,
    { announcementId }: UpdateAnnouncementRead
  ): Observable<void> {
    return this.usersApiService.updateAnnouncementRead(announcementId)
      .pipe(tap(() => {
        setState(
          patch({
            announcements: updateItem<AnnouncementsModel>(item => item.id === announcementId, patch({ isRead: true })),
          })
        );
      }));
  }

  @Action(DeleteAnnouncement)
  public deleteAnnouncement(
    { setState }: StateContext<ProfileStateModel>,
    { announcementId }: DeleteAnnouncement
  ): Observable<void> {
    return this.usersApiService.deleteAnnouncement(announcementId)
      .pipe(tap(() => {
        setState(
          patch({
            announcements: removeItem<AnnouncementsModel>(item => item.id === announcementId),
          })
        );
      }));
  }
}
