<button *ngIf="showMobileMenuToggle"
        mat-icon-button
        class="hamburger-menu u-hide-lap"
        (click)="toggleNavigation()">
  <mat-icon fontSet="fas" fontIcon="fa-bars"></mat-icon>
</button>

<div class="content" (mouseup)="onMouseUp($event)">
  <ng-content></ng-content>
</div>

<button sel-id="support-button" *ngIf="showSupportButton$ | async" mat-flat-button class="support with-mat-icon" (click)="toggleZoho()"
  sel-id="dashboard-header-button-support">
  <mat-icon fontSet="fas" fontIcon="fa-circle-question"></mat-icon>
  <span class="u-hide-mobile" i18n="@@support">Support</span>
</button>

<lib-profile-menu *ngIf="(userInfo$ | async) && !hideProfileMenu" [showSettingsLink]="showSettingsLink"></lib-profile-menu>
