import { DefinitionType } from '@LIB_UTIL/model/definitiontype';
import { FontIcon } from '@LIB_UTIL/model/fontIcon.model';

export const getDefinitionTypeIcon = (definitionType: DefinitionType): FontIcon => {
  switch (definitionType) {
    case DefinitionType.export:
      return { fontSet: 'fas', icon: 'fa-file-export' };
    case DefinitionType.graph:
      return { fontSet: 'fak', icon: 'fa-graph' };
    case DefinitionType.gauge:
      return { fontSet: 'fak', icon: 'fa-gauge-type-circular' };
    case DefinitionType.report:
      return { fontSet: 'fas', icon: 'fa-file-lines' };
    case DefinitionType.plan:
      return { fontSet: 'fak', icon: 'fa-plan' };
    case DefinitionType.dataentry:
      return { fontSet: 'fas', icon: 'fa-hand' };
    case DefinitionType.url:
      return { fontSet: 'fas', icon: 'fa-link' };
    case DefinitionType.dashboard:
      return { fontSet: 'fak', icon: 'fa-dashboard' };
    case DefinitionType.cropmanager:
      return { fontSet: 'fas', icon: 'fa-leaf' };
    default:
      // case DefinitionType.none:
      // case DefinitionType.export:
      // case DefinitionType.imeteoscope:
      // case DefinitionType.teleview:
      // case DefinitionType.userprofile:
      // case DefinitionType.visualization:
      // case DefinitionType.cropgroupui:
      // case DefinitionType.announcement:
      return { fontSet: 'fas', icon: 'fa-square-question' };
  }
};
