import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { ConfigService } from '@LIB_UTIL/services/config/config.service';
import { CopyDefinition } from '@LG_CORE/page-title/shared.model';
import { Observable } from 'rxjs';
import { SharedCropGroup, PostCropGroupDefinitionResponse, UpdateCropGroupStatus } from '@LG_ROOT/app/core/crop-groups/crop-groups.model';
import { SharedItemUser } from '@LG_ROOT/app/core/shared/user.model';
import { DashboardTileTypes } from '@LG_CORE/dashboards/dashboards.model';
import { ShareRequest } from '../shared/share.model';
import { DefinitionType } from '@LIB_UTIL/model/definitiontype';
import { Role } from '@LIB_UTIL/model/role.model';

@Injectable({
  providedIn: 'root',
})
export class CropGroupsApiService {

  constructor(private http: HttpClient, private cfg: ConfigService) {
  }

  public getCropGroup(cropGroupId?: number, slimMode: boolean = false, archiveYear: number = null) {
    const query: string = slimMode ? '?mode=slim' : '?mode=full';

    return archiveYear
      ? this.http.get(`${ this.cfg.endpoints.cropgroups }/archive/${ archiveYear }`)
      : this.http.get(`${ this.cfg.endpoints.cropgroups }/${ cropGroupId ? cropGroupId : '' }${ query }`);
  }

  /**
   * Get crop groups including archived ones
   */
  public getAllCropGroups() {
    return this.http.get(`${ this.cfg.endpoints.cropgroups }/all`);
  }

  public getCropGroupArchives() {
    return this.http.get(`${ this.cfg.endpoints.cropgroups }/archive`)
      .pipe(map((response: any) => response.archives));
  }

  public getCropGroupFolder(cropGroupId: number, folderId: number) {
    return this.http.get(`${ this.cfg.endpoints.cropgroups }/${ cropGroupId }/folders/${ folderId }?mode=full`);
  }

  public addCropGroupFolder(cropGroupId: number, parentId: number = null, name: string) {
    return this.http.post(`${ this.cfg.endpoints.cropgroups }/${ cropGroupId }/folders`, {name, parentId});
  }

  public addCropGroupDefinition(
    cropGroupId: number,
    folderId: number,
    name: string, type: DefinitionType
  ): Observable<PostCropGroupDefinitionResponse> {
    const url = `${ cropGroupId }/${ folderId ? `folders/${ folderId }/` : '' }definitions`;

    return this.http.post<PostCropGroupDefinitionResponse>(`${ this.cfg.endpoints.cropgroups }/${ url }`, {
      name: name,
      type: type,
    });
  }

  public copyDefinitionToCropGroup(cropGroupId: number, folderId: number, definitionsAndFoldersToCopy: CopyDefinition) {
    const url = `${ this.cfg.endpoints.cropgroups }/${ cropGroupId }/${ folderId ? `folders/${ folderId }/` : '' }copy`;

    return this.http.post(url, definitionsAndFoldersToCopy);
  }

  public deleteCropGroupFolder(cropGroupId: number, folderId: number) {
    return this.http.delete(`${ this.cfg.endpoints.cropgroups }/${ cropGroupId }/folders/${ folderId }`);
  }

  public deleteCropGroupDefinition(cropGroupId: number, folderId: number, definitionId: number) {
    const endpoint: string = this.cfg.endpoints.cropgroups;

    return this.http.delete(`${ endpoint }/${ cropGroupId }/${ folderId ? `folders/${ folderId }/` : '' }definitions/${ definitionId }`);
  }

  /**
   * Remove multiple users from one crop group
   */
  public removeCropGroupUsers(cropGroupId: number, userIds: number[]): Observable<Object> {
    const endpoint: string = this.cfg.endpoints.cropgroups;

    return this.http.delete(`${ endpoint }/${ cropGroupId }/users`, {
      params: {
        ids: userIds,
      },
    });
  }

  /**
   * Remove a specified user from multiple crop groups
   */
  public removeUserFromCropGroups(userId: number, cropGroupIds: number[]): Observable<void> {
    const endpoint: string = this.cfg.endpoints.cropgroups;

    return this.http.delete<void>(`${ endpoint }/users/${ userId }`, {
      params: {
        cropgroupIds: cropGroupIds,
      },
    });
  }

  public renameFolder(cropGroupId: number, folderId: number, name: string) {
    return this.http.put(`${ this.cfg.endpoints.cropgroups }/${ cropGroupId }/folders/${ folderId }`, {name});
  }

  public renameDefinition(cropGroupId: number, folderId: number, definitionId: number, name: string) {
    const endpoint: string = this.cfg.endpoints.cropgroups;

    return this.http.put(`${ endpoint }/${ cropGroupId }/${ folderId ? `folders/${ folderId }/` : '' }definitions/${ definitionId }`, {name});
  }

  public getAvailableDefinitionTypesInCropGroup(cropGroupId: number): Observable<DashboardTileTypes[]> {
    const url = `${ this.cfg.endpoints.cropgroups }/${ cropGroupId }/availabledefinitiontypes`;

    return this.http.get<DashboardTileTypes[]>(url);
  }

  public updateCropGroupStatus(cropGroupId: number, newStatus: UpdateCropGroupStatus): Observable<void> {
    const url = `${ this.cfg.endpoints.cropgroups }/${ cropGroupId }/status`;
    const body: UpdateCropGroupStatus = newStatus;

    return this.http.patch<void>(url, body);
  }

  /**
   * Request a list of users that I share at least one crop group with (except 'Live Meteo')
   */
  public getSharedCropgroupUsers(): Observable<SharedItemUser[]> {
    return this.http.get<SharedItemUser[]>(`${ this.cfg.endpoints.cropgroups }/users`);
  }

  /**
   * Get the crop groups that a specified user shares with me
   */
  public getSharedCropGroups(userId: number): Observable<SharedCropGroup[]> {
    return this.http.get<SharedCropGroup[]>(`${ this.cfg.endpoints.cropgroups }/shared/${ userId }`);
  }

  /**
   * Change the role of multiple users for one crop group
   */
  public changeUserRoles(cropGroupId: number, userIds: number[], newRole: Role): Observable<void> {
    const endpoint: string = this.cfg.endpoints.cropgroups;

    return this.http.post<void>(`${ endpoint }/${ cropGroupId }/role/${ newRole.toString() }`, {}, {
      params: {
        userIds: userIds,
      },
    });
  }

  /**
   * Activate module using an activation code
   */
  public activateCropGroup(activationCode: string): Observable<void> {
    return this.http.post<void>(`${ this.cfg.endpoints.cropgroups }/activate/${ activationCode }`, null);
  }

  public addMembersToCropgroups(shareRequest: ShareRequest): Observable<void> {
    return this.http.post<void>(`${ this.cfg.endpoints.cropgroups }/invite`, shareRequest);
  }
}
