import { DefinitionType } from '@LIB_UTIL/model/definitiontype';
import { Role } from '@LIB_UTIL/model/role.model';

export interface Definition {
  id: number;
  name: string;
  type: DefinitionType;
  url: string;
  configurationUrl?: string; // Only set for just created items.
  role: Role;
  canBeMailed: boolean;
  editMode?: boolean;
  hidden: boolean;
  path: string;
}

export interface BreadCrumbsParent {
  id: number;
  name: string;
}

export interface Parent {
  id: string;
  name: string;
  type: string;
}

export interface CopyDefinition {
  fromCropGroupId: number;
  fromModuleTemplateId: number;
  folderIds: string[];
  definitionIds: number[];
}

export const defintionDefType = (type: string): number => ({
  graph: 1,
  dir: 2,
  export: 3,
  imeteoscope: 4,
  url: 5,
  teleview: 6,
  comparecrops: 7,
  userprofile: 8,
  report: 9,
  cropmanager: 10,
  visualization: 11,
  dataentry: 13,
  cropgroupui: 14,
  dashboard: 15,
  plan: 16,
  gauge: 17,
  announcement: 18,
})[String(type).toLowerCase()] || 0;

export enum SelectDefinitionConfig {
  Folder,
  CropGroups,
  Dashboard,
  FolderFromCropGroups
}

export enum SelectDefinitionSourceType {
  Personal,
  CropGroup,
  Module
}
