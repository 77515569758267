export enum DefinitionType {
  none = 'none',
  graph = 'graph',
  gauge = 'gauge',
  report = 'report',
  plan = 'plan',
  dataentry = 'dataentry',
  dir = 'dir',
  export = 'export',
  imeteoscope = 'imeteoscope',
  url = 'url',
  teleview = 'teleview',
  comparecrops = 'comparecrops',
  userprofile = 'userprofile',
  cropmanager = 'cropmanager',
  visualization = 'visualization',
  cropgroupui = 'cropgroupui',
  dashboard = 'dashboard',
  announcement = 'announcement',
  kpi = 'kpi',
}
