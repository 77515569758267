import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { get } from 'lodash';
import { KpiStateModel } from '@LG_ROOT/app/state/kpi/kpi.state.model';
import { KpiApiService } from '@LG_CORE/Kpi/kpi-api.service';
import {
  AddKpiAction,
  CopyKpisAction,
  DeleteKpiAction,
  GetKpisAction,
  RenameKpiAction
} from '@LG_ROOT/app/state/kpi/kpi.actions';
import { KpiDefinitionModel, KpisGroupModel } from '@LIB_UTIL/kpi/model/kpi.model';
import { map } from 'rxjs/operators';

@State<KpiStateModel>({
  name: 'kpi',
  defaults: {
    kpiGroups: [],
    kpis: [],
  },
})

@Injectable()
export class KpiState {

  @Selector()
  public static kpis(state: KpiStateModel): KpiDefinitionModel[] {
    return state.kpis;
  }

  @Selector()
  public static kpisGroups(state: KpiStateModel): KpisGroupModel[] {
    return state.kpiGroups;
  }

  constructor(
    private kpiApiService: KpiApiService
  ) {
  }

  @Action(GetKpisAction)
  public getKpis(ctx: StateContext<KpiStateModel>): Observable<KpiDefinitionModel[]> {
    return this.kpiApiService.getKpis().pipe(
      tap((kpis: KpiDefinitionModel[]) => {
        if (kpis?.length > 0) {
          let kpisArray: KpiDefinitionModel[] = [...(Array.isArray(kpis) ? kpis : [])];
          let kpisGroupModel: KpisGroupModel
            = { kpis: kpisArray, name: 'KPIS GROUP PLACEHOLDER', id: 0, expanded: true };

          ctx.patchState({
            kpis: kpisArray,
            kpiGroups: [kpisGroupModel],
          });

        } else {
          const kpisState: KpiStateModel = get(ctx.getState(), 'kpis', {});
          ctx.patchState({
            ...kpisState,
          });
        }
      }));
  }

  @Action(AddKpiAction)
  public addKpi(ctx: StateContext<KpiStateModel>,
    { kpiDefinitionConfigurationModel }: AddKpiAction): Observable<KpiDefinitionModel> {
    return this.kpiApiService.addKpi(kpiDefinitionConfigurationModel).pipe(
      tap((newKpiDefinitionConfigurationModel: KpiDefinitionModel) => {
        const kpisState: KpiStateModel = get(ctx.getState(), 'kpis', {});
        if (newKpiDefinitionConfigurationModel) {
          let kpisArray: KpiDefinitionModel[]
            = [...(Array.isArray(kpisState) ? kpisState : []), newKpiDefinitionConfigurationModel];
          let kpisGroupModel: KpisGroupModel
            = { kpis: kpisArray, name: 'KPIS GROUP PLACEHOLDER', id: 0, expanded: true };
          ctx.patchState({
            kpis: kpisArray,
            kpiGroups: [kpisGroupModel],
          });
        } else {
          ctx.patchState({
            ...kpisState,
          });
        }
      }),
      map((newKpiDefinitionConfigurationModel: KpiDefinitionModel) => {
        return newKpiDefinitionConfigurationModel;
      }));
  }

  @Action(CopyKpisAction)
  public copyKpis(ctx: StateContext<KpiStateModel>,
    { kpiDefinitionIds }: CopyKpisAction): Observable<KpiDefinitionModel[]> {
    return this.kpiApiService.copyKpis(kpiDefinitionIds).pipe(
      tap((newKpis: KpiDefinitionModel[]) => {
        const kpisState: KpiStateModel = get(ctx.getState(), 'kpis', {});
        if (newKpis.length > 0) {
          const kpisArray: KpiDefinitionModel[]
            = [...(Array.isArray(kpisState) ? kpisState : []), ...newKpis];
          const kpisGroupModel: KpisGroupModel
            = { kpis: kpisArray, name: 'KPIS GROUP PLACEHOLDER', id: 0, expanded: true };
          ctx.patchState({
            kpis: kpisArray,
            kpiGroups: [kpisGroupModel],
          });
        }
      }),
      map((newKpiIds: KpiDefinitionModel[]) => {
        return newKpiIds;
      }));
  }

  @Action(DeleteKpiAction)
  public deleteKpi(ctx: StateContext<KpiStateModel>,
    { kpiDefinitionId }: DeleteKpiAction): Observable<boolean> {

    return this.kpiApiService.deleteKpi(kpiDefinitionId).pipe(
      tap((result: boolean) => {
        if (result) {
          const kpisState: KpiStateModel = get(ctx.getState(), 'kpis', {});
          let kpisArray: KpiDefinitionModel[] = [...(Array.isArray(kpisState) ? kpisState : [])];
          kpisArray = kpisArray.filter(kpi => kpi.id !== kpiDefinitionId);
          let kpisGroupModel: KpisGroupModel
            = { kpis: kpisArray, name: 'KPIS GROUP PLACEHOLDER', id: 0, expanded: true };

          ctx.patchState({
            kpis: kpisArray,
            kpiGroups: [kpisGroupModel],
          });
        }

        return result;
      }));
  }

  @Action(RenameKpiAction)
  public renameKpi(ctx: StateContext<KpiStateModel>,
    { kpiDefinitionId, newName }: RenameKpiAction): Observable<boolean> {
    return this.kpiApiService.renameKpi(kpiDefinitionId, newName).pipe(
      tap((result: boolean) => {
        if (result) {
          const kpisState: KpiStateModel = get(ctx.getState(), 'kpis', {});
          let kpisArray: KpiDefinitionModel[] = [...(Array.isArray(kpisState) ? kpisState : [])];

          kpisArray = kpisArray.map((kpiDefinitionModel: KpiDefinitionModel) => {
            return ({
              ...kpiDefinitionModel,
              name: kpiDefinitionModel.id === kpiDefinitionId ? newName : kpiDefinitionModel.name,
            });
          });

          let kpisGroupModel: KpisGroupModel
            = { kpis: kpisArray, name: 'KPIS GROUP PLACEHOLDER', id: 0, expanded: true };

          ctx.patchState({
            kpis: kpisArray,
            kpiGroups: [kpisGroupModel],
          });

        }

        return result;
      }));
  }
}
