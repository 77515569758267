<form [formGroup]="form" class="overlay-form">

  <div class="overlay-form__calenders u-flex"
       [class.tab__container__calenders--show-on-mobile]="form.get('periodType').value === periodType.Custom">

    <!-- Calendar for mobile verson, we set linked calendars to false, to ensure that only one calendar is shown-->
    <div *ngIf="showMobileCalendar"
      [class.hide-time-selector]="form.get('showWholeDays').value">
      <ngx-daterangepicker-material
        [autoApply]="true"
        [customRangeDirection]="false"
        [alwaysShowCalendars]="false"
        [linkedCalendars]="false"
        [showISOWeekNumbers]="true"
        [showCustomRangeLabel]="false"
        [showDropdowns]="true"
        [ranges]="null"
        [timePicker]="showTimeSelector"
        [timePickerIncrement]="5"
        [timePicker24Hour]="true"
        [startDate]="form.get('startDate').value"
        [endDate]="singleDate ? form.get('startDate').value : form.get('endDate').value"
        [locale]="datepickerLocale"
        [minDate]="toDayJs(minDate)"
        [maxDate]="toDayJs(maxDate)"
        [singleDatePicker]="singleDate"
        [showSingleCalendar]="true"
        (startDateChanged)="onSelectDate($event)"
        (endDateChanged)="onSelectDate($event)"
      ></ngx-daterangepicker-material>
    </div>

    <!-- Calendar for non-mobile version-->
    <div *ngIf="!showMobileCalendar" class="gap-20"
      [class.hide-time-selector]="form.get('showWholeDays').value">
      <ngx-daterangepicker-material #startPicker
        [autoApply]="true"
        [customRangeDirection]="false"
        [alwaysShowCalendars]="false"
        [showISOWeekNumbers]="true"
        [showCustomRangeLabel]="false"
        [showDropdowns]="true"
        [ranges]="null"
        [timePicker]="showTimeSelector"
        [timePickerIncrement]="5"
        [timePicker24Hour]="true"
        [splitCalendars]="true"
        [startDate]="form.get('startDate').value"
        [endDate]="singleDate ? form.get('startDate').value : form.get('endDate').value"
        [singleDatePicker]="singleDate"
        [locale]="datepickerLocale"
        [minDate]="toDayJs(minDate)"
        [maxDate]="toDayJs(maxDate)"
        (startDateChanged)="onSelectStartDate($event)"
        (endDateChanged)="onSelectDate($event)"
      ></ngx-daterangepicker-material>
    </div>

  </div>
  <div class="overlay-form__fields">
    <div class="row">
      <div>
        <label *ngIf="!singleDate" i18n >From</label>
        <label *ngIf="singleDate && !showTimeSelector" i18n="@datePicker.selectedDate">Selected date</label>
        <label *ngIf="singleDate && showTimeSelector" i18n="@datePicker.selectedDateAndTime">Selected date / time</label>
        <div>
          {{ startDate }} <span class="time u-hide-desk">&nbsp; {{ startTime }}</span>
        </div>
        <div *ngIf="showTimeSelector" class="time u-hide-max-lap">
          {{ startTime }}
        </div>
      </div>
      <div *ngIf="!singleDate">
        <label i18n>To</label>
        <div>
          {{ endDate }} <span class="time u-hide-desk">&nbsp; {{ endTime }}</span>
        </div>
        <div *ngIf="showTimeSelector" class="time u-hide-max-lap">
          {{ endTime }}
        </div>
      </div>
    </div>

    <div class="row validation-error u-bold" *ngIf="showEndBeforeStartError" i18n="@@error.endDateBeforeStartDate">
      The end date is before the start date.
    </div>

    <div class="row validation-error u-bold" *ngIf="showOutOfRangeError" i18n="@@error.invalidDateSelected">
      An invalid date has been selected.
    </div>

    <div class="row u-flex show-whole-days" *ngIf="!singleDate && showTimeSelector" [class.u-hide]="form.get('periodType').value !== periodType.Custom">
      <mat-checkbox id="showWholeDays" class="u-bold" formControlName="showWholeDays"></mat-checkbox>
      <label for="showWholeDays" class="u-bold" i18n="@@datePicker.showWholeDays" (change)="sendMixPanelEvent('showWholeDays');">Show whole days</label>
    </div>

    <div class="row no-margin" *ngIf="showPeriodSelector">
      <div>
        <label for="range" class="u-hide-max-lap" i18n>Choose a period</label>
        <mat-form-field>
          <mat-select id="range" formControlName="periodType">
            <mat-option [value]="periodType.Last24Hours" i18n>Last 24 hours</mat-option>
            <mat-option [value]="periodType.LastWeek" i18n>Last week</mat-option>
            <mat-option [value]="periodType.Last4Weeks" i18n>Last 4 weeks</mat-option>
            <mat-option [value]="periodType.LastHalfYear" i18n>Last half year</mat-option>
            <mat-option [value]="periodType.LastYear" i18n>Last year</mat-option>
            <mat-option [value]="periodType.Custom" i18n>Custom period</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>


    <div class="row u-hide-max-lap pin-button u-flex" *ngIf="enablePin">
      <div class="u-flex-divider"></div>
      <button *ngIf="!!form.get('pinnedPeriodType').value; else pinPeriod"
              (click)="emitPinReset(); sendMixPanelEvent('unPinPeriod');"
              mat-flat-button
              type="button"
              class="btn-pin">
          <div class="u-flex u-center-content-vertically">
            <lib-pin-icon [size]="14"></lib-pin-icon>
            <div i18n>Unpin period</div>
          </div>
      </button>
      <ng-template #pinPeriod>
        <button (click)="emitPin(); sendMixPanelEvent('pinPeriod');"
                mat-flat-button
                type="button"
                class="btn-pin u-flex">
          <div class="u-flex u-center-content-vertically">
            <lib-pin-icon [size]="14"></lib-pin-icon>
            <div i18n>Pin period</div>
          </div>
          <!-- <mat-icon fontSet="fas" fontIcon="fa-thumbtack" class="pin-icon"></mat-icon> -->
        </button>
      </ng-template>
    </div>

    <div class="u-flex-divider"></div> <!--Push buttons to the bottom-->

    <div class="u-flex" [hidden]="!form.dirty">
      <div class="u-flex-divider"></div>
      <button (click)="emitCancel()" class="btn-cancel" type="button" mat-flat-button i18n="@@common.cancel">Cancel</button>
      <button (click)="emitSubmit()"
              [disabled]="form.invalid || showEndBeforeStartError || showOutOfRangeError"
              type="button"
              mat-flat-button
              color="accent"
              i18n="@@common.apply"
              sel-id="dashboard-calendar-button-apply">Apply</button>
    </div>
  </div>
</form>
