import {
  DashboardCropModel,
  DashboardModel,
  DashboardSelectionDashboardModel,
  DashboardSelectionGroupModel,
  DashboardTileModel,
  DashboardTileTypes,
  DashboardTileTypeShortcutModel
} from '@LG_ROOT/app/core/dashboards/dashboards.model';
import { DashboardStateModel } from './dashboard.state.model';


/**
 * When the user selects another crop for the dashboard, this crop should be also selected
 * in all its tiles, except when the tile has a pinned crop.
 */
export const setSelectedCropInTiles = (dashboardData: DashboardModel, cropId: number): DashboardModel => {

  return {
    ...dashboardData,
    tiles: dashboardData.tiles.map((tile: DashboardTileModel) => setSelectedCropIdInTile(tile, cropId)),
  };
};


/**
 * Set the selected crop in a tile, but only if the tile contains the crop, and no crop has been pinned yet.
 */
// eslint-disable-next-line complexity,max-lines-per-function
export const setSelectedCropIdInTile = (tile: DashboardTileModel, cropId: number): DashboardTileModel => {

  // do this only for shortcut tiles
  if (tile.type !== DashboardTileTypes.shortcut) {
    return tile;
  }

  let tileData: DashboardTileTypeShortcutModel = { ...tile.data } as DashboardTileTypeShortcutModel;

  // If the tile has a pinned crop id, don't do anything, this overrides the dashboard selection.
  // Except when the user selects the same crop as has been pinned in the tile.
  if (!tileData?.crops || tileData?.crops?.length === 0 || tileData.pinnedCropId > 0 && tileData.pinnedCropId !== cropId
    && tileData.crops.some((crop: DashboardCropModel) => crop.id === cropId)) {
    return tile;
  }

  // the crop should be in the list of crops that the tile contains, set pinned crop as selected crop
  let foundCrop: DashboardCropModel = tileData.crops?.find((crop: DashboardCropModel) => crop.id === cropId);

  //fallback: if no crop found, then take default pinned or selected crop.
  if (!foundCrop) {
    foundCrop = tileData.crops?.find((crop: DashboardCropModel) => crop.isPinned);
    if (!foundCrop) {
      foundCrop = tileData.crops?.find((crop: DashboardCropModel) => crop.isSelected);
    }
  }

  if (foundCrop) {
    cropId = foundCrop.id;
    const cropName: string = tileData.crops.find((crop: DashboardCropModel) => crop.id === cropId)?.name;

    if (cropName) {
      tileData.selectedCropName = `(${ cropName })`;
    }
  } else {
    tileData.selectedCropName = '';
  }

  return {
    ...tile,
    data: {
      ...tileData,
      crops: tileData.crops.map((crop: DashboardCropModel) => ({
        ...crop,
        isSelected: crop.id === cropId,
      })
      ),
    },
  };
};


export const getAllDashboards = (state: DashboardStateModel): DashboardSelectionDashboardModel[] => {
  return state.dashboards.dashboardGroups.flatMap((group: DashboardSelectionGroupModel) =>
    group.dashboards
  );
};
