<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOpen]="isMenuOpen"
  [cdkConnectedOverlayPositions]="overlayPositions()"
  cdkConnectedOverlayPanelClass="date-range-picker-overlay"
  (backdropClick)="emitCancel()"
>
  <div class="date-range-picker-overlay__container">

    <!-- dual mode provides a popup where you can select either a period or a single date/time. -->
    <div *ngIf="dualMode" class="tabs">
      <div [class.active]="activeTab === 0" (click)="changeTab(0)" i18n="@@datepicker.period">Period</div>
      <div [class.active]="activeTab === 1" (click)="changeTab(1)" i18n="@@datepicker.timestamp">Timestamp</div>
    </div>

    <!-- period selection. This picker is used always -->
    <lib-date-range-picker-form *ngIf="activeTab === 0"
      [period]="period || defaultPeriod || internalDefaultPeriod"
      [enablePin]="enablePin"
      [showPeriodSelector]="showPeriodSelector"
      [showTimeSelector]="showTimeSelector"
      [singleDate]="singleDate"
      [minDate]="minDate"
      [maxDate]="maxDate"
      (cancel)="emitCancel()"
      (submit)="emitSubmit($event)"
      (pin)="emitPin($event)"
    ></lib-date-range-picker-form>

    <!-- single date selection. This picker is only used in dual mode -->
    <lib-date-range-picker-form *ngIf="dualMode && activeTab === 1"
      [period]="period || defaultPeriodSingleDate || internalDefaultPeriod"
      [enablePin]="false"
      [showPeriodSelector]="false"
      [showTimeSelector]="showTimeSelector"
      [singleDate]="true"
      [minDate]="minDate"
      [maxDate]="maxDate"
      (cancel)="emitCancel()"
      (submit)="emitSubmit($event)"
      (pin)="emitPin($event)"
    ></lib-date-range-picker-form>
  </div>
</ng-template>

<div [class.outline]="showOutline" [class.invalid]="!valid" [class.disabled]="disabled"
  class="date-range-input"
  [class.date-range-input--inline]="inline"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="toggleMenu()"
>
  <i class="fas fa-calendar-days date-range-input__icon"></i>
  {{ formattedPeriod }}
</div>
