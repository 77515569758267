import {
  DashboardAddTilesModel,
  DashboardCreateModel,
  DashboardSelectionDashboardModel,
  DashboardTileModel
} from '@LG_CORE/dashboards/dashboards.model';
import { PeriodModel } from '@LIB_UTIL/model/period.model';
import { TimelapseModel } from '@LIB_UTIL/model/timelapse';

export class GetDashboardAction {
  public static readonly type: string = '[Dashboard Component] Set current active dashboard and fetch data';

  constructor(public dashboard: DashboardSelectionDashboardModel) {
  }
}

export class SetIsFavoriteAction {
  public static readonly type: string = '[Dashboard Component] Toggle isFavorite state of selected dashboards';

  constructor(public dashboard: DashboardSelectionDashboardModel, public isFavorite: boolean) {
  }
}

export class UpdateDashboardNameAction {
  public static readonly type: string = '[Dashboard Component] Update name of current dashboard';

  constructor(public dashboardId: number, public body: { name: string }) {
  }
}

export class CreateDashboardAction {
  public static readonly type: string = '[Dashboard Component] Create new dashboard';

  constructor(public body: DashboardCreateModel, public fromSelector: boolean = false) {
  }
}

export class DeleteDashboardAction {
  public static readonly type: string = '[Dashboard Component] Delete current dashboard';

  constructor(public dashboardId: number) {
  }
}

export class GetDashboardTileAction {
  public static readonly type: string = '[Dashboard Component] Get single dashboard tile';

  constructor(public dashboardId: number, public tileId: number, public params: { cropId: number }) {
  }
}

export class AddDashboardTilesAction {
  public static readonly type: string = '[Dashboard Component] Add dashboard tile';

  constructor(public dashboardId: number, public body: DashboardAddTilesModel) {
  }
}

export class UpdateDashboardTilesAction {
  public static readonly type: string = '[Dashboard Component] Update dashboard tiles';

  constructor(public dashboardId: number, public body: DashboardTileModel[]) {
  }
}

export class SetDashboardPeriodAction {
  public static readonly type: string = '[Dashboard Component] Set dashboard period';

  constructor(public period: PeriodModel) {
  }
}

export class SetDashboardTimelapseConfigurationAction {
  public static readonly type: string = '[Timelapse Tab Component] Set dashboard timelapse configuration';

  constructor(public timelapse: TimelapseModel) {
  }
}

export class HideDashboardTimelapseAction {
  public static readonly type: string = '[Timelapse Controls Component] Hide the timelapse controls';
}

export class SetDashboardTimelapseTimestampAction {
  public static readonly type: string = '[Dashboard Timelapse Controls Component] Set dashboard timelapse timestamp';

  constructor(public timestamp: number) {
  }
}

export class SetDashboardHoveredTimestampAction {
  public static readonly type: string = '[Tile Chart Component] Set dashboard hovered timestamp';

  constructor(public timestamp: number) {
  }
}

export class GetDashboardsAction {
  public static readonly type: string = '[Dashboard Component] Get all dashboards';
}

export class SetActiveCropsAction {
  public static readonly type: string = '[CropSelect Component] Set active crop id and compare crop id';

  constructor(public cropId: number, public cropCompareId?: number) {
  }
}

export class PinCropGroupAction {
  public static readonly type: string = '[CropSelect Component] Pin crop group for current dashboard';

  constructor(public dashboardId: number, public cropId: number, public isPinned: boolean, public cropGroupId: number) {
  }
}

export class HideCropGroupAction {
  public static readonly type: string = '[CropSelect Component] Hide crop group for current dashboard';

  constructor(public dashboardId: number, public cropId: number, public isHidden: boolean) {
  }
}

//Note: selectedCropModel property is not stored/saved in the backend, only for selecting the right crop
export class PinTileCropAction {
  public static readonly type: string = '[Any dashboard tile] Pin crop for shortcut tile for current dashboard ';

  constructor(
    public dashboardId: number,
    public tileId: number,
    public pinnedCropId: number,
    public selectedCropId: number,
    public cropGroupId: number
  ) { }
}

export class ToggleCropSelectorVisibility {
  public static readonly type: string = '[Gauge dashboard tile] Toggle visibility of crop selector';

  constructor(
    public dashboardId: number,
    public tileId: number,
    public show: boolean
  ) { }
}

export class SetGaugeTileSelectedCropId {
  public static readonly type: string = '[Gauge dashboard tile] Set selected crop Id';

  constructor(
    public dashboardId: number,
    public tileId: number,
    public cropId: number
  ) { }
}


export class SetDashboardHasChangesAction {
  public static readonly type: string = '[DashboardGridComponent] Set dashboardHasChanges';

  constructor(public hasChanges: boolean) { }
}

export class SetFavoriteDashboardIndex {
  public static readonly type: string = '[Dashboard selector] Set index of favorite dashboard';

  constructor(
    public dashboard: DashboardSelectionDashboardModel,
    public placeBeforeDashboard: DashboardSelectionDashboardModel
  ) { }
}
