import { CropGroupDetails, CropGroupFolderDetails } from '@LG_ROOT/app/core/crop-groups/crop-groups.model';
import { Definition } from '@LG_ROOT/app/core/page-title/shared.model';
import { filterOnId, mapRename } from '@LG_ROOT/app/shared/utils';
import { StateContext } from '@ngxs/store';
import { RenameCropGroupEntity } from './crop-groups.actions';
import { CropGroupsStateModel } from './crop-groups.state.model';
import { get } from 'lodash';

/**
 * Helper function to rename an entity in the store on Root level
 */
export const renameCropGroupEntityInRoot = (
  { patchState, getState }: StateContext<CropGroupsStateModel>,
  { cropGroupId, entityId, collection, name }: RenameCropGroupEntity,
  url: string
): void => {
  // get map of all crop groups
  const allCropGroupsState: Map<string, CropGroupDetails> = getState().cropgroup;
  // get the right crop group from the map
  let cropGroupState: CropGroupDetails = get(allCropGroupsState, cropGroupId, {});

  const newFolderState: CropGroupFolderDetails[] = (collection === 'folders')
    ? cropGroupState.folders.map(mapRename(entityId, name, url))
    : cropGroupState.folders.map((folder) => renameDefintionsRecursive(folder, entityId, name, url));

  const newDefinitions: Definition[] = (collection === 'folders')
    ? cropGroupState.definitions
    : cropGroupState.definitions.map(mapRename(entityId, name, url));

  const newFilteredDefinitions: Definition[] = (collection === 'folders')
    ? cropGroupState.filteredDefinitions
    : cropGroupState.filteredDefinitions.map(mapRename(entityId, name, url));

  patchState({
    cropgroup: {
      ...allCropGroupsState,
      // overwrite the properties of the specified crop group
      [cropGroupId]: {
        ...cropGroupState,
        folders: newFolderState,
        definitions: newDefinitions,
        filteredDefinitions: newFilteredDefinitions,
      },
    },
  });
};

/**
   * Helper function to rename an entity in the store on subfolder level
   */
export const renameCropGroupEntityInSubfolder = (
  { patchState, getState }: StateContext<CropGroupsStateModel>,
  { cropGroupId, folderId, entityId, collection, name }: RenameCropGroupEntity,
  url: string
): void => {

  // get list of all folders
  const allFoldersState: Map<string, CropGroupFolderDetails> = getState().folder;
  // get the specified folder
  const folderStoreId: string = `${ cropGroupId }_${ folderId }`;
  const folderState: CropGroupFolderDetails = allFoldersState[folderStoreId];

  // make copy of folders list, but with the specified item renamed
  const newSubFolders: CropGroupFolderDetails[] = (collection === 'folders')
    ? folderState.folders.map(mapRename(entityId, name, url))
    : folderState.folders.map((folder) => renameDefintionsRecursive(folder, entityId, name, url));

  // make copy of definitions list, but with the specified item renamed
  const newDefinitions: Definition[] = (collection === 'folders')
    ? folderState.definitions
    : folderState.definitions.map(mapRename(entityId, name, url));

  // make copy of filtered definitions, but with the specified item renamed
  const newFilteredDefinitions: Definition[] = (collection === 'folders')
    ? folderState.filteredDefinitions
    : folderState.filteredDefinitions.map(mapRename(entityId, name, url));

  patchState({
    folder: {
      ...allFoldersState,
      // overwrite the specified subfolder
      [folderStoreId]: {
        ...folderState,
        folders: newSubFolders,
        definitions: newDefinitions,
        filteredDefinitions: newFilteredDefinitions,
      },
    },
  });
};


const renameDefintionsRecursive = (
  cropgroupFolder: CropGroupFolderDetails,
  definitionId: number,
  name: string,
  url: string
): CropGroupFolderDetails => {

  // get the collection of filtered definitions, which have to be synced always
  const filteredDefinitions: Definition[] = cropgroupFolder.filteredDefinitions || [];

  const newDefintions: Definition[] = cropgroupFolder.definitions.map(mapRename(definitionId, name, url));
  const newFilteredDefinitions: Definition[] = filteredDefinitions.map(mapRename(definitionId, name, url));
  const newFolders: CropGroupFolderDetails[] = cropgroupFolder.folders
    .map((folder: CropGroupFolderDetails) => renameDefintionsRecursive(folder, definitionId, name, url));

  return {
    ...cropgroupFolder,
    definitions: newDefintions,
    filteredDefinitions: newFilteredDefinitions,
    folders: newFolders,
  };
};


const deleteDefintionsRecursive = (
  cropgroupFolder: CropGroupFolderDetails,
  definitionId: number
): CropGroupFolderDetails => {

  // get the collection of filtered definitions, which have to be synced always
  const filteredDefinitions: Definition[] = cropgroupFolder.filteredDefinitions || [];

  const newDefintions: Definition[] = cropgroupFolder.definitions.filter(filterOnId(definitionId));
  const newFilteredDefinitions: Definition[] = filteredDefinitions.filter(filterOnId(definitionId));
  const newFolders: CropGroupFolderDetails[] = cropgroupFolder.folders
    .map((folder: CropGroupFolderDetails) => deleteDefintionsRecursive(folder, definitionId));

  return {
    ...cropgroupFolder,
    definitions: newDefintions,
    filteredDefinitions: newFilteredDefinitions,
    folders: newFolders,
  };
};

/**
   * Helper function to rename an entity in the store on subfolder level
   */
export const deleteDefinitionInSubfolder = (
  { patchState, getState }: StateContext<CropGroupsStateModel>,
  cropGroupId: number,
  folderId: number,
  id: number
): void => {

  // get list of all folders
  const allFoldersState: Map<string, CropGroupFolderDetails> = getState().folder || new Map();
  // get specified folder
  const folderStoreId: string = `${ cropGroupId }_${ folderId }`;
  const folderState: CropGroupFolderDetails = get(allFoldersState, folderStoreId, {});
  // get the filteredDefinitions, which should be synced at all times
  const filteredDefinitionsState: Definition[] = folderState.filteredDefinitions || [];

  const newDefinitions: Definition[] = folderState.definitions.filter(filterOnId(id));
  const newFilteredDefinitions: Definition[] = filteredDefinitionsState.filter(filterOnId(id));
  const newFolders: CropGroupFolderDetails[] = folderState.folders
    .map((folder) => deleteDefintionsRecursive(folder, id));

  patchState({
    folder: {
      ...allFoldersState,
      [folderStoreId]: {
        ...folderState,
        definitions: newDefinitions,
        filteredDefinitions: newFilteredDefinitions,
        folders: newFolders,
      },
    },
  });
};

/**
   * Helper function to rename an entity in the store on subfolder level
   */
export const deleteDefinitionInRoot = (
  { patchState, getState }: StateContext<CropGroupsStateModel>,
  cropGroupId: number,
  id: number
): void => {

  // get map of all crop groups
  const allCropGroupsState: Map<string, CropGroupDetails> = getState().cropgroup || new Map();
  // get specified crop group
  const cropGroupState: CropGroupDetails = get(allCropGroupsState, cropGroupId, {});

  // make copy of crop group definition, but without the item to delete
  const newDefinitions: Definition[] = cropGroupState.definitions.filter(filterOnId(id));

  // make copy of filtered definitions, but without the item to delete
  const newFilteredDefinitions: Definition[] = cropGroupState.filteredDefinitions.filter(filterOnId(id));

  const newFolders: CropGroupFolderDetails[] = cropGroupState.folders
    .map((folder) => deleteDefintionsRecursive(folder, id));

  patchState({
    cropgroup: {
      ...allCropGroupsState,
      [cropGroupId]: {
        ...cropGroupState,
        definitions: newDefinitions,
        filteredDefintions: newFilteredDefinitions,
        folders: newFolders,
      },
    },
  });
};

