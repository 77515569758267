import { CopyDefinition } from '@LG_CORE/page-title/shared.model';
import { DefinitionType } from '@LIB_UTIL/model/definitiontype';

export class GetCropGroup {
  public static readonly type: string = '[Crop Groups API] Get Crop Group Details';

  constructor(public cropGroupId?: number, public slimMode: boolean = false, public archive: number = null) {
  }
}

export class GetCropGroupArchives {
  public static readonly type: string = '[Crop Groups API] Get Crop Group Archives';
}

export class GetCropGroupFolder {
  public static readonly type: string = '[Crop Groups API] Get Crop Group Folder';

  constructor(public cropGroupId: number, public folderId: number) {
  }
}

export class AddCropGroupDefinition {
  public static readonly type: string = '[Crop Groups API] Add Crop Group Definition';

  constructor(
    public cropGroupId: number,
    public folderId: number,
    public name: string,
    public type: DefinitionType,
    public openEditor: boolean = true
  ) { }
}

export class CopyDefinitionToCropGroup {
  public static readonly type: string = '[Crop Groups API] Copy Definitions To Crop Group';

  constructor(public cropGroupId: number, public folderId: number, public definitionsAndFoldersToCopy: CopyDefinition) {
  }
}

export class AddCropGroupFolder {
  public static readonly type: string = '[Crop Groups API] Add Crop Group Folder';

  constructor(public cropGroupId: number, public folderId: number = null, public name: string) {
  }
}

export class DeleteCropGroupFolder {
  public static readonly type: string = '[Crop Groups API] Delete Crop Group Folder';

  constructor(public cropGroupId: number, public parentId: number = null, public folderId: number) {
  }
}

export class DeleteCropGroupDefinition {
  public static readonly type: string = '[Crop Groups API] Delete Crop Group Definition';

  constructor(public cropGroupId: number, public folderId: number, public definitionId: number) {
  }
}

export class SetCropGroupEntityEditMode {
  public static readonly type: string = '[Crop Groups API] Set Crop Group Entity EditMode';

  constructor(
    public cropGroupId: number,
    public folderId: number,
    public entityId: number,
    public collection: 'filteredDefinitions' | 'folders',
    public editMode: boolean = true
  ) {
  }
}

export class RenameCropGroupEntity {
  public static readonly type: string = '[Crop Groups API] Rename Crop Group Entity';

  constructor(
    public cropGroupId: number,
    public folderId: number,
    public entityId: number,
    public collection: 'definitions' | 'folders',
    public name: string
  ) {
  }
}

export class GetAvailableDefinitionTypesInCropGroup {
  public static readonly type: string = '[Crop Groups API] Retrieve available definition types from crop group';

  constructor(public cropGroupId: number) {
  }
}

export class FilterCropGroupDefinitionSearchAction {
  public static readonly type: string = '[Crop Groups API] Filter crop group definitions';

  constructor(public searchString: string, public cropGroupId: number, public folderId: number) {
  }
}

export class FilterCropGroupSearchAction {
  public static readonly type: string = '[Crop Groups API] Filter crop group';

  constructor(public searchString: string, public archive: number) {
  }
}
