import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { GetKpisAction } from '@LG_ROOT/app/state/kpi/kpi.actions';
import { lastValueFrom } from 'rxjs';
import { KpiState } from '@LG_ROOT/app/state/kpi/kpi.state';
import { KpiDefinitionModel } from '@LIB_UTIL/kpi/model/kpi.model';
import { DefinitionType } from '@LIB_UTIL/model/definitiontype';

@Component({
  selector: 'app-select-existing-kpi',
  templateUrl: './select-existing-kpi.component.html',
  styleUrls: ['./select-existing-kpi.component.scss'],
})

export class SelectExistingKpiComponent implements OnInit {
  public selectedDefinitions: KpiDefinitionModel[] = [];
  public definitions: KpiDefinitionModel[] = [];
  public isLoading: boolean;
  protected readonly DefinitionType = DefinitionType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { blacklist: string[] },
    private store: Store,
    private dialogRef: MatDialogRef<SelectExistingKpiComponent>
  ) {
  }

  public ngOnInit(): void {
    this.retrieveKpis();
  }

  public async retrieveKpis(): Promise<void> {
    this.isLoading = true;
    await lastValueFrom(this.store.dispatch(new GetKpisAction()));
    this.definitions = this.store.selectSnapshot(KpiState.kpis);
    this.isLoading = false;
  }

  public addDefinitionToList(definition: KpiDefinitionModel): void {
    const index: number = this.selectedDefinitions.indexOf(definition);
    if (index > -1) {
      this.selectedDefinitions.splice(index, 1);
    } else {
      this.selectedDefinitions.push(definition);
    }
  }

  public copy(): void {
    const selectedDefinitionIds: number[]
      = this.selectedDefinitions.map((definition: KpiDefinitionModel) => definition.id);
    this.dialogRef.close({
      kpiDefinitionIds: selectedDefinitionIds,
    });
  }
}
