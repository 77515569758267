<div class="modal-header" mat-dialog-title>
  <h2>{{data.title}}</h2>
</div>
<div class="modal-content" mat-dialog-content>
  {{data.text}}
</div>
<div mat-dialog-actions align="end">

  <button mat-button mat-dialog-close>{{data.buttonText}}</button>
</div>
