const milliSecsPerHour: number = 60 * 60 * 1000;
const milliSecsPerDay: number = 24 * milliSecsPerHour;

/**
 * Helper method to add the hour (0 to 23) to an epoch date.
 * Using just a mathematical calculation so no intrusion from timezones or
 * Daylight Saving Time (DST)
 */
export const addHoursToEpoch = (date: number, hour: number): number =>
  date && !isNaN(date) && hour && !isNaN(hour)
    ? date + (hour*milliSecsPerHour)
    : date;

/**
* Helper function to get the hours from an epoch.
* Using just a mathematical calculation so no intrusion from timezones or
* Daylight Saving Time (DST)
* Using Math.floor to round to the exact hour
*/
export const getHoursFromEpoch = (epoch: number): number =>
  epoch && !isNaN(epoch)
    ? Math.floor((epoch % (milliSecsPerDay)) / (milliSecsPerHour))
    : epoch;

/**
* Helper function to extract only the Date part from an epoch date.
* Using just a mathematical calculation so no intrusion from timezones or
* Daylight Saving Time (DST)
*/
export const getDateFromEpoch = (epoch: number): number =>
  epoch && !isNaN(epoch)
    ? epoch - (epoch % (milliSecsPerDay))
    : epoch;
