import { Component, Input, OnInit, inject } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ModalService } from '@LIB_UTIL/services/modal.service';
import { AuthService } from '@LIB_UTIL/auth/services/auth.service';
import { UserInfo } from '@LIB_UTIL/auth/model/auth.model';
import { AuthState } from '@LIB_UTIL/auth/state/auth.state';
import { photoToBase64 } from '../util/photo.util';
import { DeletePhoto, GetAnnouncements, SetPhoto } from '../state/profile.state.actions';
import { Router, RouterEvent } from '@angular/router';
import { ProfileState } from '../state/profile.state';
import { AnnouncementsModalComponent } from '../modals/announcements/announcements-modal.component';
import { map, startWith } from 'rxjs/operators';
import { hasSid } from '@LIB_UTIL/auth/util/auth.util';
import { environment } from '@LIB_UTIL/environments/environment';
import { GetSlaUrl } from '@LIB_UTIL/auth/state/auth.actions';
import { MixPanelService } from '@LIB_UTIL/mixpanel/mixpanel.service';

@Component({
  selector: 'lib-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnInit {

  private authService: AuthService = inject(AuthService);
  private modalService: ModalService = inject(ModalService);
  private store: Store = inject(Store);
  private router: Router = inject(Router);
  public mixPanelService: MixPanelService = inject(MixPanelService);

  /**
   * Input that defines if the application wants to show the 'settings' menu item.
   * For the main app, we want to show this settings option, which leads to 'platform settings'.
   * But for the other apps we might not show that menu items until we have a decent 'user settings' page.
   * In the future we want this profile menu to lead to your personal settings page. (language, perferences)
   * But this will be discussed and implemented later.
   */
  @Input() public showSettingsLink: boolean = false;

  // The profile of the logged in user.
  public userInfo$: Observable<UserInfo> = this.store.select(AuthState.userInfo);
  // Fetch the count for unread announcements from the store.
  public unreadAnnouncements$: Observable<number> = this.store.select(ProfileState.unreadAnnouncements);
  public brand$: Observable<string> = this.store.select(AuthState.brand);
  public slaUrl$: Observable<string> = this.store.select(AuthState.slaUrl);

  public image: File = null;
  public sessionExpired$: Observable<boolean>;
  public isSignOutButtonVisible$: Observable<boolean>;

  constructor(
  ) {

    // listen to the router events, to se if we get redirected to a 'session expired' page.
    // If so, hide all editable parts of the profile menu
    this.sessionExpired$ = this.router.events.pipe(
      // listen to router event
      map((event: RouterEvent) => event.url && event.url.includes('expired')),
      // also take initial url into account
      startWith(window.location.href.includes('expired'))
    );
  }

  public ngOnInit(): void {
    if (hasSid()) {
      this.store.dispatch(new GetAnnouncements());
      this.store.dispatch(new GetSlaUrl());
    }

    this.isSignOutButtonVisible$ = this.userInfo$.pipe(
      map((userInfo: UserInfo) => {
        return environment
          .showSignOutButtonForBrands
          .toLocaleLowerCase()
          .includes(userInfo.brandName.toLocaleLowerCase());
      })
    );
  }

  /**
   * Open the modal containing the announcements.
   */
  public openAnnouncements(): void {
    this.modalService.open({
      component: AnnouncementsModalComponent,
      config: {
        panelClass: ['announcements-modal'],
        maxWidth: '600px',
        width: '90vw',
      },
    });

    this.mixPanelService.pushEventsToMixPanel('Announcements_viewed');
  }

  /**
   * Open the SLA in new tab.
   */
  public openServiceLevelAgreement(slaUrl: string): void {
    if (slaUrl) {
      window.open(slaUrl, '_blank');
    }
  }

  /**
   * Set the users avatar.
   */
  public async uploadPhoto(event: Event): Promise<void> {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const files: FileList = target.files;

    const photoBase64: string = await photoToBase64(files.item(0));

    this.store.dispatch(new SetPhoto(photoBase64));
  }

  /**
   * Remove the user's avatar.
   */
  public deletePhoto(): void {
    this.store.dispatch(new DeletePhoto());
  }

  /**
   * Sign the user out.
   */
  public signOut(): void {
    this.authService.signOut();
  }
}
