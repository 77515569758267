import { ViewMode } from '../model/auth.model';

export class GetUserInfoAction {
  public static readonly type: string = '[Auth module] Get User Info';
}

export class SetViewModeAction {
  public static readonly type: string = '[General] Set View Mode';

  constructor(public viewMode: ViewMode) {}
}

export class UpdatePhotoAction {
  public static readonly type: string = '[Auth module] update photo in store';

  constructor(public photoBase64: string) {}
}

export class SetTermsAndConditionsAcceptedAction {
  public static readonly type: string = '[App Initalizer] Set Terms and Conditions';

  constructor(public isTermsConditionsAccepted: boolean) {}
}

export class GetSlaUrl {
  public static readonly type: string = '[Profile menu component] Get Service Level Agreement';
}

export class ToggleDarkThemeAction {
  public static readonly type: string = '[PlatformSettings] Toggle dark theme';
}

