// @ts-ignore
import { EnvironmentModel } from '@LIB_UTIL/environments/environment.model';

export const environment: EnvironmentModel = {
  production: true,
  apiEndpoint: '/api',
  mediaApiEndpoint: '/api',
  editorApiEndpoint: '/api',
  modelApiEndpoint: '/api',
  strategyManagerApiEndpoint: '/api',
  hfcApiEndpoint: '/api',
  signalRUrl: 'https://signalr.letsgrow.com/',
  // new platform
  newPlatformUrl: 'https://my.letsgrow.com',
  // dashboard
  dashboardUrl: 'https://dashboard.letsgrow.com',
  //editor
  editorUrl: 'https://editor.letsgrow.com',
  // strategy manager
  strategyManagerUrl: 'https://strategymanager.letsgrow.com',
  // modules
  moduleManagementUrl: 'https://moduleconfigurationuserinterface.letsgrow.com',
  moduleShareUrl: 'https://hiblueadmin.letsgrow.com',
  // definitions
  mailDefinitionUrl: 'https://alert.letsgrow.com',
  // crop groups
  cropGroupUrl: 'https://cropgroup.letsgrow.com',
  cropGroupsUrl: 'https://cropgroupsui.letsgrow.com',
  cropManagerUrl: 'https://cropmanager.letsgrow.com',
  // photos
  photosUrl: 'https://photo.letsgrow.com',
  // notification settings
  notificationSettingsUrl: 'https://alert.letsgrow.com',
  // chart UI
  chartUiUrl: 'http://charts.letsgrow.com',
  // graphs UI
  graphsUiUrl: 'http://graphs.letsgrow.com',
  // url to redirect to, if there's no SID
  loginPageUrl: 'https://www.letsgrow.com/login/',
  supportPageUrl: 'https://support.letsgrow.com/portal/en/home',
  contactPageUrl: 'https://www.letsgrow.com/contact',
  // base url
  baseUrl: 'https://www.letsgrow.com',
  generalConditionsUrl: 'https://www.letsgrow.com/en/general_terms_and_conditions/',
  // turn server
  turnServers: [
    {
      turnServerUrl: 'turn:turn.letsgrow.com:443',
      turnServerUsername: 'LetsGrowUI',
      turnServerCredential: 'VTcca45AFaBfpcHgvxYwpfBTHtI49Kad',
    },
    {
      turnServerUrl: 'turn:turn2.letsgrow.com:443',
      turnServerUsername: 'LetsGrowUI',
      turnServerCredential: 'KhCcrMfc2cv8n7zEM9R5463kMaeSrBtP',
    },
  ],
  // stun server
  stunServers: [
    'stun:stun.letsgrow.com:80',
    'stun:stun2.letsgrow.com:80',
    'stun:stun.l.google.com:19302',
  ],
  // config flags
  embeddedMode: true, // sets whether app renders in a mode suited for embedding in iframe
  noAuthMode: false, // sets whether app is served by backend and doesn't require authentication
  sidHeader: true, // sets weather to add sid header to every ajax request
  // modules templates that are allowed to be selected for the meteo tile
  allowedModuleTemplatesForMeteoTile: [76, 1206],
  // crop groups that are not allowed to be selected for the text tile
  disallowedCropGroupsForTextTile: [],
  hostWithoutItemRightsCheck: 'letsgrowui.hiblue.nl',
  showSupportButtonForBrands: '',
  showSignOutButtonForBrands: '',
  // dark theme should be available for these brands:
  showDarkThemeForBrands: 'LG',
  // key is encoded since key can be read from client. Encoded by encodeShiftChars and decode by decodeShiftChars method
  licenseKeyAGGrid: '',
};
