import { KpiDefinitionModel } from '@LIB_UTIL/kpi/model/kpi.model';

export class GetKpisAction {
  public static readonly type: string = '[Company] Get kpis';
}

export class AddKpiAction {
  public static readonly type: string = '[Company] Add kpi';

  constructor(public kpiDefinitionConfigurationModel: KpiDefinitionModel) {
  }
}

export class CopyKpisAction {
  public static readonly type: string = '[Company] copy kpis';

  constructor(public kpiDefinitionIds: number[]) {
  }
}

export class DeleteKpiAction {
  public static readonly type: string = '[Company] delete kpi';

  constructor(public kpiDefinitionId: number) {
  }
}

export class RenameKpiAction {
  public static readonly type: string = '[Company] rename kpi name';

  constructor(public kpiDefinitionId: number, public newName: string) {
  }
}
