/**
 * Convert the uploaded photo to a base64 string.
 */
export function photoToBase64(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => resolve(reader.result.toString());
    reader.onerror = reject;
  });
}
