import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConfigService } from '@LIB_UTIL/services/config/config.service';
import {
  DashboardAddTilesModel,
  DashboardCreateModel,
  DashboardCropModel,
  DashboardExportModel,
  DashboardModel,
  DashboardOrderProperties,
  DashboardReturnValue,
  DashboardSelectionDashboardModel,
  DashboardSelectionModel,
  DashboardTileCropSelection,
  DashboardTileDataModel,
  DashboardTileModel,
  DashboardTileTypeMeteoValuesModel,
  DashboardTileTypeShortcutModel
} from '@LG_CORE/dashboards/dashboards.model';
import { Observable } from 'rxjs';
import { PeriodModel } from '@LIB_UTIL/model/period.model';
import { queryStringBuilder } from '@LIB_UTIL/util/string';

@Injectable({
  providedIn: 'root',
})
export class DashboardsApiService {

  constructor(
    private cfg: ConfigService,
    private http: HttpClient
  ) {
  }

  public getDashboards(): Observable<DashboardSelectionModel> {
    return this.http.get<DashboardSelectionModel>(`${this.cfg.endpoints.dashboards}`);
  }

  public getDashboard(dashboard: DashboardSelectionDashboardModel): Observable<DashboardModel> {

    const params: Record<string, string | number> = {
      cropId: dashboard.cropId !== 0 ? dashboard.cropId : null,
      cropGroupId: dashboard.cropGroupId !== 0 ? dashboard.cropGroupId : null,
      moduleDefinitionId: dashboard.moduleDefinitionId !== 0 ? dashboard.moduleDefinitionId : null,
    };

    const url: string = `${ this.cfg.endpoints.dashboards }/${ dashboard.id }${ queryStringBuilder(params) }`;

    return this.http.get<DashboardModel>(url);
  }

  public updateDashboardName(
    dashboardId: number,
    body: { name: string }
  ): Observable<DashboardSelectionDashboardModel> {
    const url: string = `${this.cfg.endpoints.dashboards}/${dashboardId}/name`;

    return this.http.post<DashboardSelectionDashboardModel>(url, body);
  }

  public createDashboard(body: DashboardCreateModel): Observable<DashboardCreateModel> {
    return this.http.post<DashboardCreateModel>(`${this.cfg.endpoints.dashboards}`, body);
  }

  public deleteDashboard(dashboardId: number): Observable<void> {
    return this.http.delete<void>(`${this.cfg.endpoints.dashboards}/${dashboardId}`);
  }

  public exportDashboard(dashboardId: number, body: DashboardExportModel): Observable<HttpResponse<Blob>> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    return this.http.post<Blob>(`${this.cfg.endpoints.dashboards}/${dashboardId}/export`, body, {
      headers: headers,
      responseType: 'blob' as 'json',
      observe: 'response',
    });
  }

  public addDashboardTile(dashboardId: number, body: DashboardAddTilesModel): Observable<DashboardTileModel[]> {
    return this.http.post<DashboardTileModel[]>(`${this.cfg.endpoints.dashboards}/${dashboardId}/tile`, body);
  }

  public getDashboardTile(
    dashboardId: number,
    tileId: number,
    params: { cropId: number }
  ): Observable<DashboardTileDataModel> {
    const url: string
      = `${this.cfg.endpoints.dashboards}/${dashboardId}/tiles/${tileId}${queryStringBuilder(params)}`;

    return this.http.get<DashboardTileDataModel>(url);
  }

  public getDashboardTileValues(
    dashboardId: number,
    tileId: number,
    params: Record<string, unknown>
  ): Observable<DashboardReturnValue<DashboardTileTypeMeteoValuesModel[]>> {
    const url: string = `${this.cfg.endpoints.dashboards}/${dashboardId}/tiles/${tileId}/`
      + `values${queryStringBuilder(params)}`;

    return this.http.get<DashboardReturnValue<DashboardTileTypeMeteoValuesModel[]>>(url);
  }

  public updateDashboardTiles(dashboardId: number, body: DashboardTileModel[]): Observable<DashboardTileModel[]> {
    return this.http.post<DashboardTileModel[]>(`${this.cfg.endpoints.dashboards}/${dashboardId}/tiles`, body);
  }

  public updateDashboardPeriod(
    dashboardId: number,
    body: PeriodModel,
    moduleDefinitionId: number,
    cropGroupId: number
  ): Observable<PeriodModel> {
    // pinnedPeriodType matches value of periodType from the api, but it
    // exists only in the frontend so replace and delete it from the response
    // to match the response with the real api
    body.periodType = body.pinnedPeriodType;
    delete body.pinnedPeriodType;

    const url: string = `${this.cfg.endpoints.dashboards}/${dashboardId}/`
      + `period?moduleDefinitionId=${moduleDefinitionId}&cropGroupId=${cropGroupId}`;

    return this.http.post<PeriodModel>(url, body);
  }

  public setIsFavorite(dashboard: DashboardSelectionDashboardModel, isFavorite: boolean): Observable<boolean> {
    return this.http.post<boolean>(`${this.cfg.endpoints.dashboards}/${dashboard.id}/favorite`, {
      moduleDefinitionId: dashboard.moduleDefinitionId,
      isFavorite: isFavorite,
      cropGroupId: dashboard.cropGroupId,
    });
  }

  public pinCropGroup(dashboardId: number, cropId: number, isPinned: boolean, cropGroupId: number): Observable<DashboardCropModel> {
    const url: string = `${ this.cfg.endpoints.dashboards }/${ dashboardId }/crop-groups/${ cropGroupId }/crops/${ cropId }/pin`;

    return this.http.post<DashboardCropModel>(url, { isPinned: isPinned });
  }

  public hideCropGroup(dashboardId: number, cropId: number, isHidden: boolean): Observable<DashboardCropModel> {
    const url: string = `${this.cfg.endpoints.dashboards}/${dashboardId}/crops/${cropId}/hide`;

    return this.http.post<DashboardCropModel>(url, { isHidden: isHidden });
  }

  public pinAndSelectTileCrop(
    dashboardId: number,
    tileId: number,
    cropSelectionSettings: DashboardTileCropSelection,
    cropGroupId: number
  ): Observable<DashboardTileTypeShortcutModel> {
    const url: string = `${ this.cfg.endpoints.dashboards }/${ dashboardId }/crop-groups/${ cropGroupId }/tiles/${ tileId }/pin-select`;

    return this.http.post<DashboardTileTypeShortcutModel>(url, cropSelectionSettings);
  }

  public showCropSelector(
    dashboardId: number,
    tileId: number,
    show: boolean
  ): Observable<boolean> {
    const url: string = `${this.cfg.endpoints.dashboards}/${dashboardId}/tiles/${tileId}/show-crops`;

    return this.http.post<boolean>(url, { value: show });
  }

  public updateFavoriteDashboardOrder(dashboards: DashboardOrderProperties[]): Observable<boolean> {
    return this.http.post<boolean>(`${this.cfg.endpoints.dashboards}/order/favorite`, dashboards);
  }
}
