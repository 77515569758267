import Quill from 'quill';
import { QuillConfig } from 'ngx-quill';
import BlotFormatter from 'quill-blot-formatter';

Quill.register('modules/blotFormatter', BlotFormatter);
/**
 * The configuration object for the quill text editor.
 */
export const quillConfig: QuillConfig = {
  modules: {
    syntax: false,
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' },{ indent: '-1' }, { indent: '+1' },{ align: [] }],
      ['link'],
      ['image'],
      ['clean'],
    ],
    blotFormatter: {
    },
  },
};

export interface QuillMentionableUser {
  id: number;
  value: string;
}
