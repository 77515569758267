import { Component, Input, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '@LIB_UTIL/auth/services/auth.service';
import { filter, map, startWith } from 'rxjs/operators';
import { isPartner } from '@LIB_UTIL/util/partners';
import { Store } from '@ngxs/store';
import { AuthState } from '@LIB_UTIL/auth/state/auth.state';

@Component({
  selector: 'lib-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {

  private authService: AuthService = inject(AuthService);
  private store: Store = inject(Store);

  @Input() public logo: string;
  @Input() public applyBranding: boolean = true;
  @Input() public enableHomelink: boolean = true;
  @Input() public overrideBrand: string = '';

  public brand$: Observable<string> = this.store.select(AuthState.brand);

  /**
   * Get the assets url based on the current brand.
   * Falls back to the default LetsGrow logo.
   */
  public logoUrl$: Observable<string>;

  public get sid(): string {
    return this.authService.sid;
  }

  public ngOnInit(): void {
    this.logoUrl$ = this.createBrandLogoUrlObserver();
  }

  /**
   * Returns the brand logo url.
   */
  private createBrandLogoUrlObserver(): Observable<string> {
    return this.brand$.pipe(
      map((brand: string) => this.overrideBrand ? this.overrideBrand : brand),
      // get the brand
      filter((brand: string) => isPartner(brand)),
      map((brand: string) => brand ? brand.toLowerCase() : ''),
      // apply branding folder if we want to apply branding
      map((brand: string) => this.applyBranding ? `./assets/lib-util/partners/${brand}/logo.png` : this.logo),
      // start with the provided url in the input
      startWith(this.logo)
    );
  }

}
