<div class="page">

  <div class="page-header">
    <lib-header>
      <h1 i18n="@@pageNotFound">Page Not Found</h1>
    </lib-header>
  </div>

  <div class="page-content">
    <div class="wrapper">
      <div class="header">
        <div class="code404 u-bold">404</div>
        <div class="codeTitle u-bold" i18n>Not found</div>
      </div>
      <p class="u-bold" i18n>This page does not exist or has been moved.</p>
      <button [routerLink]="['/']"
              mat-flat-button
              color="accent">
        Back to home
      </button>
    </div>
  </div>

</div>

