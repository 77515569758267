
<div class="modal-header" mat-dialog-title>
  <h2 class="u-bold">{{data.title}}</h2>
</div>
<div class="modal-content" mat-dialog-content>
  <div>{{data.message}}</div>

  <ng-container *ngIf="data.message2">
    <div class="extra-message">{{data.message2}}</div>
  </ng-container>
</div>
<div class="modal-footer" mat-dialog-actions align="end">
  <button *ngIf="!data.hideCancelButton" mat-flat-button mat-dialog-close i18n="@@common.cancel">Cancel</button>
  <button *ngFor="let option of data.options"
    mat-flat-button
    color="accent"
    [disabled]="option.disabled"
    (click)="selectOption(option)">{{option.name}}
  </button>

</div>
