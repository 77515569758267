import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { get, omit } from 'lodash';
import { getDescendentIds } from '@LG_FOLDERS/folders.utils';
import { FoldersApiService } from '@LG_CORE/folders/folders-api.service';
import { filterOnId, mapEditMode, mapRename } from '@LG_SHARED/utils/store';
import {
  AddFolder,
  AddFolderDefinition,
  CopyDefinitionToFolder,
  DeleteFolder,
  DeleteFolderDefinition,
  GetFolder,
  RenameFolderEntity,
  SetFolderEntityEditMode
} from '@LG_ROOT/app/state/folders/folders.actions';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { FoldersStateModel } from './folders.state.model';
import { Folder, FolderDetails, PostDefinitionResponse } from '@LG_CORE/folders/folders.model';
import { LocaleID } from '@LIB_UTIL/util/locale';
import { AuthService } from '@LIB_UTIL/auth/services/auth.service';
import { Observable } from 'rxjs';
import { getEditorUrl } from '@LG_ROOT/app/shared/utils/url';
import { HttpErrorResponse } from '@angular/common/http';
import { DefinitionType } from '@LIB_UTIL/model/definitiontype';
import { getBackendErrorMessage } from '@LIB_UTIL/util/errorhandling';

@State<FoldersStateModel>({
  name: 'folders',
  defaults: {
    root: {
      folders: [],
      definitions: [],
    },
    folder: {},
  },
})
@Injectable()
export class FoldersState {

  @Selector()
  public static folder(state: FoldersStateModel): Record<string, FolderDetails> {
    return state.folder;
  }

  @Selector()
  public static folders(state: FoldersStateModel): Folder[] {
    return state.root.folders;
  }

  constructor(
    private foldersApiService: FoldersApiService,
    private authService: AuthService,
    @Inject(LOCALE_ID) private locale: LocaleID
  ) {
  }

  /*
   * ACTIONS
   */
  @Action(GetFolder)
  public getFolder(
    ctx: StateContext<FoldersStateModel>,
    { folderId }: GetFolder
  ): Observable<Object> {
    return this.foldersApiService.getFolder(folderId)
      .pipe(tap((details: any) => {
        if (!folderId) {
          ctx.patchState({ root: details });
        } else {
          const foldersState = get(ctx.getState(), 'folder', {});

          ctx.patchState({
            folder: {
              ...foldersState,
              [folderId]: details,
            },
          });

        }
      }));
  }

  @Action(AddFolder)
  public addFolder(
    ctx: StateContext<FoldersStateModel>,
    { name, parentId }: AddFolder
  ): Observable<Object> {
    return this.foldersApiService.addFolder(name, parentId)
      .pipe(tap((result: any) => {
        if (!parentId) {
          const rootState = get(ctx.getState(), 'root', {});
          ctx.patchState({
            root: {
              ...rootState,
              folders: [...(Array.isArray(rootState.folders) ? rootState.folders : []), result],
            },
          });

        } else {
          const foldersState = get(ctx.getState(), 'folder', {});
          const folderState = get(foldersState, parentId, {});

          ctx.patchState({
            folder: {
              ...foldersState,
              [parentId]: {
                ...folderState,
                folders: [...(Array.isArray(folderState.folders) ? folderState.folders : []), result],
              },
            },
          });
        }

      }));
  }

  @Action(CopyDefinitionToFolder)
  public copyDefinition(
    ctx: StateContext<FoldersStateModel>,
    { folderId, definitionsAndFoldersToCopy }: CopyDefinitionToFolder
  ): Observable<Object> {
    return this.foldersApiService.copyDefinitionToFolder(folderId, definitionsAndFoldersToCopy)
      .pipe(tap((result: any) => {
        if (!folderId) {
          const rootState = get(ctx.getState(), 'root', {});
          ctx.patchState({
            root: {
              ...rootState,
              folders: [...(Array.isArray(rootState.folders) ? rootState.folders : []), ...result.folders],
              definitions: [...(Array.isArray(rootState.definitions) ? rootState.definitions : []), ...result.definitions],
            },
          });

        } else {
          const foldersState = get(ctx.getState(), 'folder', {});
          const folderState = get(foldersState, folderId, {});

          ctx.patchState({
            folder: {
              ...foldersState,
              [folderId]: {
                ...folderState,
                folders: [...(Array.isArray(folderState.folders) ? folderState.folders : []), ...result.folders],
                definitions: [...(Array.isArray(folderState.definitions) ? folderState.definitions : []), ...result.definitions],
              },
            },
          });
        }
      }));
  }

  @Action(AddFolderDefinition)
  public addDefinition(
    ctx: StateContext<FoldersStateModel>,
    { folderId, name, type, openEditor }: AddFolderDefinition
  ): Observable<PostDefinitionResponse> {
    return this.foldersApiService.addDefinition(folderId, name, type)
      .pipe(tap((result: PostDefinitionResponse) => {

        if (folderId !== undefined) {
          const foldersState = get(ctx.getState(), 'folder', {});
          const folderState = get(foldersState, folderId, {});

          ctx.patchState({
            folder: {
              ...foldersState,
              [folderId]: {
                ...folderState,
                definitions: [...(Array.isArray(folderState.definitions) ? folderState.definitions : []), result],
              },
            },
          });

        } else {
          const rootState = get(ctx.getState(), 'root', {});
          ctx.patchState({
            root: {
              ...rootState,
              definitions: [...(Array.isArray(rootState.definitions) ? rootState.definitions : []), result],
            },
          });
        }

        if (!openEditor) {
          return;
        }

        if (type === DefinitionType.graph) {
          const url: string = getEditorUrl(type, result.id, this.locale, this.authService.sid);
          window.open(url, '_blank');
        } else if (result.configurationUrl) {
          window.open(result.configurationUrl, '_blank');
        } else if (result.url) {
          window.open(result.url, '_blank');
        }
      }));
  }

  @Action(DeleteFolder)
  public deleteFolder(
    ctx: StateContext<FoldersStateModel>,
    { folderId, parentId }: DeleteFolder
  ): Observable<Object> {
    return this.foldersApiService.deleteFolder(folderId)
      .pipe(tap(() => {

        let foldersState = get(ctx.getState(), 'folder', {});
        const descendents = getDescendentIds(foldersState, folderId);

        if (parentId === undefined) {
          const rootState = get(ctx.getState(), 'root', {});
          ctx.patchState({
            root: {
              ...rootState,
              folders: (Array.isArray(rootState.folders) ? rootState.folders : []).filter(filterOnId(folderId)),
            },
          });
        } else {
          const folderState = get(foldersState, parentId, {});

          foldersState = {
            ...foldersState,
            [parentId]: {
              ...folderState,
              folders: (Array.isArray(folderState.folders) ? folderState.folders : []).filter(filterOnId(folderId)),
            },
          };
        }

        ctx.patchState({
          folder: {
            ...omit(foldersState, [folderId, ...descendents]),
          },
        });

      }),
      catchError((error: HttpErrorResponse) => {
        throw new Error(getBackendErrorMessage(error));
      }));
  }

  @Action(DeleteFolderDefinition)
  public deleteFolderDefinition(
    ctx: StateContext<FoldersStateModel>,
    { folderId, definitionId }: DeleteFolderDefinition
  ): Observable<Object> {
    return this.foldersApiService.deleteDefinition(folderId, definitionId)
      .pipe(tap(() => {
        if (folderId !== undefined) {
          const foldersState = get(ctx.getState(), 'folder', {});
          const folderState = get(ctx.getState(), ['folder', folderId], {});

          ctx.patchState({
            folder: {
              ...foldersState,
              [folderId]: {
                ...folderState,
                definitions: (Array.isArray(folderState.definitions) ? folderState.definitions : []).filter(filterOnId(definitionId)),
              },
            },
          });
        } else {
          const rootState = get(ctx.getState(), 'root', {});
          ctx.patchState({
            root: {
              ...rootState,
              definitions: (Array.isArray(rootState.definitions) ? rootState.definitions : []).filter(filterOnId(definitionId)),
            },
          });
        }
      }));
  }

  @Action(SetFolderEntityEditMode)
  public sem(
    ctx: StateContext<FoldersStateModel>,
    { folderId, entityId, collection, editMode }: SetFolderEntityEditMode
  ): void {
    if (!folderId) {
      const rootState = get(ctx.getState(), 'root', {});
      ctx.patchState({
        root: {
          ...rootState,
          [collection]: (Array.isArray(rootState[collection]) ? rootState[collection] : []).map(mapEditMode(entityId, editMode)),
        },
      });

    } else {
      const foldersState = get(ctx.getState(), 'folder', {});
      const folderState = get(foldersState, folderId, {});

      ctx.patchState({
        folder: {
          ...foldersState,
          [folderId]: {
            ...folderState,
            [collection]: (Array.isArray(folderState[collection]) ? folderState[collection] : []).map(mapEditMode(entityId, editMode)),
          },
        },
      });
    }
  }

  @Action(RenameFolderEntity)
  public rename(
    ctx: StateContext<FoldersStateModel>,
    { folderId, entityId, collection, name }: RenameFolderEntity
  ) {
    return (collection === 'definitions'
      ? this.foldersApiService.renameDefinition(folderId, entityId, name)
      : this.foldersApiService.renameFolder(entityId, name)).pipe(tap(({ url }: { url?: string }) => {

      if (!folderId) {
        const rootState = get(ctx.getState(), 'root', {});
        ctx.patchState({
          root: {
            ...rootState,
            [collection]: (Array.isArray(rootState[collection]) ? rootState[collection] : []).map(mapRename(entityId, name, url)),
          },
        });

      } else {
        const foldersState = get(ctx.getState(), 'folder', {});
        const folderState = get(foldersState, folderId, {});

        ctx.patchState({
          folder: {
            ...foldersState,
            [folderId]: {
              ...folderState,
              [collection]: (Array.isArray(folderState[collection]) ? folderState[collection] : []).map(mapRename(entityId, name, url)),
            },
          },
        });
      }
    }));
  }
}
