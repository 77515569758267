import { Component, Inject, ViewChild } from '@angular/core';
import { Definition, SelectDefinitionConfig } from '@LG_CORE/page-title/shared.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectDefinitionComponent } from '@LG_SHARED/components/select-definition/select-definition.component';

@Component({
  selector: 'app-select-existing-entity-folder-modal',
  templateUrl: './select-existing-entity-folder-modal.component.html',
})
export class SelectExistingEntityFolderModalComponent {

  @ViewChild(SelectDefinitionComponent) public selectDefinitionComponent: SelectDefinitionComponent;
  public folderId: number;
  public parentId: number;
  public parents: any = [];
  public entityId: number;
  public selectedDefinitions: Definition[] = [];
  public selectedFolders: string[] = [];
  public selectDefinitionConfig: typeof SelectDefinitionConfig = SelectDefinitionConfig;
  private cropGroupId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { blacklist: string[] },
    private dialogRef: MatDialogRef<SelectExistingEntityFolderModalComponent>
  ) {}

  public copy(): void {
    const selectedDefinitionIds: number[] = this.selectedDefinitions.map((definition: Definition) => definition.id);

    if (!this.cropGroupId) {
      this.cropGroupId = 0;
    }

    this.dialogRef.close({
      fromCropgroupId: this.cropGroupId,
      definitionIds: selectedDefinitionIds,
      folderIds: this.selectedFolders,
    });
  }

  public onSelectDefinitionChanged(data: any): void {
    this.folderId = data.folderId;
    this.parentId = data.parentId;
    this.parents = data.parents;
    this.entityId = data.entityId;
    this.cropGroupId = data.cropGroupId;
    this.selectedDefinitions = data.selectedDefinitions;
    this.selectedFolders = data.selectedFolders;
  }

}
