import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../config/config.service';
import { AnnouncementsModel, ColleagueModel, PhotoModel } from './users-api.model';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {

  private cfg: ConfigService = inject(ConfigService);
  private http: HttpClient = inject(HttpClient);

  private readonly baseUrl: string = this.cfg.endpoints.user;

  public getAnnouncements(): Observable<AnnouncementsModel[]> {
    return this.http.get<AnnouncementsModel[]>(`${this.baseUrl}/announcements`);
  }

  public updateAnnouncementRead(announcementId: number): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/announcements/${announcementId}/read`, null);
  }

  public deleteAnnouncement(announcementId: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/announcements/${announcementId}`);
  }

  public uploadPhoto(photoBase64: string): Observable<PhotoModel> {
    const body: PhotoModel = { photoBase64: photoBase64 };

    return this.http.post<PhotoModel>(`${this.baseUrl}/photo`, body);
  }

  public deletePhoto(): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/photo`);
  }

  public getColleagues(): Observable<ColleagueModel[]> {
    return this.http.get<ColleagueModel[]>(`${this.baseUrl}/colleagues`);
  }

  public setTheme(isDarkTheme: boolean): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/theme`, { value: isDarkTheme });
  }
}
