/** Enumerations that are used for overlays from angular CDK Overlay */

export enum HorizontalConnectionPositions {
  start = 'start',
  center = 'center',
  end = 'end'
}

export enum VerticalConnectionPositions {
  top = 'top',
  center = 'center',
  bottom = 'bottom',
}
