import { replaceAll } from "./replace-all";

/**
 * Replaces a substring that contains only the exact match, not twice (or more) the match.
 * Use only a search string that contains one or more of the same character!
 *
 * For example:
 *
 * replaceExactMatch('pppp' ,  'pp', 'x') returns 'pppp'
 * replaceExactMatch('ppp' ,   'pp', 'x') returns 'ppp'
 * replaceExactMatch('pp' ,    'pp', 'x') returns 'x'
 * replaceExactMatch('ttppt' , 'pp', 'x') returns 'ttxt'
 */
export function replaceExactMatch(str: string, search: string, replace: string): string {

  if (search.length === 0) {
    return str;
  }

  const firstChar = search.charAt(0);

  // check if all characters are the same
  if (replaceAll(search, firstChar, '').length > 0) {
    return str;
  }


  // if the string contains twice the substring, don't do anything
  if ( str.includes(`${firstChar}${search}`)) {
    return str;
  }


  return replaceAll(str, search, replace);
}
