import { parseISO } from 'date-fns';
import { offsetInMilliSeconds } from '@LIB_UTIL/util/date';
import dayjs, { Dayjs } from 'dayjs';

/**
 * Function to format a date into a week number
 */
export function dateToWeekNumber(date: Date): number {

  const utcDate: Date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  utcDate.setUTCDate(utcDate.getUTCDate() + 4 - (utcDate.getUTCDay() || 7));

  // Get first day of year
  const yearStart: Date = new Date(Date.UTC(utcDate.getUTCFullYear(), 0, 1));

  // Calculate full weeks to nearest Thursday
  const weekNumber: number = Math.ceil((((utcDate.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);

  return weekNumber;
}

/**
 * The server wants the timestamps as UTC date. So we have to convert the
 * local chart datetime to UTC.
 *
 * @see /docs/date-fns-format-unix.md
 */
export function convertApiFormatToUnixTimestamp(date: string): number {
  const unixTimestamp: number = parseISO(date).getTime();

  return unixTimestamp + offsetInMilliSeconds(unixTimestamp);
}

export const removeTime = (date: Dayjs): Dayjs => dayjs(new Date(date.year(), date.month(), date.date()));
