<div class="breadcrumb-wrapper">

  <div *ngIf="config === selectDefinitionMode.CropGroups">
    <ul class="breadcrumbs custombreadcrum">
      <li *ngFor="let parent of (parents || []); let i = index; let last = last" [class.last]="last">
        <button (click)="navigateTo(parent.id, parent.type, archiveYear)">
          <span *ngIf="parents.length <= 3 || i === 0">
            <span class="u-hide-lap" *ngIf="last" i18n="@@backTo">Back to</span>
            <span [ngSwitch]="parent.name">
              <span *ngSwitchCase="'[Crop Groups]'" i18n="@@cropGroups">Crop groups</span>
              <span *ngSwitchCase="'[Crop Group Archives]'" i18n>Crop Group Archives</span>
              <span *ngSwitchCase="'[Modules]'" i18n="@@modules">Modules</span>
              <span *ngSwitchDefault>{{ parent.name }}</span>
            </span>
          </span>
          <span *ngIf="parents.length > 3 && i !== 0">
            <span class="u-hide-lap" *ngIf="last"><ng-container i18n="@@backTo">Back to</ng-container>
              {{ parent.name }}</span>
            ...
          </span>
        </button>
      </li>
      <li>
        <span [ngSwitch]="moduleOrCropGroupBreadcrumbName">
          <span *ngSwitchCase="'[Crop Groups]'" i18n>Crop Group</span>
          <span *ngSwitchCase="'[Crop Group Archives]'" i18n>Crop Group Archives</span>
          <span *ngSwitchCase="'[Modules]'" i18n="@@modules">Modules</span>
          <span *ngSwitchDefault>{{ moduleOrCropGroupBreadcrumbName }}</span>
        </span>
      </li>
    </ul>
  </div>

  <div *ngIf="config === selectDefinitionMode.Folder">
    <ul class="breadcrumbs custombreadcrum" *ngIf="folderDetailsType !== 'root' && !loading">
      <li *ngFor="let parent of (details?.parents || []); let i = index; let last = last" [class.last]="last">
        <button (click)="getFolder(parent.id)">
          <span class="u-hide-lap" *ngIf="last" i18n="@@backTo">Back to</span>
          {{ parent.name }}
        </button>
      </li>
      <li>
        {{ details?.name }}
      </li>
    </ul>
  </div>

  <div class="select-all"
       *ngIf="showSelectAll">
    <span *ngIf="!allDefinitionsSelected && pageIndex === 6" (click)="selectAll(true)">
      <ng-container i18n="@@selectAll">Select all</ng-container>
    </span>
    <span *ngIf="allDefinitionsSelected  && pageIndex === 6" (click)="selectAll(false)">
      <ng-container i18n="@@unselectAll">Unselect all</ng-container>
    </span>
  </div>
</div>

<!-- Crop group or module selection -->
<ng-container [ngSwitch]="pageIndex">
  <div class="container">
    <ng-container *ngIf="loading; else showContent">
      <div class="loading-container">
        <i class="fas fa-spinner fa-pulse" aria-hidden="true"></i>
      </div>
    </ng-container>
    <ng-template #showContent>

      <mat-list role="list" class="list" *ngSwitchCase="1">
        <mat-list-item *ngIf="showModules" role="listitem" class="list-item list-item" (click)="getModules(null)">
          <mat-icon class="list-item-icon" fontSet="fak" fontIcon="fa-modules"></mat-icon>
          <ng-container i18n="@@modules">Modules</ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="showCropGroups" role="listitem" class="list-item"
                       (click)="getCropGroups(null, null, null)">
          <mat-icon class="list-item-icon" fontSet="fas" fontIcon="fa-users"></mat-icon>
          <ng-container i18n="@@cropGroups">Crop groups</ng-container>
        </mat-list-item>
        <mat-list-item *ngIf="showFolders" role="listitem" class="list-item list-item" (click)="getFolder(null)">
          <i class="fas fa-folder list-item-icon-archive" aria-hidden="true"></i>
          <ng-container i18n="@@folders">Folders</ng-container>
        </mat-list-item>
      </mat-list>

      <!-- Modules templates -->
      <mat-list role="list" class="list" *ngSwitchCase="2">
        <mat-list-item *ngFor="let moduleTemplate of moduleTemplates$ | async" role="listitem" class="list-item"
                       (click)="getModules(moduleTemplate.id)"
                       [style.display]="whitelist.length === 0 || (whitelist.length > 0 && whitelist.includes(moduleTemplate.id)) ? 'block' : 'none'">
          <mat-icon class="list-item-icon" fontSet="fak" fontIcon="fa-modules"></mat-icon>
          {{ moduleTemplate.name }}
        </mat-list-item>
      </mat-list>

      <mat-list role="list" class="list" *ngSwitchCase="3">
        <mat-list-item role="listitem" class="list-item" (click)="showDefinitionSelection(definitionTypes.graph)">
          <div class="u-flex def-row">
            <lib-definition-icon [definitionType]="definitionTypes.graph"></lib-definition-icon>
            <div i18n="@@graph">Graph</div>
          </div>
        </mat-list-item>
        <mat-list-item role="listitem" class="list-item" (click)="showDefinitionSelection(definitionTypes.gauge)">
          <div class="u-flex def-row">
            <lib-definition-icon [definitionType]="definitionTypes.gauge"></lib-definition-icon>
            <div i18n="@@gauge">Gauge</div>
          </div>
        </mat-list-item>
        <mat-list-item role="listitem" class="list-item" (click)="showDefinitionSelection(definitionTypes.report)">
          <div class="u-flex def-row">
            <lib-definition-icon [definitionType]="definitionTypes.report"></lib-definition-icon>
            <ng-container i18n="@@table">Table</ng-container>
          </div>
        </mat-list-item>
        <mat-list-item role="listitem" class="list-item" (click)="showDefinitionSelection(definitionTypes.plan)">
          <div class="u-flex def-row">
            <lib-definition-icon [definitionType]="definitionTypes.plan"></lib-definition-icon>
            <ng-container i18n="@@map">Map</ng-container>
          </div>
        </mat-list-item>
        <mat-list-item role="listitem" class="list-item" (click)="showDefinitionSelection(definitionTypes.url)">
          <div class="u-flex def-row">
            <lib-definition-icon [definitionType]="definitionTypes.url"></lib-definition-icon>
            <ng-container i18n="@@link">Link</ng-container>
          </div>
        </mat-list-item>
        <mat-list-item role="listitem" class="list-item" (click)="showDefinitionSelection(definitionTypes.dashboard)">
          <div class="u-flex def-row">
            <lib-definition-icon [definitionType]="definitionTypes.dashboard"></lib-definition-icon>
            <ng-container i18n="@@dashboard">Dashboard</ng-container>
          </div>
        </mat-list-item>
      </mat-list>

      <!-- Module definitions -->
      <mat-list role="list" class="list" *ngSwitchCase="7">
        <mat-list-item *ngFor="let moduleDefinition of moduleDefinitions" role="listitem" class="list-item"
                       (click)="getModules(moduleDefinition.moduleTemplateId, moduleDefinition.id)"
                       [class.list-item-selected]="moduleDefinitionId === moduleDefinition.id">
          <mat-icon class="list-item-icon" fontSet="fak" fontIcon="fa-modules"></mat-icon>
          {{ moduleDefinition.name }}
        </mat-list-item>
        <mat-list-item *ngFor="let definition of definitions" role="listitem" class="list-item"
                       (click)="addDefinitionToList(definition)"
                       [class.list-item-selected]="selectedDefinitions.indexOf(definition) > -1"
                       [ngClass]="definition !== definitions[definitions.length - 1]? '':'list-item-wo'">
          <lib-definition-icon [definitionType]="definition.type" class="list-item-icon"></lib-definition-icon>
          {{definition.name}}
        </mat-list-item>
      </mat-list>

      <!-- Crop groups -->
      <mat-list role="list" class="list" *ngSwitchCase="4">
        <mat-list-item *ngFor="let cropGroup of cropGroups" role="listitem" class="list-item"
                       (click)="getCropGroups(cropGroup.id, null, archiveYear)"
                       [ngClass]="!showArchive && (cropGroup === cropGroups[cropGroups.length - 1])? 'list-item-wo':'list-item'"
                       [class.list-item-selected]="cropGroupId === cropGroup.id">
          <mat-icon class="list-item-icon" fontSet="fas" fontIcon="fa-users"></mat-icon>
          {{cropGroup.name}}
        </mat-list-item>
        <mat-list-item *ngIf="showArchive && !loading" role="listitem" class="list-item list-item-wo"
                       (click)="getCropGroupArchives()">
          <i class="fas fa-box-archive list-item-icon-archive" aria-hidden="true"></i>
          <ng-container i18n="@@archive">Archive</ng-container>
        </mat-list-item>
      </mat-list>

      <!-- Archives -->
      <mat-list role="list" class="list" *ngSwitchCase="5">
        <mat-list-item *ngFor="let archive of archives" role="listitem" class="list-item"
                       (click)="getCropGroups(null, null, archive)"
                       [ngClass]="(archive === archives[archives.length - 1]) ? 'list-item-wo':'list-item'">
          <i class="fas fa-box-archive list-item-icon-archive" aria-hidden="true"></i>
          {{ archive }}
        </mat-list-item>
      </mat-list>

      <!-- Definitions -->
      <mat-list role="list" class="list" *ngSwitchCase="6">
        <mat-list-item
          *ngIf="showNewElementOption"
          (click)="createNewElement()"
          [class.list-item-selected]="selectedDefinitions.indexOf(newElementDef) > -1"
          class="new-element-item"
          sel-id="shared-select-definition-button-createelement">
          <mat-icon class="new-element-icon" fontSet="fas" fontIcon="fa-plus"></mat-icon>
          <ng-container i18n="@@dashboard.newDefinition">Create new element</ng-container>
        </mat-list-item>

        <mat-list-item *ngFor="let folder of folders" role="listitem" class="list-item"
                       (click)="addFolderToDefinitionsList(cropGroupId, folder.id, archiveYear)"
                       (dblclick)="getFolderDefinitions(cropGroupId, folder.id, archiveYear)"
                       [class.list-item-selected]="selectedFolders.indexOf(folder.id.toString()) > -1"
                       [ngClass]="(folder === folders[folders.length - 1]) && (definitions.length === 0) ? 'list-item-wo':''">
          <div class="u-flex u-center-content-vertically">
            <i class="fas fa-folder list-item-icon-archive" aria-hidden="true"></i>
            <div>{{folder.name}}</div>
            <div class="u-flex-divider"></div>
            <button mat-icon-button
                  class="list-item-folder-button" [class.rtl]="isRtl"
                  (click)="getFolderDefinitions(cropGroupId, folder.id, archiveYear)">
              <mat-icon fontSet="fas" [fontIcon]="folderArrow"></mat-icon>
            </button>
          </div>
        </mat-list-item>

        <mat-list-item *ngFor="let definition of definitions" role="listitem" class="list-item"
                       (click)="addDefinitionToList(definition)"
                       [class.list-item-selected]="selectedDefinitions.indexOf(definition) > -1"
                       [ngClass]="definition !== definitions[definitions.length - 1]? '':'list-item-wo'">
          <div class="u-flex">
            <lib-definition-icon [definitionType]="definition.type" class="list-item-icon"></lib-definition-icon>
            <div>{{definition.name}}</div>
          </div>
        </mat-list-item>


        <!-- Empty folder placeholder -->
        <mat-list-item
          *ngIf="(!folders || folders.length === 0) && (!definitions || definitions.length === 0) && !loading"
          role="listitem" class="list-item-wo">
          <ng-container i18n="@@emptyFolder">This folder is empty</ng-container>
        </mat-list-item>
      </mat-list>

    </ng-template>
  </div>
</ng-container>
