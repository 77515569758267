/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */
import { Injectable, inject } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { AuthState } from '@LIB_UTIL/auth/state/auth.state';
import { Store } from '@ngxs/store';
import { UserInfo } from '@LIB_UTIL/auth/model/auth.model';

@Injectable({
  providedIn: 'root',
})
export class MixPanelService {

  private store: Store = inject(Store);
  public projectToken: string = '82bd5b8e457afc7c333348a77e893594';
  // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase, @typescript-eslint/no-explicit-any
  public action: any = { debug: false, track_pageview: false, persistence: 'localStorage' };

  // eslint-disable-next-line max-lines-per-function, complexity
  public pushEventsToMixPanel(
    eventName: string, extraParameter?: string): void {

    // get the profile of the current user from the store
    const userInfo: UserInfo = this.store.selectSnapshot(AuthState.userInfo);

    mixpanel.init(this.projectToken, this.action);

    // userIdentity and emailAddress are null (--) if the event is from Login page
    let userIdentity: string;
    let emailAddress: string;
    if (eventName === 'sign_up_started'
      || eventName === 'reset_password'
      || eventName === 'login_completed'
      || eventName === 'sign_up_completed') {
      userIdentity = '--';
      emailAddress = '--';
    } else {
      userIdentity = userInfo.displayName;
      emailAddress = userInfo.emailAddress;
    }

    mixpanel.identify(userIdentity);

    mixpanel.people.set({
      '$name': userIdentity,
      '$email': emailAddress,
    });

    let trigger: string;
    let eventDefinition: string;
    let propertyType: string;
    let propertyName: string;
    let propertyDefinition: string;
    let dataType: string;
    let sampleValues: string;

    switch (eventName) {

      //Navigation menu items
      case 'dashboards_page_viewed':
        trigger = 'User selects Dashboards';
        eventDefinition = 'User selects Dashboards from the main left menu';
        propertyType = 'Event Property';
        break;

      case 'modules_page_viewed':
        trigger = 'User clicks on Modules';
        eventDefinition = 'User clicks on Modules in main left menu';
        propertyType = 'Event Property';
        propertyName = 'select_module_type';
        propertyDefinition = 'User selects a module to get to lower level modules';
        dataType = 'List';
        sampleValues = null;
        break;

      case 'crop_groups_page_viewed':
        trigger = 'Select Crop Groups';
        eventDefinition = 'Select Crop Groups from the main left menu';
        propertyType = 'Event Property';
        break;

      case 'folders_page_viewed':
        trigger = 'User clicks on Folders';
        eventDefinition = 'User clicks on Folders from the left main menu';
        propertyType = 'Event Property';
        break;

      case 'manage_multimedia_page_viewed':
        trigger = 'User clicks on Manage multimedia';
        eventDefinition = 'User clicks on Manage multimedia from the left main menu';
        propertyType = 'Event Property';
        break;

      case 'notifications_page_viewed':
        trigger = 'User clicks on notifications';
        eventDefinition = 'User clicks on notifications from the main left menu';
        propertyType = 'Event Property';
        propertyName = 'select_notifcation_options';
        propertyDefinition = 'User selects an option to interact with notifications';
        dataType = 'List';
        break;

      case 'settings_page_viewed':
        trigger = 'User selects Settings';
        eventDefinition = 'User selects Settings from User Profile drop down menu';
        propertyType = 'User Property';
        break;
        // End Navigation menu items

      //Dashboard Components
      case 'add_new_tile_selected':
        trigger = 'User clicks on + Add tile';
        eventDefinition = 'User clicks on + Add tile result is the tile selection menu';
        propertyType = 'Event Property';
        break;

      case 'dashboard_settings_opened':
        trigger = 'User clicks on dashboard settings';
        eventDefinition = 'User clicks on the three dots next to the Dashboard name';
        propertyType = 'Event Property';
        propertyName = 'dashboard_settings_options';
        propertyDefinition = 'User can choose between multiple options in the settings menu';
        dataType = 'List';
        sampleValues = `${extraParameter}`;
        break;

      case 'timelapse_player_opened':
        trigger = 'User clicks on Open player';
        eventDefinition = 'User clicks on Open player';
        propertyType = 'Event Property';
        break;

      case `${extraParameter}_tab_selected`:
        trigger = `User clicks on ${extraParameter} tab`;
        eventDefinition = `User clicks in the period selector on the tab ${extraParameter}`;
        propertyType = 'Event Property';
        break;

      case 'period_selector_applied':
        trigger = 'User clicks on Apply';
        eventDefinition = 'User clicks on apply and the Period selections are applied to the Personal dashboard';
        propertyType = 'Event Property';
        break;

      case 'period_selector_opened':
        trigger = 'User clicks on Select period';
        eventDefinition = 'User clicks on Select period from the dashboard view';
        propertyType = 'Event Property';
        break;

      case 'period_selector_cancelled':
        trigger = 'User clicks on Cancel';
        eventDefinition = 'User clicks on cancel and the Select period frame closes';
        propertyType = 'Event Property';
        break;

      case 'timelapse_cancelled':
        trigger = 'User clicks on Cancel';
        eventDefinition = 'User clicks on cancel and the Select period frame closes';
        propertyType = 'Event Property';
        break;

      case `dashboard_${extraParameter}_type_selected`:
        trigger = 'User selects a dashboard';
        eventDefinition = 'User selects a dashboard';
        propertyType = 'Event Property';
        propertyName = 'dashboard_selected';
        propertyDefinition = 'Dashboard selected from one of the available categories';
        dataType = 'List';
        sampleValues = `${extraParameter} dashboard`;
        break;

      case `dashboard_${extraParameter}_selected`:
        trigger = `User selects ${extraParameter} dashboard`;
        eventDefinition = `User selects ${extraParameter} dashboard`;
        propertyType = 'Event Property';
        propertyName = `dashboard_${extraParameter}_selected`;
        propertyDefinition = `Dashboard ${extraParameter} selected`;
        dataType = 'List';
        break;

      case 'timelaps_controls_edited':
        trigger = 'User is interacting with Timelaps controls';
        eventDefinition = 'User is interacting with Timelaps controls';
        propertyType = 'Event Property';
        propertyName = 'timelapse_controls_actions';
        propertyDefinition = 'User is interacting with the Timelap controls';
        dataType = 'List';
        sampleValues = `${extraParameter}`;
        break;
        //End Dashboard Components

      //Dashboard Modals
      case 'new_tile_type_added':
        trigger = 'User clicks on + Add tile to add the tile to the dashboard';
        eventDefinition = 'User clicks on + Add tile to add the tile to the dashboard';
        propertyType = 'Event Property';
        propertyName = 'tile_type_added';
        propertyDefinition = 'User adds tile to dashboard';
        dataType = 'List';
        sampleValues = `${extraParameter}`;
        break;

      case 'new_dashboard_created':
        trigger = 'User creates a new dashboard';
        eventDefinition = 'User clicks on Create dashboard after folder selection and naming';
        propertyType = 'Event Property';
        propertyName = 'dashboard_type_created';
        propertyDefinition = 'User selects Personal dashboard or Crop group dashboard and completes the creation flow';
        dataType = 'List';
        sampleValues = `${extraParameter}`;
        break;

      case 'create_new_dashboard_cancelled':
        trigger = 'User clicks on Close';
        eventDefinition = 'User clicks on close and aborts the create new dashboard flow';
        propertyType = 'Event Property';
        break;

        //End Dashboard Modals

      //date-range-picker-form
      case 'period_selected':
        trigger = 'User Chooses a period';
        eventDefinition = 'User Chooses a period from the dropdown list';
        propertyType = 'Event Property';
        propertyName = 'choose_period';
        propertyDefinition = 'User Chooses a period from the dropdown list';
        dataType = 'List';
        sampleValues = `${extraParameter}`;
        break;

      case 'show_whole_days_box_selected':
        trigger = 'User selects the Show whole days box';
        eventDefinition = 'User selects the Show whole days box';
        propertyType = 'Event Property';
        propertyName = 'show_whole_days_option';
        propertyDefinition = 'User checks or unchecks the box';
        dataType = 'Boolean';
        sampleValues = `${extraParameter}`;
        break;

      case 'pin_period_clicked':
        trigger = 'User clicks on Pin period button';
        eventDefinition = 'User clicks on Pin period button';
        propertyType = 'Event Property';
        propertyName = 'pin_unpin_period';
        propertyDefinition = 'User selects option to pin or unpin period';
        dataType = 'Boolean';
        sampleValues = `${extraParameter}`;
        break;
        //End date-range-picker-form

      case 'Announcements_viewed':
        trigger = 'User clicks on Announcements';
        eventDefinition = 'User clicks on Announcements from user profile drop down';
        propertyType = 'Event Property';
        break;

      //strategy-manager
      case 'strategy_manager_page_viewed':
        trigger = 'User clicks on Strategy Manager';
        eventDefinition = 'User clicks on Strategy Manager from the main left menu';
        propertyType = 'Event Property';
        break;

      case 'strategy_selected':
        trigger = 'User selects a specific strategy';
        eventDefinition = 'User selects a specific strategy from the list with available strategies';
        propertyType = 'Event Property';
        propertyDefinition = 'User selects a viewing method to see the insights';
        dataType = 'List';
        break;

      case 'edit_options_selected':
        trigger = 'User clicks on edit in Strategy options';
        eventDefinition = 'User clicks on the 3 vertical dots, opening the options, user clicks on Note';
        propertyType = 'Event Property';
        propertyName = 'select_edit_options';
        dataType = 'List';
        sampleValues = `${extraParameter}`;
        break;

      case `${extraParameter}_view_selected`:
        trigger = `User clicks on ${extraParameter} view`;
        eventDefinition = `User switches to the ${extraParameter} view`;
        propertyType = 'Event Property';
        break;

      case 'create_new_strategy_selected':
        trigger = 'User clicks on + New strategy';
        eventDefinition = 'User clicks on + New strategy wanting to create a new strategy';
        propertyType = 'Event Property';
        propertyName = 'create_new_strategy_options';
        propertyDefinition = 'User selects cancel or save';
        dataType = 'Boolean';
        sampleValues = `${extraParameter}`;
        break;
        //End-strategy-manager

      //Login
      case 'sign_up_started':
        trigger = 'User clicks on Create an account';
        eventDefinition = 'User clicks on Create an account';
        propertyType = 'User Property';
        break;

      case 'reset_password':
        trigger = 'User clicks on Reset password or username';
        eventDefinition = 'User successfully requests new password';
        propertyType = 'User Property';
        break;

      case 'login_completed':
        trigger = 'User successfully logs in';
        eventDefinition = 'User successfully logs in and enters the landing page';
        propertyType = 'User Property';
        break;

      case 'sign_up_completed':
        trigger = 'User Creates successfully account';
        eventDefinition = 'User clicks on the final step "Create an account"';
        propertyType = 'User Property';
        break;
        //End Login

      //crop-groups
      case `cropGroup_${extraParameter}_selected`:
        trigger = `User clicks on ${extraParameter}`;
        eventDefinition = `User clicks on ${extraParameter}`;
        propertyType = 'Event Property';
        propertyName = `select_${extraParameter}_insights`;
        propertyDefinition = 'User selects a viewing method to see the insights';
        dataType = 'List';
        break;
        //End crop-groups

      //Modules
      case `module_${extraParameter}_selected`:
        trigger = `User clicks on ${extraParameter}`;
        eventDefinition = `User clicks on ${extraParameter}`;
        propertyType = 'Event Property';
        propertyName = `select_${extraParameter}_insights`;
        propertyDefinition = 'User selects a viewing method to see the insights';
        dataType = 'List';
        break;
        //End Modules

      //Settings
      case 'language_selected':
        trigger = 'User succesfully selects a language';
        eventDefinition = 'User succesfully selects a language';
        propertyType = 'User Property';
        propertyName = 'default_language';
        propertyDefinition = 'Selected Language (e.g., English, Dutch etc.)';
        dataType = 'List';
        break;

      case 'theme_selected':
        trigger = 'User selects theme';
        eventDefinition = 'User succesfully selects theme';
        propertyType = 'User Property';
        propertyName = 'theme_type';
        propertyDefinition = 'Theme Type(e.g., Dark)';
        dataType = 'Boolean';
        break;

      case 'menu_visibility_selected':
        trigger = 'User selects menu visibility option';
        eventDefinition = 'User succesfully selects menu visibility option';
        propertyType = 'User Property';
        propertyName = 'menu_visibility';
        propertyDefinition = 'Menu Visibility (Visible, Hidden)';
        dataType = 'Boolean';
        sampleValues = `${extraParameter}`;
        break;

      case 'first_day_of_the_week_selected':
        trigger = 'User selects First day of the week';
        eventDefinition = 'User selects First day of the week';
        propertyType = 'User Property';
        propertyName = 'first_day';
        propertyDefinition = 'Selected Day (e.g., Sunday, Monday, etc.)';
        dataType = 'List';
        sampleValues = `${extraParameter}`;
        break;

      case 'legend_visibility_selected':
        trigger = 'User selects legend view option';
        eventDefinition = 'User selects legend view option';
        propertyType = 'User Property';
        propertyName = 'visibility_option';
        propertyDefinition = 'Visibility Status (Visible, Hidden)';
        dataType = 'Boolean';
        sampleValues = `${extraParameter}`;
        break;

      case 'legend_sorted':
        trigger = 'User selects sort legend option';
        eventDefinition = 'User selects sort legend option';
        propertyType = 'User Property';
        propertyName = 'sorting_option';
        propertyDefinition = 'Sorting Method (Not automatically sorted, Automatically sorted)';
        dataType = 'Boolean';
        sampleValues = `${extraParameter}`;
        break;

      case 'data_export_format_selected':
        trigger = 'User selects Data export File format';
        eventDefinition = 'User selects Data export File format';
        propertyType = 'User Property';
        propertyName = 'export_format';
        propertyDefinition = 'Exported File Format (e.g., *.txt, *.csv, *.xlsx, etc.)';
        dataType = 'List';
        sampleValues = `${extraParameter}`;
        break;

      case 'country_settings_selected':
        trigger = 'User selects Country settings';
        eventDefinition = 'User selects Country settings';
        propertyType = 'User Property';
        propertyName = 'country_settings';
        propertyDefinition = 'Selected Country (e.g. Afar, Afrikaans, Aghem etc)';
        dataType = 'List';
        sampleValues = `${extraParameter}`;
        break;

      case 'tooltip_format_selected':
        trigger = 'Tooltip format - user selects option';
        eventDefinition = 'Tooltip format - user selects option';
        propertyType = 'User Property';
        propertyName = 'tooltip_format';
        // eslint-disable-next-line max-len
        propertyDefinition = 'Tooltip Format (No tooltip, Color, description and value, Color and value, Description and value)';
        dataType = 'List';
        sampleValues = `${extraParameter}`;
        break;
        //End Settings

      //user-management
      case 'user_management_viewed':
        trigger = 'User selects User Management';
        eventDefinition = 'User selects User Management from the Settings page';
        propertyType = 'Event Property';
        break;

      case 'join_crop_group_selected':
        trigger = 'User clicks on Join a crop group';
        eventDefinition = 'User clicks on Join a crop group';
        propertyType = 'Event Property';
        break;

      case 'activate_module':
        trigger = 'User clicks on Activate a module';
        eventDefinition = 'User clicks on Activate a module';
        propertyType = 'Event Property';
        break;

      case 'remove_member_selected':
        trigger = 'User clicks on Remove member(s)';
        eventDefinition = 'User clicks on Remove member(s) after selecting the member(s)';
        propertyType = 'Event Property';
        break;

      case 'crop_groups_viewed':
        trigger = 'User clicks on the Crop groups tab';
        eventDefinition = 'User clicks on the Crop groups tab';
        propertyType = 'Event Property';
        break;

      case 'crop_group_selected':
        trigger = 'User selects a Crop group from the list';
        eventDefinition = 'User clicks on a Crop group from the list and opens the side panel with details';
        propertyType = 'Event Property';
        break;

      case 'join_crop_group_code_verified':
        trigger = 'User enters Verification code and clicks Verify code';
        // eslint-disable-next-line max-len
        eventDefinition = 'User enters Verification code and clicks Verify code from the Add a crop group to your account window';
        propertyType = 'Event Property';
        break;

      case 'join_crop_group_canceled':
        trigger = 'User clicks on Cancel in Add a crop group to your account';
        eventDefinition = 'User clicks on Cancel in Add a crop group to your account window';
        propertyType = 'Event Property';
        break;

      case 'remove_user':
        trigger = 'User clicks on Remove user(s)';
        eventDefinition = 'User clicks on Remove user(s) after selecting the user(s)';
        propertyType = 'Event Property';
        break;

      case 'modules_viewed':
        trigger = 'User clicks on the Modules tab';
        eventDefinition = 'User clicks on the Modules tab';
        propertyType = 'Event Property';
        break;

      case 'add_module_code_verified':
        trigger = 'User enters Verification code and clicks Verify code in Add a module to your account';
        eventDefinition = 'User enters Verification code and clicks Verify code in Add a module to your account';
        propertyType = 'Event Property';
        break;

      case 'add_module_canceled':
        trigger = 'User clicks on Cancel in Add a module to your account';
        eventDefinition = 'User clicks on Cancel in Add a module to your account';
        propertyType = 'Event Property';
        break;

      case 'user_tab_viewed':
        trigger = 'User clicks on the Users tab';
        eventDefinition = 'User clicks on the Users tab';
        propertyType = 'Event Property';
        break;

      case 'send_email_to_user_selected':
        trigger = 'User clicks on a User and option Send e-mail';
        eventDefinition = 'User clicks on a User and option Send e-mail via 3 vertical dots';
        propertyType = 'Event Property';
        break;

      case 'remove_member_confirmed':
        trigger = 'User clicks on Remove member(s)';
        eventDefinition = 'User clicks on Remove member(s) after selecting the member(s)';
        propertyType = 'Event Property';
        break;

      case 'remove_user_confirmed':
        trigger = 'User clicks on Remove member(s)';
        eventDefinition = 'User clicks on Remove member(s) after selecting the member(s)';
        propertyType = 'Event Property';
        break;

      //End user-management


    }

    mixpanel.track(eventName, {
      'Trigger': trigger,
      'Event Definition': eventDefinition,
      'Property Type': propertyType,
      'Property Name': propertyName,
      'Property Definition': propertyDefinition,
      'Data Type': dataType,
      'Sample Values': sampleValues,
    });
  }
}

