import { AuthService } from '@LIB_UTIL/auth/services/auth.service';
import { environment } from '../environments/environment';
import { SnackbarService } from '@LIB_UTIL/services/snackbar.service';
import { ModalService } from '@LIB_UTIL/services/modal.service';

export function navigateToGraphPage(
  locale: string,
  sid: string,
  definitionId: number,
  cropGroupId: number,
  moduleTemplateId: number
): void {
  const domain: string = environment.newPlatformUrl;
  const id: string = `${ definitionId }-0-0`;
  const cropGroupParam: string = cropGroupId
    ? `&cropGroupId=${ cropGroupId.toString() }`
    : '';
  const moduleTemplateParam: string = moduleTemplateId
    ? `&moduleTemplateId=${ moduleTemplateId.toString() }`
    : '';

  let chartUrl: string = `${ domain }/${ locale }/charts/${ definitionId }`;
  chartUrl += `?sid=${ sid }&id=${ id }${ cropGroupParam }${ moduleTemplateParam }`;

  window.location.assign(chartUrl);
}

export function navigateToGaugePage(
  locale: string,
  sid: string,
  definitionId: number,
  cropGroupId: number,
  moduleTemplateId: number
): void {
  const domain: string = environment.newPlatformUrl;
  const cropGroupParam: string = cropGroupId
    ? `&cropGroupId=${ cropGroupId.toString() }`
    : '';
  const moduleTemplateParam: string = moduleTemplateId
    ? `&moduleTemplateId=${ moduleTemplateId.toString() }`
    : '';

  let gaugeUrl: string = `${ domain }/${ locale }/gauges/${ definitionId }`;
  gaugeUrl += `?sid=${ sid }&${ cropGroupParam }${ moduleTemplateParam }`;

  window.location.assign(gaugeUrl);
}


export function navigateToDashboard(locale: string, sid: string, dashboardId: number, cropGroupId: number): void {
  const domain: string = environment.newPlatformUrl;

  const cropGroupParam: string = cropGroupId
    ? `&cropGroupId=${ cropGroupId.toString() }`
    : '';

  const dashboardUrl: string
    = `${ domain }/${ locale }/dashboard/${ dashboardId }?sid=${ sid }${ cropGroupParam }`;

  window.location.assign(dashboardUrl);
}

export function navigateToCompanyPage(locale: string, sid: string): void {
  const domain: string = environment.newPlatformUrl;

  const companyUrl: string
    = `${ domain }/${ locale }/company?sid=${ sid }`;

  window.location.assign(companyUrl);
}

export function navigateToKpiPage(locale: string, sid: string, definitionId: number): void {
  const domain: string = environment.newPlatformUrl;

  const companyUrl: string
    = `${ domain }/${ locale }/kpi/${ definitionId }?sid=${ sid }`;

  window.location.assign(companyUrl);
}


/**
 * Remove a query string parameter from a complete url.
 */
export function removeQueryStringParameter(url: string, parameterName: string): string {
  const urlObject: URL = new URL(url);
  const searchParams: URLSearchParams = urlObject.searchParams;

  // Remove the query string parameter
  searchParams.delete(parameterName);

  // Reconstruct the modified URL
  urlObject.search = searchParams.toString();

  return urlObject.toString();
}

/**
 * Add a query string parameter to a complete url.
 */
export function addQueryStringParameter(url: string, parameterName: string, parameterValue: string): string {
  const urlObject: URL = new URL(url);
  const searchParams: URLSearchParams = urlObject.searchParams;

  searchParams.delete(parameterName);
  searchParams.append(parameterName, parameterValue);

  // Reconstruct the modified URL
  urlObject.search = searchParams.toString();

  return urlObject.toString();
}

export function removeSidFromCurrentUrl(): string {
  return removeQueryStringParameter(window.location.href, 'sid');
}

/**
 * Helper function to add the url including the sid to the clipboard.
 * This will be handy for developers (and customer support probably)
 */
export function copyUrlWithSidToClipboard(
  event: MouseEvent,
  authService: AuthService,
  snackbarService: SnackbarService,
  modalService: ModalService
): void {
  if (event.ctrlKey) {
    const urlWithSid: string = addQueryStringParameter(document.location.href, 'sid', authService.sid);

    if (navigator.clipboard) {
      navigator.clipboard.writeText(urlWithSid);
      snackbarService.success('Url copied to clipboard'); // no need to translate
    } else {
      modalService.showPopupMessage({
        title: 'URL',
        message: urlWithSid,
        closeText: 'Close',
      });
    }
  }
}
