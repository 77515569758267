/**
 * Build the query string for the api call.
 */
 export const queryStringBuilder = (queryParams: any): string => // eslint-disable-line
  Object.keys(queryParams).reduce((acc: string, key: string) => {
    const value: unknown = queryParams[key];
    if (Array.isArray(value)) {
      return (acc === '' ? '?' : '') + `${acc}&` + value.map(val => `${key}=${val}`).join('&');
    } else if (queryParams[key] !== null && queryParams[key] !== undefined) {
      return `${acc === '' ? '?' : `${acc}&`}${key}=${queryParams[key]}`;
    } else {
      return acc;
    }
  }, '');
