import { getTimezoneOffset } from 'date-fns-tz';


/**
 * Returns the default user's timezone.
 * This will return the correct timezone 99% of the time.
 * However on a iSii we have to perform a call to ip-api.com.
 */
// eslint-disable-next-line new-cap
export const defaultTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Get the timezone as determined in the timezone.guard.ts.
 *
 * @example
 * Europe/Amsterdam
 * America/New_York
 */
export const getCachedTimezone = (): string | null => sessionStorage.getItem('timezone');
export const getCachedTimezoneOffset = (): string | null => sessionStorage.getItem('timezoneOffset');

/**
 * Get the offset in milliseconds between the localtime and UTC.
 *
 * To prevent an incorrect offset due to daylight savings, make
 * sure to get the timezone offset at the the provided date.
 */
export const offsetInMilliSeconds = (timestamp?: number): number => {

  const offSet: string = getCachedTimezoneOffset();
  if (offSet) {
    return Number(offSet) * 60;

  } else {
    const timezone: string = getCachedTimezone() || defaultTimezone;
    // If a timestamp has been provided use that as reference data. Otherwise fallback to now.
    const refDate: Date = timestamp ? new Date(timestamp) : new Date();

    return getTimezoneOffset(timezone, refDate);
  }

};

/**
 * Get the offset in minutes between the localtime and UTC.
 */
export const offsetInMinutes = (): number => {

  return offsetInMilliSeconds() / (60 * 1000);
};

