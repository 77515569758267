import { DashboardStateModel } from '@LG_ROOT/app/state/dashboard/dashboard.state.model';
import { TimelapseUnits } from '@LIB_UTIL/model/timelapse';

export const dashboardStateDefaults: DashboardStateModel = {
  errorMessage: '',
  dashboard: null,
  dashboards: null,
  dashboardData: null,
  activeCrops: null,
  timelapse: {
    displayControls: false,
    timestamp: new Date().getTime(),
    stepSize: 5,
    unit: TimelapseUnits.minute,
    speedInSeconds: 1,
  },
  hoveredTimestamp: null,
  isLoading: true,
  dashboardHasChanges: false,
};
