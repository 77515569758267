import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { AuthService } from '@LIB_UTIL/auth/services/auth.service';
import { environment } from '@LIB_UTIL/environments/environment';
import { NavItem } from '@LIB_UTIL/layout/models/nav-item.model';
import { lastValueFrom } from 'rxjs';
import { CompanyApiService } from '@LG_CORE/company/company-api.service';
import { CompanyModel } from '@LG_CORE/company/company.model';
import { StrategyManagerApiService } from '@LIB_UTIL/services/strategy-manager-access.api.service';

@Component({
  selector: 'layout-wrapper',
  templateUrl: './layout-wrapper.component.html',
})
export class LayoutWrapperComponent implements OnInit {

  public navItems: NavItem[] = [];

  constructor(
    private strategyManagerApiService: StrategyManagerApiService,
    private companyApiService: CompanyApiService,
    private authService: AuthService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  public ngOnInit(): void {
    this.createNavItems();
  }

  // eslint-disable-next-line max-lines-per-function
  private async createNavItems(): Promise<void> {
    this.navItems = [{
      routerLink: ['/dashboard'],
      fontSet: 'fak',
      fontIcon: 'fa-dashboard',
      translatedTitle: $localize`:@@dashboards:Dashboards`,
      seleniumId: 'dashboard',
    }, {
      routerLink: ['/modules'],
      fontSet: 'fak',
      fontIcon: 'fa-modules',
      translatedTitle: $localize`:@@modules:Modules`,
      seleniumId: 'modules',
    }, {
      routerLink: ['/crop-groups'],
      fontSet: 'fas',
      fontIcon: 'fa-users',
      translatedTitle: $localize`:@@cropGroups:Crop groups`,
      seleniumId: 'crop-groups',
      className: 'nav-item-crop-groups',
    }, {
      routerLink: ['/folders'],
      fontSet: 'fas',
      fontIcon: 'fa-folder',
      translatedTitle: $localize`:@@folders:Folders`,
      seleniumId: 'folders',
    }, {
      routerLink: ['/photos'],
      fontSet: 'fas',
      fontIcon: 'fa-image',
      translatedTitle: $localize`:@@manageMultimedia:Manage multimedia`,
      seleniumId: 'photos',
    }, {
      routerLink: ['/notifications'],
      fontSet: 'fak',
      fontIcon: 'fa-notification-settings',
      translatedTitle: $localize`:@@notifications:Notifications`,
      seleniumId: 'notifications',
    }];

    const hasStrategyManagerModule: boolean = await lastValueFrom(
      this.strategyManagerApiService.getStrategyManagerAccess()
    );

    if (hasStrategyManagerModule) {
      const url: string = `${environment.strategyManagerUrl}/${this.locale}/?sid=${this.authService.sid}`;
      this.navItems.push({
        href: url,
        routerLink: [''],
        fontSet: 'fak',
        fontIcon: 'fa-strategy-manager',
        translatedTitle: $localize`:@@strategyManager.strategyManager:Strategy Manager`,
        seleniumId: 'strategy-manager',
      });
    }

    const companies: CompanyModel[] = await lastValueFrom(
      this.companyApiService.getCompanies()
    );

    if (companies.length > 0) {
      this.navItems.push({
        routerLink: ['/company'],
        fontSet: 'fas',
        fontIcon: 'fa-building',
        translatedTitle: $localize`:@@company:Company`,
      });
    }
  }
}
