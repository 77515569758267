import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ColoredIconComponent } from './colored-icon/colored-icon.component';
import { InfoIconComponent } from './info-icon/info-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DefinitionIconComponent } from './definition-icon/definition-icon.component';
import { MenuIconComponent } from './menu-icon/menu-icon.component';
import { PinIconComponent } from './pin-con/pin-icon.component';


const materialModules: any[] = [// eslint-disable-line
  MatIconModule,
  MatTooltipModule,
];

const moduleComponents: any[] = [// eslint-disable-line
  ColoredIconComponent,
  InfoIconComponent,
  DefinitionIconComponent,
  MenuIconComponent,
  PinIconComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ...materialModules,
  ],
  declarations: [
    ...moduleComponents,
  ],
  exports: [
    ...moduleComponents,
  ],
})
export class LgIconModule {
}
