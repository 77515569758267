import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { get } from 'lodash';

@Component({
  selector: 'app-iframe-modal',
  templateUrl: './iframe-modal.component.html',
  styleUrls: ['./iframe-modal.component.scss'],
})
export class IframeModalComponent implements OnInit {

  public url: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { url: string }
  ) {}

  public ngOnInit(): void {
    this.url = get(this.data, 'url', null);
  }

}
