import { Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { environment } from '@LIB_UTIL/environments/environment';
import { DashboardModel } from '@LG_CORE/dashboards/dashboards.model';
import { Subject } from 'rxjs';
import { getDefaultCropId } from '@LG_DASHBOARD/components/crop-select/crop-select.helper';
import { PeriodModel, PeriodType } from '@LIB_UTIL/model/period.model';
import { LocaleID } from '@LIB_UTIL/util/locale';
import { AuthService } from '@LIB_UTIL/auth/services/auth.service';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class SignalRService implements OnDestroy {

  private incomingTime$: Subject<string> = new Subject<string>();
  private incomingTimeSubscribed: boolean = false;

  private lastSubId: number;
  private lastStartDateFormatted: string;
  private lastEndDateFormatted: string;

  private _hubConnection: HubConnection;

  constructor(
    public authService: AuthService,
    @Inject(LOCALE_ID) private locale: LocaleID
  ) {
  }

  private get hubConnection(): HubConnection {
    return this._hubConnection;
  }

  public get incomingTime(): Subject<string> {
    return this.incomingTime$;
  }

  private resetLastSubId(): void {
    this.lastSubId = null;
  }

  private buildConnection(): void {
    this._hubConnection = new HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(`${environment.signalRUrl}LetsGrowHub`)
      .build();
  }

  public ngOnDestroy(): void {
    if (this.hubConnection.state === HubConnectionState.Connected) {
      this.hubConnection.stop();
      this.resetLastSubId();
      this.incomingTimeSubscribed = false;
    }
  }

  public async initSignalR(dashboard: DashboardModel): Promise<void> {
    await this.subscribe(dashboard.id, dashboard.subMenuDefinitionId);

    if (dashboard.period.pinnedPeriodType !== PeriodType.None) {
      this.changeDashboardPeriod(dashboard.subMenuDefinitionId, dashboard.period);
    }

    if (!!dashboard.crops?.length) {
      this.changeDashboardCrop(getDefaultCropId(dashboard.crops).id, dashboard.subMenuDefinitionId);
    }
  }

  private async subscribe(id: number, subMenuDefinitionId: number): Promise<void> {
    if (!this.hubConnection) {
      this.buildConnection();
    }

    if (this.lastSubId === id && HubConnectionState.Connected) {
      return;
    }

    if (this.hubConnection.state === HubConnectionState.Connected) {
      await this.hubConnection.stop();
      this.resetLastSubId();
    }

    if (this.hubConnection.state === HubConnectionState.Disconnected) {
      return this.hubConnection
        .start()
        .then(() => this.hubConnection.invoke(
          'Subscribe',
          id.toString(),
          '0',
          this.getSid(),
          subMenuDefinitionId.toString()
        ).then(() => {
          this.lastSubId = id;
          this.subscribeToIncomingTime();
        }))
        .catch(error => console.error(error));
    }
  }

  public changeDashboardTime(
    date: string,
    dashboardId: number,
    fakeId: number,
    subMenuDefinitionId: number
  ): Promise<void> {
    return this.hubConnection
      .invoke(
        'SynchronizeTime',
        dashboardId.toString(),
        fakeId.toString(),
        this.getSid(),
        subMenuDefinitionId.toString(),
        date
      );
  }

  private subscribeToIncomingTime(): void {
    if (this.hubConnection.state === HubConnectionState.Connected && !this.incomingTimeSubscribed) {
      this.incomingTimeSubscribed = true;
      this.hubConnection.on('IncomingTime', (msg) => this.incomingTime$.next(msg));
    }
  }

  public changeDashboardPeriod(subMenuDefinitionId: number, period?: PeriodModel): Promise<void> {
    if (this.lastSubId) {
      if (period) {
        // Set culture to 'en' when using 'ar' culture. Otherwise, signalR date is formatted incorrectly
        if (this.locale === 'ar') {
          dayjs.locale('en');
        }
        this.lastStartDateFormatted = dayjs(period.startDate).format('YYYY-MM-DD');
        this.lastEndDateFormatted = dayjs(period.endDate).format('YYYY-MM-DD');
      }

      return this.hubConnection
        .invoke('SynchronizePeriod',
          this.lastSubId.toString(),
          this.getSid(),
          subMenuDefinitionId.toString(),
          this.lastStartDateFormatted,
          this.lastEndDateFormatted);
    }

    return Promise.resolve();
  }

  public changeDashboardCrop(cropId: number, subMenuDefinitionId: number): Promise<void> {
    if (this.lastSubId) {
      return this.hubConnection.invoke(
        'SynchronizeCrop',
        this.lastSubId.toString(),
        this.getSid(),
        subMenuDefinitionId.toString(),
        cropId.toString()
      );
    }

    return Promise.resolve();
  }

  public compareDashboardCrop(cropId: number, subMenuDefinitionId: number): Promise<void> {
    if (this.lastSubId) {
      const cropIdString: string = cropId ? cropId.toString() : '-1';

      return this.hubConnection.invoke(
        'CompareCrop',
        this.lastSubId.toString(),
        this.getSid(),
        subMenuDefinitionId.toString(),
        cropIdString
      );
    }

    return Promise.resolve();
  }

  public hideDashboardCrops(cropIds: number[], subMenuDefinitionId: number): Promise<void> {
    const cropsString: string = cropIds.join(',').toString();
    if (this.lastSubId) {
      const cropIdString: string = cropIds ? cropsString : '-1';

      return this.hubConnection.invoke(
        'SynchronizeHideCrops',
        this.lastSubId.toString(),
        this.getSid(),
        subMenuDefinitionId.toString(),
        cropIdString
      );
    }

    return Promise.resolve();
  }

  /**
   * Retrieve sid from authorisation service
   */
  public getSid(): string {
    return this.authService.sid;
  }
}
