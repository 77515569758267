import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: [],
})
export class InformationComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:
    {
      title: string;
      text: string;
      buttonText: string;
    }) {
  }
}
