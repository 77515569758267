const SID_SESSION_STORAGE_KEY: string = 'LG-sid';

/**
 * When the session has expired and user logs in again,
 * the old sessionId was being used. Before redirect to login page,
 * clear the sid to make sure after logging in the new sid is used.
 */
export function clearSid(): void {
  sessionStorage.removeItem(SID_SESSION_STORAGE_KEY);
}

export function hasSid(): boolean {
  const sid: string = sessionStorage.getItem(SID_SESSION_STORAGE_KEY);

  return !!sid;
}
