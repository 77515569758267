export const filterOnId = (originalId: any) => ({ id }) => originalId !== id;

export const mapEditMode = (entityId: number, editMode: boolean) => (e: any) => ({
  ...e,
  editMode: e.id === entityId ? editMode : false,
});

export const mapRename = (entityId: number, name: string, url?: string) => (e) => ({
  ...e,
  name: e.id === entityId ? name : e.name,
  url: (e.id === entityId && url && e.hasOwnProperty('url')) ? url : e.url,
  editMode: false,
});
