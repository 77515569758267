<div class="modal-header" mat-dialog-title>
  <h2>{{titleDescription}}</h2>
</div>


<div class="modal-content" mat-dialog-content>
  <div class="label" *ngIf="showSubTitle">{{subTitleDescription}}</div>
  <mat-form-field>
    <input matInput type="text" [appFocusOnLoad]="200" [(ngModel)]="name"/>
  </mat-form-field>

  <div class="label" *ngIf="!defaultType" i18n="@@type">Type</div>
  <ul class="selectable-list" *ngIf="!defaultType">
    <li *ngFor="let item of items" [class.selected]="selectedType === item" (click)="selectedType = item">
      <lib-colored-icon [fontSet]="getIcon(item).fontSet" [fontIcon]="getIcon(item).icon"></lib-colored-icon>
      <span class="title" [ngSwitch]="item">
        <ng-container *ngSwitchCase="'graph'" i18n="@@graph" sel-id="add-entity-graph">Graph</ng-container>
        <ng-container *ngSwitchCase="'gauge'" i18n="@@common.visual" sel-id="add-entity-visual">Visual</ng-container>
        <ng-container *ngSwitchCase="'report'" i18n="@@table" sel-id="add-entity-table">Table</ng-container>
        <ng-container *ngSwitchCase="'plan'" i18n="@@map" sel-id="add-entity-map">Map</ng-container>
        <ng-container *ngSwitchCase="'dataentry'" i18n sel-id="add-entity-data-entry">Data entry</ng-container>
        <ng-container *ngSwitchCase="'dashboard'" i18n sel-id="add-entity-dashboard">Dashboard</ng-container>
        <ng-container *ngSwitchCase="'folder'" i18n sel-id="add-entity-folder">Folder</ng-container>
        <ng-container *ngSwitchCase="'export'" i18n sel-id="add-entity-export">Export</ng-container>
      </span>
    </li>
  </ul>

</div>


<div class="modal-footer" mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close i18n="@@common.close" class="close" sel-id="add-entity-close-button">Close</button>
  <button mat-flat-button color="accent"
          class="with-mat-icon"
          [disabled]="!name || !selectedType" (click)="close()"
          sel-id="add-entity-add-button">
    <mat-icon fontSet="fas" fontIcon="fa-plus"></mat-icon>
    <ng-container i18n="@@common.add">Add</ng-container>
  </button>
</div>
