<lib-navigation
  [overrideBrand]="overrideBrand"
  [navItems]="navItems"
  [logo]="logo"
  [applyBranding]="applyBranding"
  class="navigation">
</lib-navigation>
<div class="content">
  <div class="mobile-content-cover u-full-space" (click)="hideNavigation()"></div>
  <router-outlet></router-outlet>
</div>
<div class="full-screen-handle" [class.show]="isFullScreen" (click)="deactivateFullScreen()">
  <ng-container i18n>Click here to close full screen</ng-container>
  <i class="fas fa-xmark"></i>
</div>
