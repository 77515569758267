import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@LIB_UTIL/services/config/config.service';
import { Observable } from 'rxjs';
import { CompanyModel } from '@LG_CORE/company/company.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyApiService{

  constructor(private http: HttpClient, private cfg: ConfigService) {
  }

  /**
   * Retrieves for the current user all companies
   */
  public getCompanies(): Observable<CompanyModel[]> {
    const url: string = `${this.cfg.endpoints.companies}`;

    return this.http.get<CompanyModel[]>(url);
  }
}
