import { get } from 'lodash';
import { Folder, FolderDetails } from '@LG_CORE/folders/folders.model';

const getFolders = ({ folders }: FolderDetails) => folders.map(({ id }: Folder) => id);

export const getDescendentIds = (foldersState, deletedId: number): string[] => {
  const folder = get(foldersState, deletedId, { folders: [] });

  return getFolders(folder).reduce((acc, id) => [...acc, id, ...getDescendentIds(foldersState, id)], []);
};
