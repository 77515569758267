<div class="navigation"
     [class.expanded]="navigationExpanded$ | async"
     [class.full-screen]="isFullScreen$ | async"
     [class.rtl]="isRtl"
     id="main-nav">

  <lib-logo [overrideBrand]="overrideBrand" [logo]="logo" [applyBranding]="applyBranding"  (click)="navClick()"></lib-logo>

  <div class="user u-hide-lap" *ngIf="(userInfo$ | async)">
    <div class="display-name">{{ (userInfo$ | async)?.displayName }}</div>
    <div class="email">{{ (userInfo$ | async)?.emailAddress }}</div>
  </div>

  <div class="navigation-items nav-item-container">
    <!-- The list of navigation items -->
    <lib-nav-item *ngFor="let item of navItems" [navItem]="item" [expanded]="navigationExpanded$ | async"></lib-nav-item>
  </div>

  <div class="u-flex-divider"></div>

  <!-- Support link -->
  <div class="nav-item-container support" *ngIf="showSupportLink$ | async">
    <lib-nav-item [navItem]="supportNavItem" [expanded]="navigationExpanded$ | async"></lib-nav-item>
  </div>

  <!-- settings link -->
  <div class="nav-item-container settings">
    <lib-nav-item [navItem]="settingsNavItem" [expanded]="navigationExpanded$ | async"></lib-nav-item>
  </div>

  <!-- signout link (mobile layout only) -->
  <div class="nav-item-container signout">
    <lib-nav-item [navItem]="signOutNavItem" [expanded]="navigationExpanded$ | async" (click)="signOut()"></lib-nav-item>
  </div>

  <div class="mobile-menu-spacer"></div>

  <button type="button" class="nav-item u-hide-mobile" (click)="toggleExpansion()">
    <i class="fas {{arrowIcon}}"></i>
  </button>

</div>
