import { reduce } from 'lodash';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Layout, LayoutStateModel } from './layout.model';
import {
  ActivateFullScreen,
  SetApplicationContext,
  CollapseNavigation,
  DeactivateFullScreen,
  ExpandNavigation,
  SetScreenSize,
  ToggleNavigationExpanded
} from './layout.actions';
import { Injectable } from '@angular/core';
import { AuthApiService } from '@LIB_UTIL/auth/services/auth-api.service';

type LayoutBreakPoint = (width: number) => boolean;

interface LayoutBreakPoints {
  mobile: LayoutBreakPoint;
  lap: LayoutBreakPoint;
  maxLap: LayoutBreakPoint;
  desk: LayoutBreakPoint;
  maxDesk: LayoutBreakPoint;
  wall: LayoutBreakPoint;
  dam: LayoutBreakPoint;
}


const layoutBreakpoints: LayoutBreakPoints = {
  mobile: (width: number) => width <= 767,
  lap: (width: number) => width >= 768,
  maxLap: (width: number) => width <= 1023,
  desk: (width: number) => width >= 1024,
  maxDesk: (width: number) => width <= 1280,
  wall: (width: number) => width >= 1281,
  dam: (width: number) => width >= 1680,
};

@State<LayoutStateModel>({
  name: 'layout',
  defaults: {
    navigationExpanded: false,
    isFullScreen: false,
    applicationContext: '',
    layout: {
      screenWidth: 1024,
      mobile: false,
      lap: true,
      maxLap: false,
      desk: true,
      maxDesk: true,
      wall: false,
      dam: false,
    },
  },
})
@Injectable()
export class LayoutState {
  constructor(
    private authApiService: AuthApiService,
    private store: Store
  ) {
  }

  @Selector()
  public static applicationContext(state: LayoutStateModel): string {
    return state.applicationContext;
  }

  @Selector()
  public static layout(state: LayoutStateModel): Layout {
    return state.layout;
  }

  @Selector()
  public static navigationExpanded(state: LayoutStateModel): boolean {
    return state.navigationExpanded;
  }

  @Selector()
  public static isFullScreen(state: LayoutStateModel): boolean {
    return state.isFullScreen;
  }

  @Selector()
  public static isMobile(state: LayoutStateModel): boolean {
    return state.layout.mobile;
  }

  @Selector()
  public static isDesktop(state: LayoutStateModel): boolean {
    return state.layout.desk;
  }

  @Selector()
  public static screenWidth(state: LayoutStateModel): number {
    return state.layout.screenWidth;
  }

  @Action(SetApplicationContext)
  public setApplicationContext(
    { patchState }: StateContext<LayoutStateModel>,
    { applicationContext }: SetApplicationContext
  ): void {
    patchState({ applicationContext: applicationContext });
  }

  @Action(ActivateFullScreen)
  public activateFullScreen({ patchState }: StateContext<LayoutStateModel>): void {
    patchState({ isFullScreen: true });
  }

  @Action(DeactivateFullScreen)
  public deactivateFullScreen({ patchState }: StateContext<LayoutStateModel>): void {
    patchState({ isFullScreen: false });
  }

  @Action(ToggleNavigationExpanded)
  public toggleNavigationExpanded({ patchState, getState }: StateContext<LayoutStateModel>): void {
    const state: LayoutStateModel = getState();
    patchState({ navigationExpanded: !state.navigationExpanded });
  }

  @Action(CollapseNavigation)
  public collapseNavigation({ patchState }: StateContext<LayoutStateModel>): void {
    patchState({ navigationExpanded: false });
  }

  @Action(ExpandNavigation)
  public expandNavigation({ patchState }: StateContext<LayoutStateModel>): void {
    patchState({ navigationExpanded: true });
  }

  // screen size change action
  @Action(SetScreenSize)
  public setScreenSize({ patchState }: StateContext<LayoutStateModel>, { screenWidth }: SetScreenSize): void {
    patchState({
      layout: {
        screenWidth: screenWidth,
        ...reduce(layoutBreakpoints, (acc: Partial<Layout>, check: (w: number) => boolean, breakpoint: string) => ({
          ...acc,
          [breakpoint]: check(screenWidth),
        }), {}),
      },
    });
  }
}
