import { CopyDefinition } from '@LG_CORE/page-title/shared.model';
import { DefinitionType } from '@LIB_UTIL/model/definitiontype';

export class GetFolder {
  public static readonly type: string = '[Folders API] Get Folder';

  constructor(public folderId?: number) {}
}

export class AddFolder {
  public static readonly type: string = '[Folders API] Add Folder';

  constructor(public name: string, public parentId?: number) {}
}

export class AddFolderDefinition {
  public static readonly type: string = '[Folders API] Add Folder Definition';

  constructor(
    public folderId: number,
    public name: string,
    public type: DefinitionType,
    public openEditor: boolean = true
  ) {}
}

export class CopyDefinitionToFolder {
  public static readonly type: string = '[Folders API] Copy Definitions To Folder';

  constructor(public folderId: number, public definitionsAndFoldersToCopy: CopyDefinition) {}
}

export class DeleteFolder {
  public static readonly type: string = '[Folders API] Delete Folder';

  constructor(public folderId: number, public parentId?: number) {
  }
}

export class DeleteFolderDefinition {
  public static readonly type: string = '[Folders API] Delete Folder Definition';

  constructor(public folderId: number, public definitionId: number) {}
}

export class SetFolderEntityEditMode {
  public static readonly type: string = '[Folders API] Set Folder Entity Edit Mode';

  constructor(
    public folderId: number,
    public entityId: number,
    public collection: 'definitions' | 'folders',
    public editMode: boolean = true
  ) {}
}

export class RenameFolderEntity {
  public static readonly type: string = '[Folders API] Reneme Folder Entity';

  constructor(
    public folderId: number,
    public entityId: number,
    public collection: 'definitions' | 'folders',
    public name: string
  ) {}
}
