import { AfterViewInit, Component, ElementRef, Input, ViewChild, inject } from '@angular/core';

@Component({
  selector: 'lib-pin-icon',
  templateUrl: './pin-icon.component.html',
  styleUrls: ['./pin-icon.component.scss'],
})
export class PinIconComponent implements AfterViewInit {

  private elementRef: ElementRef = inject(ElementRef);

  @Input() public size: number = 24;
  @Input() public noMargin: boolean = false;

  @ViewChild('pinIcon') public menuIcon: ElementRef<HTMLElement>;

  public ngAfterViewInit(): void {
    const element: HTMLElement = this.elementRef?.nativeElement;

    if (!element) {
      return;
    }

    element.style.setProperty('--icon-size', `${this.size}px`);
  }
}
