import { addMilliseconds, differenceInMilliseconds, setSeconds, subDays, subMilliseconds, subMonths, subYears } from 'date-fns';
import { PeriodModel, PeriodType } from '../model/period.model';
import { formatDateToApiFormat, formatDateToFullDateFormat } from './date-formatting';

type TooltipFn = (period: PeriodModel) => string;

export function getPeriodDate(period?: PeriodType): Date {
  let date: Date;

  switch (period) {
    case PeriodType.Last24Hours:
      date = subDays(new Date(), 1);
      break;
    case PeriodType.None:
    case PeriodType.LastWeek:
      date = subDays(new Date(), 7);
      break;
    case PeriodType.Last4Weeks:
      date = subDays(new Date(), 28);
      break;
    case PeriodType.LastHalfYear:
      date = subMonths(new Date(), 6);
      break;
    case PeriodType.LastYear:
      date = subYears(new Date(), 1);
      break;
    default:
      date = new Date();
  }

  return setSeconds(date, 0);
}

export function getPeriodType(period?: PeriodType): PeriodType {
  return period === PeriodType.None ? PeriodType.LastWeek : period;
}

export function getPeriodByType(period: PeriodModel): PeriodModel {
  if (![PeriodType.Custom, PeriodType.None].includes(period.periodType)) {
    period.periodType = getPeriodType(period.periodType);
    period.startDate = formatDateToApiFormat(getPeriodDate(period.periodType));
    period.endDate = formatDateToApiFormat(getPeriodDate());
  }

  return period;
}

/**
 * Helper function to get a tooltip for the previous period, depending on the period type
 */
export const getTooltipPrevious: TooltipFn = (period: PeriodModel): string => {
  switch (period?.periodType) {
    case PeriodType.Last24Hours:
      return $localize`:@@previous24Hours:Previous 24 hours`;
    case PeriodType.LastWeek:
      return $localize`:@@previousWeek:Previous week`;
    case PeriodType.Last4Weeks:
      return $localize`:@@previous4Weeks:Previous 4 weeks`;
    case PeriodType.LastHalfYear:
      return $localize`:@@previousHalfYear:Previous half year`;
    case PeriodType.LastYear:
      return $localize`:@@previousYear:Previous year`;
    default:
      return $localize`:@@previousPeriod:Previous period`;
  }
};

/**
 * Helper function to get a tooltip for the next period, depending on the period type
 */
export const getTooltipNext: TooltipFn = (period: PeriodModel): string => {
  switch (period?.periodType) {
    case PeriodType.Last24Hours:
      return $localize`:@@next24Hours:Next 24 hours`;
    case PeriodType.LastWeek:
      return $localize`:@@nextWeek:Next week`;
    case PeriodType.Last4Weeks:
      return $localize`:@@next4Weeks:Next 4 weeks`;
    case PeriodType.LastHalfYear:
      return $localize`:@@nextHalfYear:Next half year`;
    case PeriodType.LastYear:
      return $localize`:@@nextYear:Next year`;
    default:
      return $localize`:@@nextPeriod:Next period`;
  }
};

/**
 * Helper function to calculate a previous period
 */
export const getPreviousPeriod = (period: PeriodModel): PeriodModel => {
  const diffInDays: number = differenceInMilliseconds(new Date(period.endDate), new Date(period.startDate));

  return {
    ...period,
    startDate: formatDateToApiFormat(subMilliseconds(new Date(period.startDate), diffInDays)),
    endDate: period.startDate,
  };
};

/**
 * Helper function to calculate a next period
 */
export const getNextPeriod = (period: PeriodModel): PeriodModel => {
  const diffInDays: number = differenceInMilliseconds(new Date(period.endDate), new Date(period.startDate));

  return {
    ...period,
    startDate: period.endDate,
    endDate: formatDateToApiFormat(addMilliseconds(new Date(period.endDate), diffInDays)),
  };
};

export const customPeriodToday: PeriodModel = {
  startDate: formatDateToFullDateFormat(new Date()),
  periodType: PeriodType.Custom,
};
