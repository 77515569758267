<!-- HREF links -->
<a *ngIf="navItem.href" class="nav-item {{navItem.className}}" [href]="navItem.href"  [class.expanded]="expanded" [class.rtl]="isRtl"
    target="_blank">
  <div class="nav-icon-and-title" [attr.sel-id]="navItem.seleniumId">
    <mat-icon [fontSet]="navItem.fontSet" [fontIcon]="navItem.fontIcon" ></mat-icon>
    <span class="title">{{navItem.translatedTitle}}</span>
  </div>
</a>

<!-- Router links -->
<a *ngIf="!navItem.href" class="nav-item {{navItem.className}}" [routerLinkActive]="navItem.routerLink ? 'active' : ''"
    (click)="navClick()" [class.expanded]="expanded" [class.rtl]="isRtl"
    [routerLink]="navItem.routerLink" >
  <div class="nav-icon-and-title" [attr.sel-id]="navItem.seleniumId">
    <mat-icon [fontSet]="navItem.fontSet" [fontIcon]="navItem.fontIcon" ></mat-icon>
    <span class="title">{{navItem.translatedTitle}}</span>
  </div>
</a>
