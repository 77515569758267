import { Injectable } from '@angular/core';

import { environment } from '@LIB_UTIL/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  public apiEndpoint: string = environment.apiEndpoint;
  public mediaApiEndpoint: string = environment.mediaApiEndpoint;
  public modelApiEndpoint: string = environment.modelApiEndpoint;
  private editorApiEndpoint: string = environment.editorApiEndpoint;
  private strategyManagerApiEndpoint: string = environment.strategyManagerApiEndpoint;

  public endpoints: Record<string, string> = {
    activate: `${this.apiEndpoint}/activate`,
    accounts: `${this.apiEndpoint}/account`,
    appointment: `${this.apiEndpoint}/appointments`,
    auth: `${this.apiEndpoint}/auth`,
    base: this.apiEndpoint,
    charts: `${this.apiEndpoint}/charts`,
    kpis: `${this.apiEndpoint}/kpis`,
    companies: `${this.apiEndpoint}/companies`,
    editor: `${this.editorApiEndpoint}`,
    cropgroups: `${this.apiEndpoint}/cropgroups`,
    strategyManager: `${this.strategyManagerApiEndpoint}/strategy-manager`,
    dashboards: `${this.apiEndpoint}/dashboards`,
    folders: `${this.apiEndpoint}/folders`,
    gauge: `${this.apiEndpoint}/gauge`,
    keepAlive: `${this.apiEndpoint}/keepalive`,
    language: `${this.apiEndpoint}/language`,
    log: `${this.apiEndpoint}/log`,
    media: `${this.mediaApiEndpoint}/media`,
    modules: `${this.apiEndpoint}/modules`,
    moduletemplates: `${this.apiEndpoint}/moduletemplates`,
    platformSettings: `${this.apiEndpoint}/platform/settings`,
    cultures: `${this.apiEndpoint}/cultures`,
    recover: `${this.apiEndpoint}/recover`,
    reset: `${this.apiEndpoint}/reset`,
    register: `${this.apiEndpoint}/register`,
    registerZoho: `${this.apiEndpoint}/register/zoho`,
    verify: `${this.apiEndpoint}/verify`,
    user: `${this.apiEndpoint}/user`,
    lca: `${this.modelApiEndpoint}/model/lca`, // old hfc/lca endpoint
    hfc: `${this.apiEndpoint}/hfc`,  // endpoint for the new HFC SPA
  };
}
