import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { PeriodModel, PeriodType } from '@LIB_UTIL/model/period.model';
import { LocaleID } from '@LIB_UTIL/util/locale';
import { HorizontalConnectionPositions, VerticalConnectionPositions } from '@LIB_UTIL/model/overlay.model';
import { formatDateToPeriodFormat } from '@LIB_UTIL/util/date-formatting';
import { addDays } from 'date-fns';
import { formatDateToTime } from '@LIB_UTIL/util/date-formatting/date-formatting';

@Component({
  selector: 'lib-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LgDateRangePickerComponent{
  @Input() public period: PeriodModel;
  @Input() public enablePin: boolean = false;
  @Input() public inline: boolean = false;
  @Input() public showPeriodSelector: boolean = true;
  @Input() public showTimeSelector: boolean = true;
  @Input() public showOutline: boolean = false;
  @Input() public singleDate: boolean = false;
  @Input() public dualMode: boolean = false; // both period and timestamp can be selected
  @Input() public valid: boolean = true;
  @Input() public minDate: Date | null = null;
  @Input() public maxDate: Date | null = null;
  @Input() public disabled: boolean = false;
  @Input() public defaultPeriod: PeriodModel = null;
  @Input() public nothingSelectedMessage: string = $localize`:@@datePicker.selectPeriod:Select a period`;
  @Output() public readonly pin: EventEmitter<PeriodModel> = new EventEmitter<PeriodModel>();
  @Output() public readonly cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly submit: EventEmitter<PeriodModel> = new EventEmitter<PeriodModel>();


  public isMenuOpen: boolean = false;
  public internalDefaultPeriod: PeriodModel = {
    startDate: addDays(new Date(), -7).toString(),
    endDate: new Date().toString(),
    periodType: PeriodType.LastWeek,
  };

  public defaultPeriodSingleDate: PeriodModel = {
    startDate: new Date().toString(),
    endDate: null,
    periodType: PeriodType.None,
  };

  public activeTab: number = 0;

  /**
   * Output the period in the datepicker trigger.
   */
  public get formattedPeriod(): string {
    if (!this.period) {
      return this.nothingSelectedMessage;
    }

    const startDate: string = this.period.startDate
      ? formatDateToPeriodFormat(new Date(this.period.startDate), this.locale)
      : '';
    const startTime: string = this.period.startDate
      ? formatDateToTime(new Date(this.period.startDate), this.locale)
      : '';
    const endDate: string = this.period.endDate
      ? formatDateToPeriodFormat(new Date(this.period.endDate), this.locale)
      : '';

    return this.activeTab === 1 || (!this.dualMode && this.singleDate)
      ? this.showTimeSelector ? `${startDate} ${startTime}`: `${startDate}`
      : `${startDate} - ${endDate}`;
  }

  constructor(
    @Inject(LOCALE_ID) private locale: LocaleID
  ) {}


  public emitPin(period: PeriodModel): void {
    this.pin.emit(period);
  }

  public emitSubmit(event: PeriodModel): void {
    this.isMenuOpen = false;

    if (this.activeTab === 1) {
      // strip the end date from the period, to notify the calling component that this is a single date/time
      // instead of a period.
      event = { ...event, endDate: null };
    }

    this.submit.emit(event);
  }

  public emitCancel(): void {
    this.isMenuOpen = false;
    this.cancel.emit();
  }

  public overlayPositions(): ConnectionPositionPair[] {
    return ([
      new ConnectionPositionPair(
        { originX: HorizontalConnectionPositions.start, originY: VerticalConnectionPositions.top },
        { overlayX: HorizontalConnectionPositions.center, overlayY: VerticalConnectionPositions.bottom },
        85, // 120
        -15
      ),
      new ConnectionPositionPair(
        { originX: HorizontalConnectionPositions.start, originY: VerticalConnectionPositions.top },
        { overlayX: HorizontalConnectionPositions.start, overlayY: VerticalConnectionPositions.bottom },
        -55,
        -15
      ),
      new ConnectionPositionPair(
        { originX: HorizontalConnectionPositions.start, originY: VerticalConnectionPositions.bottom },
        { overlayX: HorizontalConnectionPositions.start, overlayY: VerticalConnectionPositions.bottom },
        -55,
        255
      ),
      new ConnectionPositionPair(
        { originX: HorizontalConnectionPositions.start, originY: VerticalConnectionPositions.bottom },
        { overlayX: HorizontalConnectionPositions.center, overlayY: VerticalConnectionPositions.bottom },
        85,
        255
      ),
    ]);
  }

  public toggleMenu(): void {
    if (this.disabled) {
      return;
    }
    this.isMenuOpen = !this.isMenuOpen;
  }

  public changeTab(tab: number): void {
    this.activeTab = tab;
  }
}
