import { Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscription, throwError } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { environment } from '@LIB_UTIL/environments/environment';
import { serialUnsubscriber } from '@LIB_UTIL/util/rx';

import { GetUserInfoAction } from '../state/auth.actions';
import { AuthApiService } from './auth-api.service';
import { AuthResult, RecoverMode, ZohoUser } from '../model/auth.model';
import { v4 as uuid } from 'uuid';


const SID_SESSION_STORAGE_KEY: string = 'LG-sid';
const SESSION_ID_SESSION_STORAGE_KEY: string = 'LG-session-id';
const VERIFICATION_CODE_LENGTH: number = 20;
const KEEPALIVE_INTERVAL: number = 5 * 1000 * 60;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  /**
   * The sid returned by the backend.
   * (A kind of accessToken)
   * Falls back to value in sessionStorage.
   */
  private sid$: BehaviorSubject<string>
    = new BehaviorSubject<string>(sessionStorage.getItem(SID_SESSION_STORAGE_KEY));

  /**
   * Keep track of our subscriptions.
   */
  private subs: Record<string, Subscription> = {};


  /**
   * Returns the sid returned by the backend.
   * (A kind of accessToken)
   */
  public get sid(): string | null {
    return this.sid$.getValue();
  }

  /**
   * Get the user unique session id.
   * (unique for each browser tab)
   */
  public get sessionId(): string | null {
    return sessionStorage.getItem(SESSION_ID_SESSION_STORAGE_KEY);
  }

  constructor(
    private store: Store,
    private authApiService: AuthApiService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  public ngOnDestroy(): void {
    serialUnsubscriber(...Object.values(this.subs));
  }

  public auth(username: string, password: string): Observable<AuthResult> {
    return this.authApiService.auth(username, password, this.locale)
      .pipe(map(({
        sid,
        twoLetterISOLanguageName,
        brandName,
        isTermsConditionsAccepted,
      }: { sid: string; twoLetterISOLanguageName: string; brandName: string; isTermsConditionsAccepted: boolean }) => ({
        sid: sid,
        language: twoLetterISOLanguageName,
        brandName: brandName,
        isTermsConditionsAccepted: isTermsConditionsAccepted,
      })));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  public registerUser(userDetails: any): Observable<Object> {
    return this.authApiService.registerUser(userDetails);
  }

  public registerZohoUser(userDetails: ZohoUser): Observable<string> {
    return this.authApiService.registerZohoUser(userDetails);
  }

  public recover(email: string, mode: RecoverMode): Observable<Object> {
    return this.authApiService.recover(email, mode);
  }

  public activate(activationCode: string): Observable<Object> {
    return this.authApiService.activate(activationCode);
  }

  public verifyCode(code: string): Observable<Object> {
    return (!code || code === '' || code.length !== VERIFICATION_CODE_LENGTH)
      ? throwError(null)
      : this.authApiService.verifyCode(code);
  }

  public reset(password: string, verificationCode: string): Observable<Object> {
    return this.authApiService.reset(password, verificationCode);
  }

  public getTermsAndConditionsUrl(): Observable<Object> {
    return this.authApiService.getTermsAndConditionsUrl();
  }

  public setTermsAndConditions(isAccepted: boolean): Observable<Object> {
    return this.authApiService.setTermsAndConditionsAccepted(isAccepted);
  }

  public isUserManagerOfAModule(): Observable<Object> {
    return this.authApiService.isUserManagerModule();
  }

  /**
   * Store the sid and session id in the session storage
   * (which is cleared after closing the tab)
   *
   * Generate a unique session identifier so the
   * backend can distinguish user sessions.
   *
   * The session id is attached in the header to
   * each api request.
   */
  public setSid(sid: string): void {
    // Generate a unique session identifier.
    const sessionId: string = this.sessionId || uuid();

    // Store the value in the session storage to cache
    // the sid between page reloads.
    sessionStorage.setItem(SID_SESSION_STORAGE_KEY, sid);
    sessionStorage.setItem(SESSION_ID_SESSION_STORAGE_KEY, sessionId);

    this.sid$.next(sid);
  }

  public initAuth(): void {
    if (!environment.embeddedMode) {
      this.getUserInfo();
      this.startKeepAlive();
    }
  }

  /**
   * Check (on the backend) if the user is still signed in.
   * Unfortunately we cannot do this in the front end by clearing the  sid in the session storage,
   * because each browser tab has its own session Storage.
   * If the user is no longer signed in, he will automatically be redirected to the login page.
   */
  public checkAuth(): void {
    this.authApiService.checkAuth();
  }

  public isSessionExpired(): Promise<boolean> {
    return this.authApiService.isExpired();
  }

  private getUserInfo(): void {
    this.subs['sid'] = this.sid$
      .pipe(
        filter((sid: string) => !!sid),
        distinctUntilChanged((a: string, b: string) => a === b),
        switchMap(() => this.store.dispatch(new GetUserInfoAction()))
      ).subscribe();
  }

  public startKeepAlive(): void {
    this.subs['keepAlive'] = interval(KEEPALIVE_INTERVAL)
      .subscribe(() => this.authApiService.keepAlive());
  }

  public signOut(): void {
    this.authApiService.signOut().subscribe(() => window.location.assign(environment.baseUrl));
  }
}
