/* eslint-disable max-lines */
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { get, omit } from 'lodash';

import { CropGroupsApiService } from '@LG_CORE/crop-groups/crop-groups-api.service';
import { filterOnId, mapEditMode } from '@LG_SHARED/utils';
import {
  AddCropGroupDefinition,
  AddCropGroupFolder,
  CopyDefinitionToCropGroup,
  DeleteCropGroupDefinition,
  DeleteCropGroupFolder,
  FilterCropGroupDefinitionSearchAction,
  FilterCropGroupSearchAction,
  GetAvailableDefinitionTypesInCropGroup,
  GetCropGroup,
  GetCropGroupArchives,
  GetCropGroupFolder,
  RenameCropGroupEntity,
  SetCropGroupEntityEditMode
} from '@LG_ROOT/app/state/cropgroups/crop-groups.actions';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { CropGroupsStateModel } from '@LG_ROOT/app/state/cropgroups/crop-groups.state.model';
import {
  CropGroupDetails,
  CropGroupFolderDetails,
  FilterableCropGroup,
  PostCropGroupDefinitionResponse
} from '@LG_CORE/crop-groups/crop-groups.model';
import { DashboardTileTypes } from '@LG_CORE/dashboards/dashboards.model';
import { Observable } from 'rxjs';
import { Definition } from '@LG_ROOT/app/core/page-title/shared.model';
import {
  deleteDefinitionInRoot,
  deleteDefinitionInSubfolder,
  renameCropGroupEntityInRoot,
  renameCropGroupEntityInSubfolder
} from './crop-groups.util';
import { itemMatchesSearch } from '@LIB_UTIL/util/string';
import { LocaleID } from '@LIB_UTIL/util/locale';
import { AuthService } from '@LIB_UTIL/auth/services/auth.service';
import { getEditorUrl } from '@LG_ROOT/app/shared/utils/url';
import { DefinitionType } from '@LIB_UTIL/model/definitiontype';
import { CropGroup } from '@LIB_UTIL/model/cropgroup.model';

@State<CropGroupsStateModel>({
  name: 'cropgroups',
  defaults: {
    root: {
      cropGroups: [],
      filterableCropGroups: [],
      hasArchive: false,
    },
    archive: new Map(),
    archives: [],
    cropgroup: new Map(),
    folder: new Map(),
    availableDefinitions: [],
    searchString: '',
  },
})
@Injectable()
export class CropGroupsState {

  constructor(
    private cropGroupsApiService: CropGroupsApiService,
    private authService: AuthService,
    @Inject(LOCALE_ID) private locale: LocaleID
  ) {
  }

  @Selector()
  public static cropGroup(state: CropGroupsStateModel): Map<string, CropGroupDetails> {
    return state.cropgroup;
  }

  @Selector()
  public static folder(state: CropGroupsStateModel): Map<string, CropGroupFolderDetails> {
    return state.folder;
  }

  @Selector()
  public static cropGroups(state: CropGroupsStateModel): CropGroup[] {
    return state.root.cropGroups;
  }

  /*
   * ACTIONS
   */
  @Action(GetCropGroup)
  public getCropGroup(
    ctx: StateContext<CropGroupsStateModel>,
    { cropGroupId, slimMode, archive }: GetCropGroup
  ): Observable<Object> {
    return this.cropGroupsApiService.getCropGroup(cropGroupId, slimMode, archive)
      .pipe(tap((details: any) => {

        if (details.cropGroups && details.cropGroups.length > 0) {
          details.filterableCropGroups = details.cropGroups.map((cropGroup: CropGroup) => ({
            ...cropGroup,
            hidden: false,
            checked: false,
          }));
        }

        if (!cropGroupId && !archive) {
          ctx.patchState({ root: details });
        } else if (archive) {
          const archiveState: any = get(ctx.getState(), 'archive', {});
          ctx.patchState({
            searchString: '',
            archive: {
              ...archiveState,
              [archive]: details,
              // initialize the filtered definitions with all definitions
              filteredDefinitions: details.definitions,
            },
          });
        } else {
          const cropGroupsState: Map<string, CropGroupDetails> = get(ctx.getState(), 'cropgroup', {});
          ctx.patchState({
            searchString: '',
            cropgroup: {
              ...cropGroupsState,
              [cropGroupId]: {
                ...details,
                // initialize the filtered definitions with all definitions
                filteredDefinitions: details.definitions,
              },
            },
          });
        }
      }));
  }

  @Action(GetCropGroupArchives)
  public getCropGroupArchives({ patchState }: StateContext<CropGroupsStateModel>): Observable<Object> {
    return this.cropGroupsApiService.getCropGroupArchives()
      .pipe(tap((archives: string[]) => patchState({ searchString: '', archives: archives })));
  }

  @Action(GetCropGroupFolder)
  public getCropGroupFolder(
    ctx: StateContext<CropGroupsStateModel>,
    { cropGroupId, folderId }: GetCropGroupFolder
  ): Observable<Object> {
    return this.cropGroupsApiService.getCropGroupFolder(cropGroupId, folderId)
      .pipe(tap((details: any) => {

        const cropGroupFoldersState = get(ctx.getState(), 'folder', {});

        ctx.patchState({
          searchString: '',
          folder: {
            ...cropGroupFoldersState,
            // initialize the filtered definitions with all definitions
            filteredDefinitions: details.definitions,
            [`${ cropGroupId }_${ folderId }`]: {
              ...details,
              filteredDefinitions: details.definitions,
            },
          },
        });
      }));
  }

  @Action(AddCropGroupFolder)
  public addCropGroupFolder(
    ctx: StateContext<CropGroupsStateModel>,
    { cropGroupId, folderId, name }: AddCropGroupFolder) {
    return this.cropGroupsApiService.addCropGroupFolder(cropGroupId, folderId, name)
      .pipe(tap((result: any) => {

        if (!folderId) {
          const cropGroupsState = get(ctx.getState(), 'cropgroup', {});
          const cropGroupState = get(cropGroupsState, cropGroupId, {});

          ctx.patchState({
            searchString: '',
            cropgroup: {
              ...cropGroupsState,
              [cropGroupId]: {
                ...cropGroupState,
                folders: [...(Array.isArray(cropGroupState.folders) ? cropGroupState.folders : []), result],
              },
            },
          });
        } else {
          const foldersState = get(ctx.getState(), 'folder', {});
          const folderStoreId: string = `${ cropGroupId }_${ folderId }`;
          const folderState = get(foldersState, folderStoreId, {});

          ctx.patchState({
            folder: {
              ...foldersState,
              [folderStoreId]: {
                ...folderState,
                folders: [...(Array.isArray(folderState.folders) ? folderState.folders : []), result],
              },
            },
          });
        }
      }));
  }

  @Action(AddCropGroupDefinition)
  public addCropGroupDefinition(
    ctx: StateContext<CropGroupsStateModel>,
    { cropGroupId, folderId, name, type, openEditor }: AddCropGroupDefinition) {
    return this.cropGroupsApiService.addCropGroupDefinition(cropGroupId, folderId, name, type)
      .pipe(tap((result: PostCropGroupDefinitionResponse) => {
        if (folderId) {
          const foldersState = get(ctx.getState(), 'folder', {});
          const folderStoreId: string = `${ cropGroupId }_${ folderId }`;
          const folderState = get(foldersState, folderStoreId, {});

          ctx.patchState({
            folder: {
              ...foldersState,
              [folderStoreId]: {
                ...folderState,
                definitions: [...(Array.isArray(folderState.definitions) ? folderState.definitions : []), result],
              },
            },
          });
        } else {
          const cropGroupsState = get(ctx.getState(), 'cropgroup', {});
          const cropGroupState = get(cropGroupsState, cropGroupId, {});

          ctx.patchState({
            cropgroup: {
              ...cropGroupsState,
              [cropGroupId]: {
                ...cropGroupState,
                definitions: [...(Array.isArray(cropGroupState.definitions) ? cropGroupState.definitions : []), result],
              },
            },
          });
        }

        this.filterCropGroup(ctx, cropGroupId, folderId, '');

        if (openEditor) {
          if (type === DefinitionType.graph) {
            const url: string = getEditorUrl(type, result.id, this.locale, this.authService.sid, cropGroupId);
            window.open(url, '_blank');
          } else if (result.configurationUrl) {
            window.open(result.configurationUrl, '_blank');
          } else if (result.url) {
            window.open(result.url, '_blank');
          }
        }
      }));
  }

  @Action(CopyDefinitionToCropGroup)
  public copyDefinitionToCropGroup(
    ctx: StateContext<CropGroupsStateModel>,
    { cropGroupId, folderId, definitionsAndFoldersToCopy }: CopyDefinitionToCropGroup
  ): Observable<Object> {
    return this.cropGroupsApiService.copyDefinitionToCropGroup(cropGroupId, folderId, definitionsAndFoldersToCopy)
      .pipe(tap((result: any) => {
        if (folderId) {
          const foldersState = get(ctx.getState(), 'folder', {});
          const folderStoreId: string = `${ cropGroupId }_${ folderId }`;
          const folderState = get(foldersState, folderStoreId, {});

          ctx.patchState({
            folder: {
              ...foldersState,
              [folderStoreId]: {
                ...folderState,
                definitions: [...(Array.isArray(folderState.definitions) ? folderState.definitions : []), ...result.definitions],
                filteredDefinitions: [...(Array.isArray(folderState.filteredDefinitions) ? folderState.filteredDefinitions : []), ...result.definitions],
                folders: [...(Array.isArray(folderState.folders) ? folderState.folders : []), ...result.folders],
              },
            },
          });
        } else {

          const allCropGroupsState = get(ctx.getState(), 'cropgroup', {});
          const cropGroupState = get(allCropGroupsState, cropGroupId, {});

          ctx.patchState({
            cropgroup: {
              ...allCropGroupsState,
              [cropGroupId]: {
                ...cropGroupState,
                definitions: [...(Array.isArray(cropGroupState.definitions) ? cropGroupState.definitions : []), ...result.definitions],
                filteredDefinitions: [...(Array.isArray(cropGroupState.filteredDefinitions) ? cropGroupState.filteredDefinitions : []), ...result.definitions],
                folders: [...(Array.isArray(cropGroupState.folders) ? cropGroupState.folders : []), ...result.folders],
              },
            },
          });
        }
      }));
  }

  @Action(DeleteCropGroupFolder)
  public deleteCropGroupFolder(ctx: StateContext<CropGroupsStateModel>, {
    cropGroupId,
    parentId,
    folderId,
  }: DeleteCropGroupFolder) {
    return this.cropGroupsApiService.deleteCropGroupFolder(cropGroupId, folderId)
      .pipe(tap(() => {

        let foldersState = get(ctx.getState(), 'folder', {});
        const folderStoreId = `${ cropGroupId }_${ folderId }`;

        if (!parentId) {
          const cropGroupsState = get(ctx.getState(), 'cropgroup', {});
          const cropGroupState = get(cropGroupsState, cropGroupId, {});

          ctx.patchState({
            searchString: '',
            cropgroup: {
              ...cropGroupsState,
              [cropGroupId]: {
                ...cropGroupState,
                folders: (Array.isArray(cropGroupState.folders) ? cropGroupState.folders : []).filter(filterOnId(folderId)),
              },
            },
          });
        } else {
          const fid: string = `${ cropGroupId }_${ parentId }`;
          const folderState = get(foldersState, fid, {});
          foldersState = {
            ...folderState,
            [fid]: {
              ...folderState,
              searchString: '',
              folders: (Array.isArray(folderState.folders) ? folderState.folders : []).filter(filterOnId(folderId)),
            },
          };
        }

        ctx.patchState({
          folder: {
            ...omit(foldersState, folderStoreId),
          },
        });

      }));
  }

  @Action(DeleteCropGroupDefinition)
  public deleteCropGroupDefinition(
    ctx: StateContext<CropGroupsStateModel>,
    { cropGroupId, folderId, definitionId }: DeleteCropGroupDefinition
  ): Observable<Object> {
    // tell the backend to delete the crop group definition
    return this.cropGroupsApiService.deleteCropGroupDefinition(cropGroupId, folderId, definitionId)
      .pipe(tap(() => {
        // now update the local store as well
        this.deleteDefinition(ctx, cropGroupId, folderId, definitionId);
      }));

  }

  private deleteDefinition(
    ctx: StateContext<CropGroupsStateModel>,
    cropGroupId: number,
    folderId: number,
    id: number
  ): void {

    const searchStringState: string = ctx.getState().searchString;

    if (folderId) {
      deleteDefinitionInSubfolder(ctx, cropGroupId, folderId, id);
    } else {
      deleteDefinitionInRoot(ctx, cropGroupId, id);
    }

    // after that, apply the search again
    this.filterCropGroup(ctx, cropGroupId, folderId, searchStringState);
  }

  @Action(SetCropGroupEntityEditMode)
  public sem(
    ctx: StateContext<CropGroupsStateModel>,
    { cropGroupId, folderId, entityId, collection, editMode }: SetCropGroupEntityEditMode
  ): void {

    if (folderId) {
      const foldersState: Map<string, CropGroupFolderDetails> = get(ctx.getState(), 'folder', {});
      const folderStoreId: string = `${ cropGroupId }_${ folderId }`;
      const folderState: CropGroupFolderDetails = get(foldersState, folderStoreId, {});

      ctx.patchState({
        folder: {
          ...foldersState,
          [folderStoreId]: {
            ...folderState,
            [collection]: (Array.isArray(folderState[collection])
              ? folderState[collection]
              : [])
              .map(mapEditMode(entityId, editMode)),
          },
        },
      });
    } else {
      const cropGroupsState: Map<string, CropGroupDetails> = get(ctx.getState(), 'cropgroup', {});
      const cropGroupState: CropGroupDetails = get(cropGroupsState, cropGroupId, {});

      ctx.patchState({
        cropgroup: {
          ...cropGroupsState,
          [cropGroupId]: {
            ...cropGroupState,
            [collection]: (Array.isArray(cropGroupState[collection])
              ? cropGroupState[collection]
              : []).map(mapEditMode(entityId, editMode)),
          },
        },
      });
    }
  }

  @Action(RenameCropGroupEntity)
  public rename(
    ctx: StateContext<CropGroupsStateModel>,
    renameParams: RenameCropGroupEntity
  ): Observable<{ url?: string }> {

    let { cropGroupId, folderId, entityId, collection, name } = renameParams;

    // call the backend to update the name
    return (collection === 'definitions'
      ? this.cropGroupsApiService.renameDefinition(cropGroupId, folderId, entityId, name)
      : this.cropGroupsApiService.renameFolder(cropGroupId, entityId, name)
    ).pipe(tap(({ url }: { url?: string }) => {

      // Update the store with the renamed item.
      //
      // Entities are stored in the store on two locations:
      // 1. In the root (when you are on the root level of a crop group)
      // 2. In one of the subfolders.
      // Depending on the folderId, we have to either rename the item in the
      // root location (CropGroupsStateModel.cropgroup) or in the
      // subfolder location (CropGroupsStateModel.folder)
      if (folderId) {
        renameCropGroupEntityInSubfolder(ctx, renameParams, url);
      } else {
        renameCropGroupEntityInRoot(ctx, renameParams, url);
      }

      const searchStringState: string = get(ctx.getState(), 'searchString', '');
      this.filterCropGroup(ctx, cropGroupId, folderId, searchStringState);
    }));
  }

  @Action(GetAvailableDefinitionTypesInCropGroup)
  public getAvailableDefinitionTypesInCropGroup(
    { patchState }: StateContext<CropGroupsStateModel>,
    { cropGroupId }: GetAvailableDefinitionTypesInCropGroup
  ): Observable<DashboardTileTypes[]> {
    return this.cropGroupsApiService.getAvailableDefinitionTypesInCropGroup(cropGroupId)
      .pipe(tap((availableDefinitions: DashboardTileTypes[]) => {
        patchState({ availableDefinitions: availableDefinitions });
      }));
  }

  @Action(FilterCropGroupSearchAction)
  public filterCropGroupAction(
    ctx: StateContext<CropGroupsStateModel>,
    { searchString, archive }: FilterCropGroupSearchAction
  ): void {

    let rootsState: {
      cropGroups: CropGroup[];
      filterableCropGroups: FilterableCropGroup[];
      hasArchive: boolean;
    } = get(ctx.getState(), 'root', []);
    let cropGroupState: CropGroup[] = get(rootsState, 'cropGroups', []);

    if (archive) {
      const archiveState: Map<number, { cropGroups: CropGroup[]; filterableCropGroups: FilterableCropGroup[] }>
        = get(ctx.getState(), 'archive', []);

      rootsState = get(archiveState, archive, []);
      cropGroupState = get(rootsState, 'cropGroups', []);

      let filteredCropGroupState: FilterableCropGroup[] = cropGroupState.map((cropGroup: CropGroup) => ({
        ...cropGroup,
        hidden: !itemMatchesSearch(cropGroup.name, searchString),
        checked: false,
      }));

      ctx.patchState({
        searchString: searchString,
        archive: {
          ...archiveState,
          [archive]: {
            ...rootsState,
            filterableCropGroups: filteredCropGroupState,
          },
        },
      });
    } else {

      let filteredCropGroupState: FilterableCropGroup[] = cropGroupState.map((cropGroup: CropGroup) => ({
        ...cropGroup,
        hidden: !itemMatchesSearch(cropGroup.name, searchString),
        checked: false,
      }));

      //don't show archive folder in search mode
      let rootState: {
        cropGroups: CropGroup[];
        filterableCropGroups: FilterableCropGroup[];
        hasArchive: boolean;
      } = JSON.parse(JSON.stringify(rootsState));
      rootState.hasArchive = !searchString;

      ctx.patchState({
        searchString: searchString,
        root: {
          ...rootState,
          filterableCropGroups: filteredCropGroupState,
        },
      });
    }
  }

  @Action(FilterCropGroupDefinitionSearchAction)
  public filterCropGroupDefinitionAction(
    ctx: StateContext<CropGroupsStateModel>,
    { searchString, cropGroupId, folderId }: FilterCropGroupDefinitionSearchAction
  ): void {
    this.filterCropGroup(ctx, cropGroupId, folderId, searchString);
  }

  private filterCropGroup(ctx: StateContext<CropGroupsStateModel>,
    cropGroupId: number, folderId: number, searchString: string): void {

    if (folderId) {
      const { foldersState, folderStoreId, folderState, definitionsState, allChildFoldersArray, newDefinitionArray }
        = this.getFoldersStatesAndDefinitions(ctx, cropGroupId, folderId, searchString);

      ctx.patchState({
        searchString: searchString,
        folder: {
          ...foldersState,
          [folderStoreId]: {
            ...folderState,
            filteredDefinitions: newDefinitionArray,
            definitions: definitionsState,
            folders: allChildFoldersArray,
          },
        },
      });

    } else {
      const { cropGroupsState, cropGroupState, definitionsState, allChildFoldersArray, newDefinitionArray }: {
        cropGroupsState: Map<string, CropGroupDetails>;
        allChildFoldersArray: CropGroupFolderDetails[];
        definitionsState: Definition[];
        newDefinitionArray: Definition[];
        cropGroupState: CropGroupDetails;
      }
        = this.getCropCroupStatesAndDefinitions(ctx, cropGroupId, searchString);
      ctx.patchState({
        searchString: searchString,
        cropgroup: {
          ...cropGroupsState,
          [cropGroupId]: {
            ...cropGroupState,
            filteredDefinitions: newDefinitionArray,
            definitions: definitionsState,
            folders: allChildFoldersArray,
          },
        },
      });
    }
  }

  private getCropCroupStatesAndDefinitions(ctx: StateContext<CropGroupsStateModel>,
    cropGroupId: number, searchString: string): {
      cropGroupsState: Map<string, CropGroupDetails>;
      allChildFoldersArray: CropGroupFolderDetails[];
      definitionsState: Definition[];
      newDefinitionArray: Definition[];
      cropGroupState: CropGroupDetails;
    } {
    const cropGroupsState: Map<string, CropGroupDetails> = get(ctx.getState(), 'cropgroup', {});
    const cropGroupState: CropGroupDetails = get(cropGroupsState, cropGroupId, {});
    const definitionsState: Definition[] = get(cropGroupState, 'definitions', {});
    const childFoldersState: CropGroupFolderDetails[] = get(cropGroupState, 'folders', {});

    let allDefinitionArray: Definition [] = [];
    let allChildFoldersArray: CropGroupFolderDetails [] = [];

    if (searchString) {
      allDefinitionArray = this.findAllFolderDefinitions(cropGroupState.folders).map((definition: Definition) =>
        ({
          ...definition,
          hidden: !itemMatchesSearch(definition.name, searchString),
        })
      );
      allChildFoldersArray = childFoldersState.map((childFolder: CropGroupFolderDetails) =>
        ({
          ...childFolder,
          hidden: true,
        })
      );
    } else {
      allChildFoldersArray = childFoldersState.map((childFolder: CropGroupFolderDetails) =>
        ({
          ...childFolder,
          hidden: false,
        })
      );
    }

    let newDefinitionArray: Definition [] = definitionsState.map((definition: Definition) =>
      ({
        ...definition,
        hidden: !itemMatchesSearch(definition.name, searchString),
      })
    );

    newDefinitionArray = newDefinitionArray.concat(allDefinitionArray);

    return {
      cropGroupsState: cropGroupsState,
      cropGroupState: cropGroupState,
      definitionsState: definitionsState,
      allChildFoldersArray: allChildFoldersArray,
      newDefinitionArray: newDefinitionArray,
    };
  }

  private getFoldersStatesAndDefinitions(ctx: StateContext<CropGroupsStateModel>,
    cropGroupId: number, folderId: number, searchString: string): {
      folderState: CropGroupFolderDetails;
      allChildFoldersArray: CropGroupFolderDetails[];
      foldersState: Map<string, CropGroupFolderDetails>;
      definitionsState: Definition[];
      newDefinitionArray: Definition[];
      folderStoreId: string;
    } {
    const foldersState: Map<string, CropGroupFolderDetails> = get(ctx.getState(), 'folder', {});
    const folderStoreId: string = `${ cropGroupId }_${ folderId }`;
    const folderState: CropGroupFolderDetails = get(foldersState, folderStoreId, {});
    const definitionsState: Definition[] = get(folderState, 'definitions', {});
    const childFoldersState: CropGroupFolderDetails[] = get(folderState, 'folders', {});

    let allDefinitionArray: Definition [] = [];
    let allChildFoldersArray: CropGroupFolderDetails [] = [];

    if (searchString) {
      // retrieve all child folder definitions
      allDefinitionArray = this.findAllFolderDefinitions(folderState.folders).map((definition: Definition) =>
        ({
          ...definition,
          hidden: !itemMatchesSearch(definition.name, searchString),
        })
      );
    }

    // hide all folders in the UI, depending on the search string
    allChildFoldersArray = childFoldersState.map((childFolder: CropGroupFolderDetails) =>
      ({
        ...childFolder,
        hidden: searchString ? true : false,
      })
    );

    let newDefinitionArray: Definition [];
    // retrieve current cropgroup/folder definitions
    newDefinitionArray = definitionsState.map((definition: Definition) =>
      ({
        ...definition,
        hidden: !itemMatchesSearch(definition.name, searchString),
      })
    );

    newDefinitionArray = newDefinitionArray.concat(allDefinitionArray);

    return {
      foldersState: foldersState,
      folderStoreId: folderStoreId,
      folderState: folderState,
      definitionsState: definitionsState,
      allChildFoldersArray: allChildFoldersArray,
      newDefinitionArray: newDefinitionArray,
    };
  }

  public findAllFolderDefinitions(cropGroupFolderDetails: CropGroupFolderDetails[]): Definition[] {
    const result: Definition[] = [];
    if (!cropGroupFolderDetails && cropGroupFolderDetails.length === 0) {
      return result;
    }

    cropGroupFolderDetails.forEach(function (data: CropGroupFolderDetails): void {
      if (data.definitions) {
        result.push(...data.definitions);
      }

      if (data.folders && data.folders.length > 0) {
        result.push(...this.findAllFolderDefinitions(data.folders));
      }
    }.bind(this));

    return result;
  }
}
