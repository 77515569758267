import { Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { LayoutState } from '@LIB_UTIL/layout/state/layout.state';
import { environment } from '@LIB_UTIL/environments/environment';
import { AuthState } from '@LIB_UTIL/auth/state/auth.state';
import { UserInfo } from '@LIB_UTIL/auth/model/auth.model';
import { NavItem } from '@LIB_UTIL/layout/models/nav-item.model';
import { Layout } from '@LIB_UTIL/layout/state/layout.model';
import { CollapseNavigation, ToggleNavigationExpanded } from '@LIB_UTIL/layout/state/layout.actions';
import { LocaleID, isRtl } from '@LIB_UTIL/util/locale';
import { AuthService } from '@LIB_UTIL/auth/services/auth.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lib-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {

  private store: Store = inject(Store);
  private authService: AuthService = inject(AuthService);
  @Inject(LOCALE_ID) private locale: LocaleID = inject(LOCALE_ID) as LocaleID;

  @Input() public navItems: NavItem[] = [];
  @Input() public logo: string;
  @Input() public applyBranding: boolean;
  @Input() public overrideBrand: string = '';

  public layout$: Observable<Layout> = this.store.select(LayoutState.layout);
  public userInfo$: Observable<UserInfo> = this.store.select(AuthState.userInfo);
  public navigationExpanded$: Observable<boolean> = this.store.select(LayoutState.navigationExpanded);
  public isFullScreen$: Observable<boolean> = this.store.select(LayoutState.isFullScreen);

  private mobileNavExpanded: boolean = false;

  private mobileNavExpandedSub: Subscription;

  public isRtl: boolean = isRtl(this.locale);

  public arrowIcon: string = this.isRtl ? 'fa-angle-left' : 'fa-angle-right';

  public showSupportLink$: Observable<boolean>;

  public supportNavItem: NavItem = {
    href: environment.supportPageUrl,
    routerLink: [],
    fontSet: 'fas',
    fontIcon: 'fa-circle-question',
    translatedTitle: $localize`:@@support:Support`,
    seleniumId: 'support',
  };

  public settingsNavItem: NavItem = {
    routerLink: ['/settings'],
    fontSet: 'fas',
    fontIcon: 'fa-gear',
    translatedTitle: $localize`:@@settings:Settings`,
  };

  public signOutNavItem: NavItem = {
    routerLink: null,
    fontSet: 'fas',
    fontIcon: 'fa-right-from-bracket',
    translatedTitle: $localize`:@@common.signOut:Sign out`,
  };

  public ngOnInit(): void {
    this.mobileNavExpandedSub = combineLatest([
      this.layout$,
      this.navigationExpanded$,
    ]).subscribe(([layout, expanded]: [Layout, boolean]) => {
      this.mobileNavExpanded = layout.mobile && expanded;
    });

    // show support link for all brands except LetsGrow,
    // and also if Zoho support button is not shown (depending on deployment)
    this.showSupportLink$ = this.userInfo$.pipe(
      map((userInfo: UserInfo) => userInfo?.brandName?.toLowerCase() !== 'lg'
        && !environment.showSupportButtonForBrands
          .toLowerCase()
          .split(',')
          .includes(userInfo?.brandName?.toLowerCase()))
    );
  }

  public ngOnDestroy(): void {
    this.mobileNavExpandedSub.unsubscribe();
  }

  public toggleExpansion(): void {
    this.store.dispatch(new ToggleNavigationExpanded());
  }

  public navClick(): void {
    if (this.mobileNavExpanded) {
      this.store.dispatch(new CollapseNavigation());
    }
  }

  public signOut(): void {
    this.authService.signOut();
  }

}
