import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appFocusOnLoad]',
})
export class FocusOnLoadDirective implements AfterViewInit {

  @Input() public appFocusOnLoad: number = 0;

  constructor(private el: ElementRef) {}

  public ngAfterViewInit(): void {
    const timeout: number = Number(this.appFocusOnLoad) || 1;

    setTimeout(() => {
      this.el.nativeElement.focus();
    }, timeout);
  }

}
