export class SetPhoto {
  public static readonly type: string = '[Profile] Set new profile photo';

  constructor(public photoBase64: string) {}
}

export class DeletePhoto {
  public static readonly type: string = '[Profile] Delete profile photo';
}

export class GetAnnouncements {
  public static readonly type: string = '[Announcements] Get announcement for current user';
}

export class UpdateAnnouncementRead {
  public static readonly type: string = '[Announcements] Update announcement isRead state';

  constructor(public announcementId: number) {}
}

export class DeleteAnnouncement {
  public static readonly type: string = '[Announcements] Delete announcement';

  constructor(public announcementId: number) {}
}
