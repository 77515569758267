import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { animate, AnimationBuilder, style } from '@angular/animations';

@Directive({
  selector: '[appFadeInDelay]',
})
export class FadeInDelayDirective implements AfterViewInit {

  @Input() public appFadeInDelay: number = 0;

  constructor(
    private builder: AnimationBuilder,
    private el: ElementRef
  ) {}

  public ngAfterViewInit(): void {
    const delay: number = Number(this.appFadeInDelay) || 300;

    this.builder.build([
      style({ opacity: 0 }),
      animate(delay, style({ opacity: 0 })),
      animate(300, style({ opacity: 1 })),
    ])
      .create(this.el.nativeElement)
      .play();
  }

}
