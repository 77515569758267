import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { NavItem } from '@LIB_UTIL/layout/models/nav-item.model';
import { CollapseNavigation } from '@LIB_UTIL/layout/state/layout.actions';
import { LayoutState } from '@LIB_UTIL/layout/state/layout.state';
import { isRtl } from '@LIB_UTIL/util/locale';
import { Store } from '@ngxs/store';

@Component({
  selector: 'lib-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
})
export class NavItemComponent {
  @Input() public navItem: NavItem;
  @Input() public expanded: boolean = false;

  public isRtl: boolean = isRtl(this.locale);

  constructor(
    private store: Store,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  public navClick(): void {
    const isMobile: boolean = this.store.selectSnapshot(LayoutState.isMobile);

    if (isMobile) {
      this.store.dispatch(new CollapseNavigation());
    }
  }
}

