import { DashboardCropModel } from '@LG_CORE/dashboards/dashboards.model';

export const getDefaultCropId = (crops: DashboardCropModel[]): DashboardCropModel => {
  let defaultCrop: DashboardCropModel = crops.find(crop => crop.isSelected);

  if (!defaultCrop) {
    defaultCrop = crops[0];
  }

  return defaultCrop;
};
