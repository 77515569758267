import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '@LIB_UTIL/services/config/config.service';

@Injectable({
  providedIn: 'root',
})
export class StrategyManagerApiService {

  constructor(private http: HttpClient, private cfg: ConfigService) {
  }

  /**
   * Find out if the current user has access to the strategy manager
   */
  public getStrategyManagerAccess(): Observable<boolean> {
    const url: string = `${this.cfg.endpoints.strategyManager}/get-access`;

    return this.http.get<boolean>(url);
  }
}
