/* eslint-disable  @typescript-eslint/no-explicit-any */
import { filter } from 'rxjs/operators';
import { AddEntityComponent } from '../modals/add-entity/add-entity.component';
import { IframeModalComponent } from '../modals/iframe-modal/iframe-modal.component';
import {
  ChooseAddSelectEntityModalComponent
} from '../modals/choose-add-select-entity-modal/choose-add-select-entity-modal.component';
import {
  SelectExistingEntityModalComponent
} from '../modals/select-existing-entity-modal/select-existing-entity-modal.component';
import {
  SelectExistingEntityFolderModalComponent
} from '../modals/select-existing-entity-folder-modal/select-existing-entity-folder-modal.component';
import { Observable } from 'rxjs';
import { InformationComponent } from '@LG_SHARED/modals/information/information.component';
import { ModalService } from '@LIB_UTIL/services/modal.service';
import { SelectExistingKpiComponent } from '@LG_SHARED/modals/select-existing-kpi/select-existing-kpi.component';

export const launchAddEntityModal = (ms: ModalService, blacklist?: string[]): Observable<any> => ms.open({
  component: AddEntityComponent,
  config: {
    panelClass: ['basic-modal'],
    width: '630px',
    data: {
      blacklist: blacklist,
    },
  },
}).pipe(filter((result: any) => !!result));

export const launchChooseSelectAddEntityModal = (ms: ModalService): Observable<any> => ms.open({
  component: ChooseAddSelectEntityModalComponent,
  config: {
    panelClass: ['basic-modal'],
    width: 'auto',
    data: {
      copyExistingElementDescription: $localize`:@@common.copyExistingElement:Copy existing element`,
      createNewElementDescription: $localize`:@@common.createNewElement:Create new element`,
    },
  },
}).pipe(filter((result: any) => !!result));

export const launchChooseSelectAddKpiModal = (ms: ModalService): Observable<any> => ms.open({
  component: ChooseAddSelectEntityModalComponent,
  config: {
    panelClass: ['basic-modal'],
    width: 'auto',
    data: {
      copyExistingElementDescription: $localize`:@@kpi.copyExistingKpi:Copy existing KPI`,
      createNewElementDescription: $localize`:@@kpi.createNewKpi:Create new KPI`,
    },
  },
}).pipe(filter((result: any) => !!result));

export const launchSelectExistingEntityModal = (ms: ModalService): Observable<any> => ms.open({
  component: SelectExistingEntityModalComponent,
  config: {
    panelClass: ['basic-modal'],
    width: '630px',
  },
}).pipe(filter((result: any) => !!result));

export const launchSelectExistingEntityFolderModal = (ms: ModalService): Observable<any> => ms.open({
  component: SelectExistingEntityFolderModalComponent,
  config: {
    panelClass: ['basic-modal'],
    width: '630px',
  },
}).pipe(filter((result: any) => !!result));

export const launchSelectExistingKPIModal = (ms: ModalService): Observable<any> => ms.open({
  component: SelectExistingKpiComponent,
  config: {
    panelClass: ['basic-modal'],
    width: '630px',
  },
}).pipe(filter((result: any) => !!result));

export const launchIframeModal = (ms: ModalService, url: string): Observable<any> => ms.open({
  component: IframeModalComponent,
  config: {
    panelClass: ['iframe-modal'],
    width: '630px',
    data: {
      url: url,
    },
  },
}).pipe(filter((result: any) => !!result));

export const launchReadOnlyModal = (ms: ModalService): Observable<any> => ms.open({
  component: InformationComponent,
  config: {
    panelClass: ['basic-modal'],
    backdropClass: ['modal-backdrop'],
    data: {
      title: $localize`:@@Demo:Demo`,
      text: $localize`:@@NotAllowedInDemo:This functionality is not allowed in the demo`,
      buttonText: $localize`:@@common.close:Close`,
    },
    maxWidth: '500px',
    width: '80vw',
  },
}).pipe(filter((result: any) => !!result));
