import { SelectableCropUser } from '@LG_CORE/crop-groups/crop-groups.model';
import { SelectableModuleUser } from '@LG_ROOT/app/core/modules/modules.model';
import { UserManagementUser } from '@LG_ROOT/app/core/shared/user.model';
import { itemMatchesSearch } from '@LIB_UTIL/util/string';

/**
 * Check if a user matches a search string.
 */
export const userContainsSearchText = (
  user: SelectableCropUser|UserManagementUser|SelectableModuleUser,
  searchString: string,
  includingEmail: boolean = false
): boolean => searchString.length === 0
    || itemMatchesSearch(user.displayName, searchString)
    || (includingEmail && itemMatchesSearch(user.emailAddress, searchString));
