import { ar, de, enUS, es, fr, nl, pl, ru, zhCN, ja } from 'date-fns/locale';

/**
 * Supported app locales.
 */
export type LocaleID = 'ar' | 'de' | 'es' | 'fr' | 'nl' | 'pl' | 'ru' | 'en' | 'zh' | 'ja';

/**
 * Translate the local code to the date-fns/locale.
 */
export function getLocale(locale: LocaleID): Locale {
  switch (locale) {
    case 'ar':
      return ar;
    case 'de':
      return de;
    case 'es':
      return es;
    case 'fr':
      return fr;
    case 'nl':
      return nl;
    case 'pl':
      return pl;
    case 'ru':
      return ru;
    case 'zh':
      return zhCN;
    case 'ja':
      return ja;
    default:
      return enUS;
  }
}

/**
 * Helper function to check if the current locale uses right to left reading / layout
 */
export function isRtl(locale: string): boolean {
  return locale === 'ar';
}
