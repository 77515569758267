import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface PopupMessage {
  title: string;
  message: string;
  closeText: string;
}
@Component({
  selector: 'lib-message-modal',
  templateUrl: './message-modal.component.html',
})
export class MessageModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public popupMessage: PopupMessage) { }
}
