import { GridsterItem } from 'angular-gridster2';
import { Definition } from '@LG_CORE/page-title/shared.model';
import { PeriodModel } from '@LIB_UTIL/model/period.model';
import { DefinitionType } from '@LIB_UTIL/model/definitiontype';

export type DashboardTileData =
  DashboardTileTypeChartModel |
  DashboardTileTypeIframeModel |
  DashboardTileTypeMeteoModel |
  DashboardTileTypeShortcutModel |
  DashboardTileTypeGaugeModel;

export interface DashboardSelectionModel {
  translations: DashboardTranslation;
  dashboardGroups: DashboardSelectionGroupModel[];
}

export interface DashboardTranslation {
  searchPlaceholder: string;
  noSearchResults: string;
}

export interface DashboardSelectionGroupModel {
  id: number;
  type: DashboardTypes;
  name: string;
  sortOrder: number;
  dashboards: DashboardSelectionDashboardModel[];
}

export interface DashboardSelectionDashboardModel {
  name: string;
  prefix: string; // the prefix will contain the name of the crop group
  // if this is a cropgroup dashboard.
  id: number;
  isFavorite: boolean;
  isDefault: boolean;
  moduleDefinitionId: number;
  subMenuId: number;
  cropId: number;
  cropGroupId: number;
}

export interface DashboardModel {
  id: number;
  name: string;
  prefix: string;
  period: PeriodModel;
  tiles: DashboardTileModel[];
  isEditable: boolean;
  isExportable: boolean;
  isFavorite: boolean;
  crops: DashboardCropModel[];
  cropGroupId: number;
  type: DashboardTypes;
  moduleTemplateId: number;
  moduleDefinitionId: number;
  folderId: number;
  subMenuDefinitionId: number;
}

export interface DashboardCropModel {
  id: number;
  name: string;
  isPinned: boolean;
  isHidden: boolean;
  isSelected: boolean;
  isCompared: boolean;
}

export interface DashboardTileCropSelection {
  pinnedCropId: number;
  selectedCropId: number;
}

export interface DashboardTileModel extends DashboardTileDataModel, GridsterItem {
  id: number;
  name: string;
  type: DashboardTileTypes;
}

export enum DashboardTypes {
  Favorite,
  Personal,
  CropGroup,
  Module,
  ArchivedCropGroup,
  ExpiredModule,
}

export enum DashboardTileTypes {
  none = 'None',
  iframe = 'Iframe',
  chart = 'Chart',
  gauge = 'Gauge',
  media = 'Media',
  meteo = 'Meteo',
  plan = 'Plan',
  report = 'Report',
  text = 'Text',
  glass = 'Glass',
  shortcut = 'Shortcut',
  dashboard = 'Dashboard'
}

export interface DashboardTileTypeIframeModel {
  url: string;
  iframeUrl: string;
}

export interface DashboardTileTypeGaugeModel {
  id: number;
  dashboardId: number;
  definitionId: number;
  moduleDefinitionId: number;
  moduleTemplateId: number;
  cropGroupId: number;
  pinnedCropId: number;
  subMenuDefinitionId: number;
  timeStamp: Date;
  showCropSelector: boolean;
  selectedCropId: number | null; // does not come from backend!
  canEdit: boolean;
}

export interface DashboardTileTypeMeteoModel {
  colIds: number[];
}

export interface DashboardTileTypeShortcutModel {
  type: DashboardTileTypes;
  url: string;
  name: string;
  selectedCropName: string;
  imageUrl: string;
  crops?: DashboardCropModel[];
  pinnedCropId: number;
  hasRights: boolean;
}

export interface DashboardTileTypeMeteoValuesModel {
  itemCode: string;
  name: string;
  value: string;
}

export interface DashboardTileTypeChartModel {
  id: string;
  chartId: number;
  cropId?: number;
  cropGroupId: number;
  moduleDefinitionId: number;
  moduleTemplateId: number;
  subMenuDefinitionId: number;
}

export interface DashboardCreateModel {
  id?: number;
  name: string;
  type: DashboardTypes;
  cropGroupId: number;
  folderId: number | string;
  prefix?: string;
}

export interface DashboardExportModel {
  cropId?: number;
  cropCompareId?: number;
  mediaList?: {
    tileId: number;
    mediaId: number;
  }[];
  moduleDefinitionId?: number;
}

export interface DashboardReturnValue<T> {
  values: T;
}

export interface DashboardAddTilesModel {
  moduleTemplateId: number;
  moduleDefinitionId: number;
  cropGroupId: number;
  definitions: Definition[];
  type: DashboardTileTypes;
  definitionType: DefinitionType;
  folderId: number;
}

export interface DashboardTileDataModel {
  data: DashboardTileData;
}

export interface DashboardOrderProperties {
  dashboardId: number;
  moduleDefinitionId: number;
  cropGroupId: number;
}
