import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-choose-add-select-entity-modal',
  templateUrl: './choose-add-select-entity-modal.component.html',
  styleUrls: ['./choose-add-select-entity-modal.component.scss'],
})
export class ChooseAddSelectEntityModalComponent {
  public copyExistingElementDescription: string;
  public createNewElementDescription: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      copyExistingElementDescription: string;
      createNewElementDescription: string;
    },
    private dialogRef: MatDialogRef<ChooseAddSelectEntityModalComponent>
  ) {
    this.copyExistingElementDescription = this.data?.copyExistingElementDescription;
    this.createNewElementDescription = this.data?.createNewElementDescription;
  }

  public showAdd(newOrExisting: string): void {
    this.dialogRef.close({ show: newOrExisting });
  }
}
