<div class="modal-header" mat-dialog-title>
  <h2 i18n>What would you like to do?</h2>
</div>

<div class="modal-content" mat-dialog-content>
  <li class="tile u-flex" mat-dialog-close (click)="showAdd('existing')" cdkFocusInitial>
    <mat-icon fontSet="fas" fontIcon="fa-clone"></mat-icon>
    <div>{{copyExistingElementDescription}}</div>
  </li>
  <li class="tile u-flex" mat-dialog-close (click)="showAdd('new')" sel-id="create-new-element">
    <mat-icon fontSet="fas" fontIcon="fa-plus"></mat-icon>
    <div>{{createNewElementDescription}}</div>
  </li>
</div>

<div class="modal-footer" mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close i18n="@@common.close" class="close">Close</button>
</div>
