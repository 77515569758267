import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

export const chartVisibilityKey: string = 'letsgrow.chartVisibility';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  // The old name of the cookie which will not be used anymore, only for
  // converting once
  private cookieVisibilityKey: string = 'let-grow.chartVisibility';

  /**
   * This key is used to store the versions that must be hidden in the graphs at the strategy compare version page
   */
  private readonly localStorageCropStrategyVersionKey: string = 'letsgrow.cropStrategy_hiddenVersionIds';

  constructor(private cookieService: CookieService) { }

  /**
   * This is used to detect changes in hiding or showing graphs for the strategy compare versions page
   */
  public hiddenCropStrategyVersions$: BehaviorSubject<string> = new BehaviorSubject(this.hiddenCropStrategyVersions);

  public set hiddenCropStrategyVersions(value: string) {
    this.hiddenCropStrategyVersions$.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem(this.localStorageCropStrategyVersionKey, value);
  }

  public get hiddenCropStrategyVersions(): string {
    return localStorage.getItem(this.localStorageCropStrategyVersionKey);
  }

  /**
   * We temporarily stored the invisible lines of the charts in cookies.
   * However, this causes the cookie to become too large, so now we will
   * store them in the local storage.
   */
  public convertCookiesToLocalStorage(): void {

    const cookies: string[] = this.getCookiesByPartialName(this.cookieVisibilityKey);

    cookies.forEach((cookie: string) => {
      const keyVal: string[] = cookie.split('=');
      if (keyVal.length >= 2) {
        // convert to a new key
        const newKey: string = keyVal[0].replace(this.cookieVisibilityKey, chartVisibilityKey);
        // save in local storage with the new key, and without encoding
        localStorage.setItem(newKey, unescape(keyVal[1]));
        // delete the original cookie
        this.cookieService.delete(keyVal[0], '/');
      }
    });
  }

  /**
   * Find cookies by a partial name
   */
  private getCookiesByPartialName(name: string): string[] {

    let cookies: string[] = [];

    if (document.cookie && document.cookie !== '') {
      const split: string[] = document.cookie.split(';');

      split.forEach((c: string) => {
        const keyVal: string[] = c.split('=');
        if (keyVal.length >= 2 && keyVal[0].includes(name)) {
          cookies.push(c.trim()); // also remove any leading spaces
        }
      });
    }

    return cookies;
  }
}
