export interface AnnouncementsModel {
  html: string;
  isRead: boolean;
  id: number;
  date: string;
  type: AnnouncementTypes;
}

export enum AnnouncementTypes {
  none = 0,
  announcement = 1,
  placeHolder2 = 2,
  task = 3,
  placeHolder4 = 4,
}

export interface PhotoModel {
  photoBase64: string;
}

export interface ColleagueModel {
  id: number;
  name: string;
  email: string;
  userName: string;
}

