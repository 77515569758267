/**
 * The role of a user, related to both crop groups and modules.
 *
 *
 * ROLE MATRIX:
 *
 *
 *                                    CROP GROUP         MODULE
 * -----------------------------------------------------------------------
 * | Admin                       |        X        |        X            |
 * | Owner                       |                 |                     |
 * | Editor                      |        X        |        X            |
 * | Writer                      |                 |        X            |
 * | Viewer                      |        X        |        X  (FE only) |
 * | Editor and Data Exporter    |        X        |                     |
 * | Data exporter               |        X        |                     |
 * | Manager                     |                 |        X            |
 * -----------------------------------------------------------------------
 *
 */
export enum Role {

  /**
   * ADMIN
   * The administrator of a CROP GROUP or MODULE
   */
  admin = 'Admin',

  /**
   * OWNER
   * The owner of a MODULE
   */
  owner = 'Owner',

  /**
   * EDITOR
   * The editor of a CROP GROUP. he can modify definitions and dashboards.
   * NOTE: The editor role is also used for MODULES, in order to identify the manager of the module.
   * The manager of a module can share and change manual rights for other users of this module (except for the owner).
   */
  editor = 'Editor',


  /**
   * WRITER
   * The member of a MODULE that may enter data manually via the mobile app or LetsGrow.com
   */
  writer = 'Writer',

  /**
   * VIEWER
   * Member of a CROP GROUP or MODULE with read only rights
   * NOTE: The Viewer role is not actually used for modules in the database. The Api looks in another table
   * to see if this is a user of the module. But if so, it sends the role 'Viewer' to the frontend.
   */
  viewer = 'Viewer',


  /**
   * EDITOR AND DATA EXPORTER
   * member of a CROP GROUP with editor and data exporter rights
   */
  editorAndDataExporter = 'EditorAndDataExporter',

  /**
   * DATA EXPORTER
   * member of a CROP GROUP with data exporter rights
   */
  dataExporter = 'DataExporter',

  /**
   * MANAGER
   * member of MODULE with all rights
   */
  manager = 'Manager'
}
