<div class="modal-header" mat-dialog-title>
  <h2 class="u-bold">{{popupMessage.title}}</h2>
</div>
<div class="modal-content" mat-dialog-content>
  <div>{{popupMessage.message}}</div>
</div>
<div class="modal-footer" mat-dialog-actions align="end">
  <button mat-flat-button color="accent" [mat-dialog-close]="true">
    {{popupMessage.closeText}}
  </button>
</div>


