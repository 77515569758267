import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ChooseOptionModalData {
  title: string;
  message: string;
  message2: string;
  options: ChooseOption[];
  selectedOption: ChooseOption | null;
  hideCancelButton?: boolean;
}

export interface ChooseOption {
  name: string;
  value: number;
  disabled?: boolean;
}

@Component({
  selector: 'lib-choose-option-modal',
  templateUrl: './choose-option-modal.component.html',
  styleUrls: ['./choose-option-modal.component.scss'],
})
export class ChooseOptionModalComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChooseOptionModalData,
    public dialogRef: MatDialogRef<ChooseOptionModalComponent>
  ) { }

  public selectOption(option: ChooseOption): void {
    this.dialogRef.close({
      ...this.data,
      selectedOption: option,
    });
  }
}
