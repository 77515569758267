export class SetApplicationContext {
  public static readonly type: string = '[App Component] Application context';

  constructor(public applicationContext: string) {
  }
}

export class ActivateFullScreen {
  public static readonly type: string = '[App Navigation Component] Activate full screen mode';
}

export class DeactivateFullScreen {
  public static readonly type: string = '[App Navigation Component] Deactivate full screen mode';
}

export class ToggleNavigationExpanded {
  public static readonly type: string = '[App Navigation Component] Toggle Navigation Expanded';
}

export class CollapseNavigation {
  public static readonly type: string = '[App Navigation Component] Collapse Navigation';
}

export class ExpandNavigation {
  public static readonly type: string = '[App Navigation Component] Expand Navigation';
}

export class SetScreenSize {
  public static readonly type: string = '[App Component] Set Screen Size';

  constructor(public screenWidth: number) {
  }
}
