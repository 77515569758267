import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) {}

  /**
   * Display a uniform success message, for instance
   * after a successful http call.
   */
  public success(message: string): void {
    this.snackBar.open(message, 'Dismiss', {
      duration: 3000,
      panelClass: 'snackbar--success',
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
    });
  }

  /**
   * Display a uniform error message, for instance
   * after a failed http call.
   */
  public error(message: string, keepOpen: boolean = false): void {
    this.snackBar.open(message, 'Dismiss', {
      duration: keepOpen ? 0 : 3000,
      panelClass: 'snackbar--error',
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
    });
  }

  /**
   * Wrapper around error function to show a snackbar that stays
   * open until dismissed by user
   */
  public dismissableError(message: string): void {
    this.error(message, true);
  }

  /**
   * Try to get a value from an observable. If it fails, show an error message
   */
  public async showErrorOnFailure<T,U>(
    obs: Observable<T>,
    message: string,
    showBackendError: boolean = false
  ): Promise<void> {
    let errorMessage: string = message;
    try {
      await lastValueFrom(obs);
    } catch (e) {
      if (showBackendError) {
        errorMessage = `${message}\n${e.toString().replace('Error:', '')}`;
      }
      this.dismissableError(errorMessage);
    }
  }

  public async showBackendErrorOnFailure<T,U>(
    obs: Observable<T>,
    message: string
  ): Promise<void> {
    await this.showErrorOnFailure(obs, message, true);
  }

  public async showSuccessOrError<T, U>(
    obs: Observable<T>,
    successMessage: string,
    errorMessage: string
  ): Promise<boolean> {
    try {
      await lastValueFrom(obs);
      this.success(successMessage);

      return true;
    } catch (e) {
      const message: string = `${errorMessage}\n${e.toString().replace('Error:', '')}`;
      this.dismissableError(message);

      return false;
    }
  }
}
