import { AfterViewInit, Component, ElementRef, Input, inject } from '@angular/core';

@Component({
  selector: 'lib-menu-icon',
  templateUrl: './menu-icon.component.html',
  styleUrls: ['./menu-icon.component.scss'],
})
export class MenuIconComponent implements AfterViewInit {

  private elementRef: ElementRef = inject(ElementRef);

  @Input() public size: number = 40;
  @Input() public colorInverted: boolean = false;

  public ngAfterViewInit(): void {
    const element: HTMLElement = this.elementRef?.nativeElement;

    if (!element) {
      return;
    }

    element.style.setProperty('--icon-size', `${this.size}px`);
  }
}
