import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmText {
  title: string;
  message: string;
  cancelText: string;
  confirmText: string;
}
@Component({
  selector: 'lib-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public confirmText: ConfirmText) { }
}
