/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MessageModalComponent, PopupMessage } from '@LIB_UTIL/components/modals/message/message-modal.component';
import { ChooseOptionModalComponent, ChooseOptionModalData } from '@LIB_UTIL/components/modals/choose-option/choose-option-modal.component';
import { ConfirmText, ConfirmationModalComponent } from '@LIB_UTIL/components/modals/confirmation/confirmation-modal.component';


export interface ConfirmModalConfig {
  component?: any;
  config?: any;
}

export interface ModalConfig {
  component: any;
  config?: any;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {

  constructor(public dialog: MatDialog) {
  }

  public confirmModal(
    modalConfig?: ConfirmModalConfig,
    component: any = ConfirmationModalComponent
  ): Observable<any> {
    modalConfig = {
      component: component,
      ...modalConfig,
    };

    return this.dialog
      .open(modalConfig.component, {
        width: '360px',
        backdropClass: 'modal-backdrop',
        panelClass: ['modal-panel', 'confirmation-panel'],
        ...modalConfig.config,
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(result => !!result)
      );
  }

  /**
   * Show a dynamic confirmation dialog (in which all the texts are
   * inserted dynamically).
   */
  public dynamicConfirmModal(
    title: string,
    message: string,
    cancelText: string,
    confirmText: string,
    width: number = 360
  ): Observable<any> {

    const text: ConfirmText = {
      title: title,
      message: message,
      cancelText: cancelText,
      confirmText: confirmText,
    };

    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.data = text;

    return this.confirmModal({
      component: ConfirmationModalComponent,
      config: {
        width: `${width}px`,
        data: text,
      },
    });
  }

  /**
   * Show a multi-option dialog (in which all the texts and options are
   * inserted dynamically).
   */
  public chooseOptionModal(
    data: ChooseOptionModalData,
    width: number
  ): Observable<any> {

    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.data = data;

    return this.confirmModal({
      component: ChooseOptionModalComponent,
      config: {
        width: `${width}px`,
        data: data,
      },
    });
  }

  public open(modalConfig: ModalConfig): Observable<any> {
    return this.dialog
      .open(modalConfig.component, {
        width: '375px',
        backdropClass: 'modal-backdrop',
        panelClass: 'modal-panel',
        enterAnimationDuration: 0,
        ...modalConfig.config,
      })
      .afterClosed()
      .pipe(
        take(1)
      );
  }

  public showPopupMessage(
    popupMessage: PopupMessage
  ): Observable<any> {

    return this.dialog
      .open(MessageModalComponent, {
        width: '500px',
        backdropClass: 'modal-backdrop',
        panelClass: ['modal-panel', 'confirmation-panel'],
        enterAnimationDuration: 0,
        data: popupMessage,
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(result => !!result)
      );
  }
}
