import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { get } from 'lodash';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FontIcon } from '@LIB_UTIL/model/fontIcon.model';
import { DefinitionType } from '@LIB_UTIL/model/definitiontype';
import { getDefinitionTypeIcon } from '@LIB_UTIL/util/icons';

@Component({
  selector: 'app-add-entity',
  templateUrl: './add-entity.component.html',
  styleUrls: ['./add-entity.component.scss'],
})
export class AddEntityComponent implements OnInit, OnDestroy {

  private keyDownSub: Subscription;
  public selectedType: string;
  public defaultType: string;
  public titleDescription: string = $localize`:@@entity.addTitleDescription: What would you like to add?`;
  public subTitleDescription: string;
  public name: string = '';
  public blacklist: string[] = [];
  public items: string[] = ['folder', 'graph', 'gauge', 'report', 'plan', 'dashboard', 'export'];
  public showSubTitle: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { blacklist: string[]; defaultType?: string },
    private dialogRef: MatDialogRef<AddEntityComponent>
  ) {
  }

  public ngOnInit(): void {
    this.blacklist = get(this.data, 'blacklist', []);
    this.defaultType = this.data?.defaultType;
    this.selectedType = this.defaultType ? this.defaultType : null;

    this.items = this.items.filter((item: string) => !this.blacklist.includes(item));

    if (this.defaultType) {
      if (this.defaultType === 'kpi') {
        this.titleDescription = $localize`:@@entity.enterKpiNameTitleDescription: Enter a name for your KPI`;
        this.showSubTitle = true;
        this.subTitleDescription = $localize`:@@entity.name:Name`;
      }
    } else {
      this.showSubTitle = true;
      this.subTitleDescription = $localize`:@@entity.name:Name`;
    }

    this.keyDownSub = this.dialogRef.keydownEvents()
      .pipe(filter(({ key }: KeyboardEvent) => key === 'Enter'))
      .subscribe(() => this.close());
  }

  public ngOnDestroy(): void {
    this.keyDownSub.unsubscribe();
  }

  public close(): void {
    if (this.name && this.selectedType) {
      this.dialogRef.close({ type: this.selectedType, name: this.name });
    }
  }

  public getIcon(item: string): FontIcon {
    if (item === 'folder') {
      return { fontSet: 'fas', icon: 'fa-folder' };
    }

    const definitionType: DefinitionType = DefinitionType[item];

    return getDefinitionTypeIcon(definitionType);
  }

}
