import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-user-initials',
  template: `
    <div class="container" [class]="size">
      {{ initials }}
    </div>
  `,
  styleUrls: ['./user-initials.component.scss'],
})
export class UserInitialsComponent {
  @Input() public initials: string;
  @Input() public size: 'small' | 'normal' = 'normal';
}
