import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ModuleInstance,
  ModuleDetails,
  ModuleDetailsType,
  ModuleTemplateDetails,
  ModuleTemplatesRoot,
  ModuleUser,
  SharedModule,
  InstanceGroup
} from './modules.model';
import { ConfigService } from '@LIB_UTIL/services/config/config.service';
import { Observable } from 'rxjs';
import { DashboardTileTypes } from '@LG_CORE/dashboards/dashboards.model';
import { SharedItemUser } from '../shared/user.model';
import { ShareRequest } from '../shared/share.model';
import { Role } from '@LIB_UTIL/model/role.model';

/**
 * Response can be eiter of three types, depending on the type of call
 * 'root': returns ModuleTemplatesRoot that contains all templates
 * 'moduletemplate': Returns ModuleTemplateDetails
 * 'module': ModuleDetails
 */
export type ModuleDetailsApiResponse = ModuleTemplatesRoot & ModuleTemplateDetails & ModuleDetails;

@Injectable({
  providedIn: 'root',
})
export class ModulesApiService {

  constructor(private http: HttpClient, private cfg: ConfigService) {
  }

  public getModuleDetails(entityId: number, type: ModuleDetailsType, getArchived: boolean):
  Observable<ModuleDetailsApiResponse> {

    const archivedString: string = getArchived ? `?getArchived=${getArchived}` : '';

    let url: string;
    if (type === 'module') {
      url = `${this.cfg.endpoints.modules}/${entityId}${archivedString}`;
    } else if (entityId && type === 'moduletemplate') {
      url = `${this.cfg.endpoints.moduletemplates}/${entityId}${archivedString}`;
    } else {
      url = `${this.cfg.endpoints.moduletemplates}${archivedString}`;
    }

    return this.http.get<ModuleDetailsApiResponse>(url);
  }

  /**
   * Retrieve all module templates and modules in one backend call
   */
  public getTemplatesAndModules(): Observable<ModuleTemplateDetails[]> {
    return this.http.get<ModuleTemplateDetails[]>(`${this.cfg.endpoints.moduletemplates}/withmodules`);
  }

  public getAvailableDefinitionTypesInModule(moduleTemplateId: number): Observable<DashboardTileTypes[]> {
    const url: string = `${this.cfg.endpoints.moduletemplates}/${moduleTemplateId}/availabledefinitiontypes`;

    return this.http.get<DashboardTileTypes[]>(url);
  }

  public getInstances(moduleId: number): Observable<ModuleInstance[]> {
    return this.http.get<ModuleInstance[]>(`${this.cfg.endpoints.modules}/${moduleId}/instances`);
  }

  public createInstance(moduleId: number, instance: ModuleInstance): Observable<ModuleInstance> {
    return this.http.post<ModuleInstance>(`${this.cfg.endpoints.modules}/${moduleId}/instances`, instance);
  }

  public updateInstances(moduleId: number, instances: ModuleInstance[]): Observable<ModuleInstance[]> {
    return this.http.put<ModuleInstance[]>(`${this.cfg.endpoints.modules}/${moduleId}/instances`, instances);
  }

  public createInstanceGroup(moduleId: number, instance: ModuleInstance): Observable<ModuleInstance> {
    return this.http.post<ModuleInstance>(`${this.cfg.endpoints.modules}/${moduleId}/instances/groups`, instance);
  }

  public getInstanceGroups(moduleId: number): Observable<InstanceGroup[]> {
    return this.http.get<InstanceGroup[]>(`${this.cfg.endpoints.modules}/${moduleId}/instances/groups`);
  }

  public getInstancesForInstanceGroup(moduleId: number, instanceGroupId: number): Observable<InstanceGroup> {
    const url: string = `${this.cfg.endpoints.modules}/${moduleId}/instances/groups/${instanceGroupId}`;

    return this.http.get<InstanceGroup>(url);
  }

  public renameInstance(moduleId: number, instanceId: number, body: string): Observable<void> {
    const url: string = `${this.cfg.endpoints.modules}/${moduleId}/instances/${instanceId}/rename`;

    return this.http.patch<void>(url, { name: body });
  }

  public deleteInstance(moduleId: number, instanceId: number): Observable<void> {
    return this.http.delete<void>(`${this.cfg.endpoints.modules}/${moduleId}/instances/${instanceId}`);
  }

  public addInstancesToInstanceGroup(moduleId: number, instanceGroupId: number, body: number[]): Observable<void> {
    const url: string = `${this.cfg.endpoints.modules}/${moduleId}/instances/${instanceGroupId}/addInstances`;

    return this.http.patch<void>(url, body);
  }

  public removeInstancesFromInstanceGroup(moduleId: number, instanceGroupId: number, body: number[]): Observable<void> {
    const url: string = `${this.cfg.endpoints.modules}/${moduleId}/instances/${instanceGroupId}/removeInstances`;

    return this.http.patch<void>(url, body);
  }

  /**
   * Request a list of users that I share at least one module with
   */
  public getSharedModuleUsers(): Observable<SharedItemUser[]> {
    return this.http.get<SharedItemUser[]>(`${this.cfg.endpoints.modules}/users`);
  }

  /**
   * Get the modules that a specified user shares with me
   */
  public getSharedModules(userId: number): Observable<SharedModule[]> {
    return this.http.get<SharedModule[]>(`${this.cfg.endpoints.modules}/shared/${userId}`);
  }

  /**
   * Get the users of a specified module
   */
  public getModuleUsers(moduleId: number): Observable<ModuleUser[]> {
    return this.http.get<ModuleUser[]>(`${this.cfg.endpoints.modules}/${moduleId}/users`);
  }

  /**
   * Remove a user from a module
   */
  public removeModuleUsers(moduleId: number, userIds: number[]): Observable<Object> {
    const endpoint: string = this.cfg.endpoints.modules;

    return this.http.delete(`${endpoint}/${moduleId}/users`, {
      params: {
        ids: userIds,
      },
    });
  }

  /**
   * Remove a specified user from multiple modules
   */
  public removeUserFromModules(userId: number, moduleIds: number[]): Observable<Object> {
    const endpoint: string = this.cfg.endpoints.modules;

    return this.http.delete(`${endpoint}/users/${userId}`, {
      params: {
        moduleIds: moduleIds,
      },
    });
  }

  /**
   * Activate module using an activation code
   */
  public activateModule(activationCode: string): Observable<void> {
    return this.http.post<void>(`${this.cfg.endpoints.modules}/activate/${activationCode}`, null);
  }

  /**
   * Change the role of multiple users for one module
   */
  public changeUserRoles(moduleId: number, userIds: number[], newRole: Role): Observable<void> {
    const endpoint: string = this.cfg.endpoints.modules;

    return this.http.post<void>(`${endpoint}/${moduleId}/role/${newRole.toString()}`, {}, {
      params: {
        userIds: userIds,
      },
    });
  }

  /**
   * Share modules with other users
   */
  public shareModules(shareRequest: ShareRequest): Observable<void> {
    return this.http.post<void>(`${this.cfg.endpoints.modules}/invite`, shareRequest);
  }

  public triggerInstanceGroupCalculation(moduleId: number, instanceGroupId: number, body: string): Observable<void> {
    const url: string = `${this.cfg.endpoints.modules}/${moduleId}/instances/groups/${instanceGroupId}/calculate`;

    return this.http.patch<void>(url, { calculationDate: body });
  }
}
