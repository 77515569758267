import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { ConfigService } from '@LIB_UTIL/services/config/config.service';
import { environment } from '@LIB_UTIL/environments/environment';
import { RecoverMode, UserInfo, ViewMode, ZohoUser } from '../model/auth.model';
import { clearSid } from '../util/auth.util';


interface Recovery {
  email?: string;
  username?: string;
  mode: RecoverMode;
}

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {

  constructor(
    private http: HttpClient,
    private cfg: ConfigService
  ) {}

  public auth(username: string, password: string, twoLetterISOLanguageName: string): Observable<Object> {
    return this.http.post(this.cfg.endpoints.auth, {
      username: username,
      password: password,
      twoLetterISOLanguageName: twoLetterISOLanguageName,
    });
  }

  public signOut(): Observable<Object> {
    clearSid();

    return this.http.delete(this.cfg.endpoints.auth);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  public registerUser(userDetails: any): Observable<Object> {
    return this.http.post(this.cfg.endpoints.register, userDetails);
  }

  public registerZohoUser(userDetails: ZohoUser): Observable<string> {
    return this.http.post<string>(this.cfg.endpoints.registerZoho, userDetails);
  }

  public recover(field: string, mode: RecoverMode): Observable<Object> {
    const payload: Recovery = mode === 'username'
      ? { email: field, mode: mode }
      : { username: field, mode: mode };

    return this.http.post(this.cfg.endpoints.recover, payload);
  }

  public activate(activationCode: string): Observable<Object> {
    return this.http.post(`${this.cfg.endpoints.activate}?code=${activationCode}`, {});
  }

  public verifyCode(code: string): Observable<Object> {
    return this.http.get(`${this.cfg.endpoints.verify}?code=${code}`);
  }

  public reset(password: string, code: string): Observable<Object> {
    return this.http.post(this.cfg.endpoints.reset, { password: password, code: code });
  }

  public getUserInfo(): Observable<UserInfo> {
    return this.http.get<UserInfo>(`${this.cfg.endpoints.user}`, { observe: 'response' })
      .pipe(
        switchMap((response: HttpResponse<UserInfo>) => {
          if (response.status === 204) {
            clearSid();
            window.open(environment.loginPageUrl, '_self');

            return;
          }

          return of(response.body);
        }),
        catchError(() => {
          clearSid();
          window.open(environment.loginPageUrl, '_self');

          return of(undefined);
        })
      );
  }

  /**
   * Check (on the backend) if the user is still signed in.
   * If this is not the case, the user will automatically be redirected
   * to the login page.
   */
  public checkAuth(): void {
    this.getUserInfo().subscribe();
  }

  public async isExpired(): Promise<boolean> {

    const userObs: Observable<boolean> = this.http.get<UserInfo>(
      `${this.cfg.endpoints.user}`,
      { observe: 'response' }
    ).pipe(
      switchMap((response: HttpResponse<UserInfo>) => {
        if (response.status === 204) {
          clearSid();

          return of(true);
        }

        return of(false);
      }),
      catchError(() => {
        clearSid();

        return of(true);
      })
    );

    const expired: boolean = await firstValueFrom(userObs);

    return expired;
  }

  public getTermsAndConditions(): Observable<Object> {
    return this.http.get(`${this.cfg.endpoints.user}/termsconditions`, { observe: 'response' })
      .pipe(
        map((response) => response.body)
      );
  }

  public getTermsAndConditionsUrl(): Observable<Object> {
    return this.http.get(`${this.cfg.endpoints.user}/termsconditionsurl`, { observe: 'response' })
      .pipe(
        map((response) => response.body)
      );
  }

  public getServiceLevelAgreementUrl(isModuleManager: boolean): Observable<string> {
    if (isModuleManager){
      return this.http.get<string>(`${this.cfg.endpoints.user}/sla`, { observe: 'response' })
        .pipe(
          map((response) => response.body)
        );
    }
  }

  public setTermsAndConditionsAccepted(isAccepted: boolean): Observable<Object> {
    return this.http.put(`${this.cfg.endpoints.user}/termsconditions`, { isAccepted: isAccepted });
  }

  public keepAlive(): void {
    this.http.get(`${this.cfg.endpoints.keepAlive}`).pipe(take(1)).subscribe();
  }

  public setViewMode(viewMode: ViewMode): Observable<Object> {
    return this.http.put(`${this.cfg.endpoints.user}/view-mode`, { viewMode: viewMode });
  }

  public isUserManagerModule(): Observable<boolean> {
    return this.http.get<boolean>(`${this.cfg.endpoints.modules}/isManager`);
  }


}
