import {
  ChartAnalysisModel,
  ChartAnnotationBodyModel,
  ChartCompareSettings,
  ChartModel
} from '@LIB_UTIL/chart/model/charts-api.model';
import { ChartXAxisExtremes, ChartYAxisExtremes } from '@LIB_UTIL/chart/model/chart.model';
import { PeriodModel, PeriodType } from '@LIB_UTIL/model/period.model';
import { ChartLegendLineModel } from '../model/chart-legend.model';

export class SetDashboardChartsAction {
  public static readonly type: string = '[Dashboard State] Store chart data in the charts state.';

  constructor(
    public charts: ChartModel[],
    public compare: Record<string, ChartCompareSettings>,
    public activeCropId: number) {
  }
}

export class AddDashboardChartAction {
  public static readonly type: string = '[Dashboard State] Store the newly added chart in the charts state.';

  constructor(
    public chart: ChartModel,
    public activeCropId: number,
    public activeCompareCropId: number,
    public dashboardPeriod: PeriodModel
  ) {
  }
}

export class GetChartDataAction {
  public static readonly type: string = '[Chart Component] Fetch the chart data.';

  constructor(public chartId: number, public id: string, public periodType: PeriodType) {
  }
}

export class GetChartAction {
  public static readonly type: string = '[Chart Component] Fetch the chart configuration.';

  constructor(public chartId: number, public id: string) {
  }
}

export class DeleteChartAction {
  public static readonly type: string = '[Charts Component] Remove the chart in backend and from store.';

  constructor(public chartId: number, public id: string, public cropGroupId: number) {
  }
}

export class SetChartCropModulesAction {
  public static readonly type: string
    = '[Charts Component] Set the cropGroupId, moduleDefinitionId and moduleTemplateId for the chart.';

  constructor(
    public id: string,
    public cropId: number,
    public comparedCropId: number,
    public cropGroupId: number,
    public moduleDefinitionId: number,
    public moduleTemplateId: number,
    public startDate: string,
    public endDate: string,
    public showWeekNumbers: boolean,
    public calculateWeekValues: boolean,
    public lineNoteDate: string,
    public colIds: number[],
    public collectorType: string,
    public itemCode: string,
    public title: string,
    public calculationType: string,
    public hiddenCrops: number[]
  ) {
  }
}

export class SetCompareChartAction {
  public static readonly type: string = '[Chart Component] Set the cropId or compareCropId.';

  constructor(public id: string, public cropId: number, public compareCropId: number, public hiddenCrops: number[]) {
  }
}

export class SetChartAnalysisOptionAction {
  public static readonly type: string = '[Chart Component] Set the chart analytic options.';

  constructor(public id: string, public options: ChartAnalysisModel) {
  }
}

export class SetChartPeriodAction {
  public static readonly type: string = '[Chart Component] Set the chart period.';

  constructor(public id: string, public period: PeriodModel, public resetYAxis: boolean = false) {
  }
}

export class CreateAnnotationAction {
  public static readonly type: string
    = '[Chart Component] Create a new annotation (comment) on a data point in the chart.';

  constructor(public chartId: number, public id: string, public body: ChartAnnotationBodyModel) {
  }
}

export class UpdateAnnotationAction {
  public static readonly type: string = '[Chart Component] Update an existing annotation in the chart.';

  constructor(
    public chartId: number,
    public id: string,
    public annotationId: number,
    public body: ChartAnnotationBodyModel
  ) {
  }
}

export class DeleteAnnotationAction {
  public static readonly type: string = '[Chart Component] Delete an annotation in the chart.';

  constructor(public chartId: number, public id: string, public annotationId: number) {
  }
}

/**
 * ------------------------------------------------
 * Chart frontend setting actions (frontend only).
 * ------------------------------------------------
 */

export class SetChartXYAxisRangeAction {
  public static readonly type: string = '[Chart Component] Set the min and max values for the X-axis and Y-axis.';

  constructor(
    public id: string,
    public yAxisExtremes: ChartYAxisExtremes[],
    public xAxisExtremes: ChartXAxisExtremes
  ) {
  }
}

export class ResetChartYRangeAction {
  public static readonly type: string = '[Chart Component] Reset the min and max values for the Y-as.';

  constructor(public id: string) {
  }
}

export class ResetChartXYRangeAction {
  public static readonly type: string = '[Chart Component] Reset the min and max values for the X-axis and Y-axis.';

  constructor(public id: string) {
  }
}

export class ToggleSerieVisibilityAction {
  public static readonly type: string = '[Chart Component] Toggle the visibility of a serie (line) within the chart.';

  constructor(public id: string, public serie: ChartLegendLineModel) {
  }
}

export class ToggleAllChartSeriesVisibilityAction {
  public static readonly type: string
    = '[Chart Component] Toggle the visibility of all series (lines) within the chart.';

  constructor(public id: string, public seriesVisibility: boolean) {
  }
}

export class ToggleMarkerForSerieAction {
  public static readonly type: string
    = '[Chart Component] Toggle the visibility of the point markers for a serie in the chart.';

  constructor(public id: string, public serie: ChartLegendLineModel) {
  }
}

export class ToggleLoadingAction {
  public static readonly type: string = '[Chart Component] Toggle loading state of a chart.';

  constructor(public id: string, public isLoading: boolean) {
  }
}

export class GetChartAnnotationsAction {
  public static readonly type: string = '[Chart Notes Component] Add the note to the chart.';

  constructor(public chartId: number, public id: string) {
  }
}

export class SetPreviewChartAction {
  public static readonly type: string = '[EditorState] Set chart preview';

  constructor(public chart: ChartModel) {
  }
}
