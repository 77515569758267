import { ModuleInstance, ModuleDetailsType } from '@LG_CORE/modules/modules.model';

export class GetModuleEntityDetails {
  public static readonly type: string = '[Modules API] Get Module';

  constructor(public entityId?: number, public type: ModuleDetailsType = 'root', public getArchived: boolean = false) {
  }
}

export class GetInstancesAction {
  public static readonly type: string = '[Modules API] Get Instances';

  constructor(public moduleId: number) {
  }
}

export class CreateInstanceAction {
  public static readonly type: string = '[Modules API] Create Instances';

  constructor(public moduleId: number, public instance: ModuleInstance) {
  }
}

export class UpdateInstancesAction {
  public static readonly type: string = '[Modules API] Update Instances';

  constructor(public moduleId: number, public instances: ModuleInstance[]) {
  }
}

export class GetInstanceGroups {
  public static readonly type: string = '[Modules API] Get Instance Groups';

  constructor(public moduleId: number) {
  }
}

export class CreateInstanceGroup {
  public static readonly type: string = '[Modules API] Create Instance Group';

  constructor(public moduleId: number, public moduleInstance: ModuleInstance) {
  }
}

export class RenameInstanceGroup {
  public static readonly type: string = '[Modules API] Rename Instance Group';

  constructor(public moduleId: number, public instanceGroupId: number, public name: string) {
  }
}

export class DeleteInstanceGroup {
  public static readonly type: string = '[Modules API] Delete Instance Group';

  constructor(public moduleId: number, public instanceGroupId: number) {
  }
}

export class GetAvailableDefinitionTypesInModule {
  public static readonly type: string = '[Modules API] Retrieve available definition types from module template';

  constructor(public moduleTemplateId: number) {
  }
}

