<div class="modal-header" mat-dialog-title>
  <h2 i18n="@@kpi.whichKpisToCopy">Which KPIs would you like to copy?</h2>
</div>
<div class="modal-content" mat-dialog-content>
  <div class="container">
    <ng-container *ngIf="isLoading; else showContent">
      <div class="loading-container">
        <i class="fas fa-spinner fa-pulse" aria-hidden="true"></i>
      </div>
    </ng-container>
    <ng-template #showContent>
      <mat-list role="list" class="list">
        <mat-list-item *ngFor="let definition of definitions" role="listitem" class="list-item"
                       (click)="addDefinitionToList(definition)"
                       [class.list-item-selected]="selectedDefinitions.indexOf(definition) > -1"
                       [ngClass]="definition !== definitions[definitions.length - 1]? '':'list-item-wo'">
          <div class="u-flex">
            <lib-colored-icon class="kpi-icon" fontIcon="fa-bullseye-arrow"></lib-colored-icon>
            <div>{{ definition.name }}</div>
          </div>
        </mat-list-item>
      </mat-list>
    </ng-template>
  </div>
</div>
<div class="modal-footer" mat-dialog-actions>
  <div class="u-flex-divider"></div>
  <button mat-flat-button i18n="@@common.close" [mat-dialog-close]="false" class="close">Close</button>
  <button mat-flat-button
          color="accent"
          mat-dialog-close
          cdkFocusInitial
          (click)="copy()"
          [disabled]="!selectedDefinitions.length">
    <ng-container i18n="@@common.copy">Copy</ng-container>
  </button>
</div>
