import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { NgxDaterangepickerMd } from '@LIB_UTIL/ngx-daterangepicker/daterangepicker.module';
import { DateRangePickerFormComponent } from './date-range-picker-form/date-range-picker-form.component';
import { LgDateRangePickerComponent } from './date-range-picker.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LgIconModule } from '../icons/icon.module';

const materialModules: any[] = [// eslint-disable-line
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatIconModule,
  MatSelectModule,
];

const letsGrowModules: any[] = [// eslint-disable-line
  LgIconModule,
];

const moduleComponents: any[] = [// eslint-disable-line
  DateRangePickerFormComponent,
  LgDateRangePickerComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OverlayModule,
    NgxDaterangepickerMd.forRoot(),
    ReactiveFormsModule,
    ...materialModules,
    ...letsGrowModules,
  ],
  declarations: [
    ...moduleComponents,
  ],
  exports: [
    ...moduleComponents,
  ],
})
export class LgDateRangePickerModule {
}
