import { AfterViewInit, Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[appHideAfter]',
})
export class HideAfterDirective implements AfterViewInit {

  @Input() public appHideAfter: number = 0;
  @HostBinding('style.opacity') public opacity: number = 1;

  public ngAfterViewInit(): void {
    const delay: number = Number(this.appHideAfter) || 0;
    setTimeout(() => this.opacity = 0, delay);
  }
}
