import { environment } from '@LIB_UTIL/environments/environment';
import { DefinitionType } from '@LIB_UTIL/model/definitiontype';
import { LocaleID } from '@LIB_UTIL/util/locale';

/**
 * Return the url to the chart editor.
 */
export const getEditorUrl = (
  definitionType: DefinitionType,
  definitionId: number,
  language: LocaleID = 'en',
  sid: string,
  cropGroupId: number = null,
  moduleTemplateId: number = null
): string => {
  let url: string = `${environment.editorUrl}`;

  if (environment.production) {
    url = `${url}/${language}`;
  }

  url = `${url}/${definitionType}/${definitionId}?sid=${sid}`;

  if (!!cropGroupId) {
    url += `&cropGroupId=${cropGroupId}`;
  }

  if (!!moduleTemplateId) {
    url += `&moduleTemplateId=${moduleTemplateId}`;
  }

  return url;
};
