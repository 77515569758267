import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  inject
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { LayoutState } from '@LIB_UTIL/layout/state/layout.state';
import { AuthService } from '@LIB_UTIL/auth/services/auth.service';
import { serialUnsubscriber } from '@LIB_UTIL/util/rx';
import { CollapseNavigation, DeactivateFullScreen } from '@LIB_UTIL/layout/state/layout.actions';
import { NavItem } from '@LIB_UTIL/layout/models/nav-item.model';
import { Router } from '@angular/router';
import { LocaleID, isRtl } from '@LIB_UTIL/util/locale';
import { AuthState } from '@LIB_UTIL/auth/state/auth.state';
import { setPartnerFavIcon } from '@LIB_UTIL/util/partners';

const darkThemeClass: string = 'dark';
const lightThemeClass: string = 'light';

@Component({
  selector: 'lib-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  private store: Store = inject(Store);
  private el: ElementRef = inject(ElementRef);
  private authService: AuthService = inject(AuthService);
  private router: Router = inject(Router);
  @Inject(LOCALE_ID) private locale: LocaleID = inject(LOCALE_ID) as LocaleID;

  @Input() public navItems: NavItem[] = [];
  @Input() public redirectToLoginOnexpire: boolean = true;
  @Input() public logo: string = './assets/lib-util/images/logo-white.png';
  @Input() public applyBranding: boolean = true;
  @Input() public overrideBrand: string = '';

  public brand$: Observable<string> = this.store.select(AuthState.brand);
  public darkTheme$: Observable<boolean> = this.store.select(AuthState.darkTheme);
  public navigationExpanded$: Observable<boolean> = this.store.select(LayoutState.navigationExpanded);
  public isFullScreen$: Observable<boolean> = this.store.select(LayoutState.isFullScreen);

  @HostBinding('class.expanded') public navigationExpanded: boolean;
  @HostBinding('class.no-transitions') public noTransitions: boolean = true;
  @HostBinding('class.full-screen') public isFullScreen: boolean;
  @HostBinding('class.rtl') public rtl: boolean = isRtl(this.locale);

  /**
   * When this browser tab gets focus, we need to check if the user is still signed in.
   * (He might be signed out on another browser tab)
   * This will automatically redirect him to the login page if necessary.
   */
  @HostListener('window:focus', ['$event'])
  public async onFocus(): Promise<void> {
    if (this.redirectToLoginOnexpire) {
      this.authService.checkAuth();
    } else {
      const expired: boolean = await this.authService.isSessionExpired();

      if (expired) {
        this.router.navigate(['/session-expired']);
      }
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  public onEscape(): void {
    this.store.dispatch(new DeactivateFullScreen());
  }

  private subs: Record<string, Subscription> = {};
  private previousBrand: string = null;

  public ngOnInit(): void {
    this.subs['navigationExpanded'] = this.navigationExpanded$
      .subscribe((expanded: boolean) => this.navigationExpanded = expanded);
    this.subs['isFullScreen'] = this.isFullScreen$
      .subscribe((fullScreen: boolean) => this.isFullScreen = fullScreen);

    this.subs['brand'] = this.brand$
      .subscribe((brand: string) => {
        if (this.overrideBrand !== '') {
          brand = this.overrideBrand;
        }
        if (this.previousBrand) {
          this.el.nativeElement.classList.remove(...this.previousBrand);
          document.body.classList.remove(this.previousBrand);
        }
        this.el.nativeElement.classList.add(brand?.toLowerCase());
        document.body.classList.add(brand?.toLocaleLowerCase());

        setPartnerFavIcon(brand);

        this.previousBrand = brand;
      });

    this.subs['darkTheme'] = this.darkTheme$
      .subscribe((isDarkTheme: boolean) => {
        if (isDarkTheme) {
          this.el.nativeElement.classList.add(darkThemeClass);
          document.body.classList.add(darkThemeClass);
          this.el.nativeElement.classList.remove(lightThemeClass);
          document.body.classList.remove(lightThemeClass);
        } else {
          this.el.nativeElement.classList.add(lightThemeClass);
          document.body.classList.add(lightThemeClass);
          this.el.nativeElement.classList.remove(darkThemeClass);
          document.body.classList.remove(darkThemeClass);
        }
      });
  }

  /**
   * After layout has loaded, we can allow for transitions.
   */
  public ngAfterViewInit(): void {
    setTimeout(() => this.noTransitions = false, 100);
  }

  public ngOnDestroy(): void {
    serialUnsubscriber(...Object.values(this.subs));
  }

  public hideNavigation(): void {
    this.store.dispatch(new CollapseNavigation());
  }

  public deactivateFullScreen(): void {
    this.store.dispatch(new DeactivateFullScreen());
  }
}
