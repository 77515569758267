// eslint-disable-next-line complexity
export function isPartner(partnerCode: string): boolean {

  // The commented brands have been commented out on purpose, since
  // we cleaned up the list of brands. But we want to keep them just in case.
  // See story 76243
  switch (partnerCode?.toLowerCase()) {
    case 'ae':   // agro energy
    case 'ar':   // aranet
    case 'cu':   // cultilene
    case 'dd':   // delphy data
    case 'dt':   // delphy teelt
    case 'em':   // EMS
    case 'fv':   // food ventures
    case 'gb':   // gearbox
    case 'hfc':  // Horti Footprint Calculator
    case 'hk':   // hortikey
    case 'ke':   // kas als energiebron
    case 'mj':   // MJ Tech
    case 'mp':   // MPS
    case 'oc':   // OCAP
    case 'pe':   // plant empowerment
    case 'rz':   // rijkszwaan
    case 'sg':   // SunsetGrown
    case 'sl':   // silal
    case 'sn':   // sense NL
    case 'sv':   // svensson
    //case 'bm': // b-mex
    //case 'br': // brinkman
    //case 'cd': // corvus drones
    //case 'da': // dalsem
    //case 'hs': // hortisense
    //case 'hv': // hortivation
    //case 'mg': // mobigrower
    //case 'ph': // planthub.eu
    //case 'qu': // quantified (sensor technology)
    //case 'rb': // royal brinkman
    //case 'vh': // van der hoeven
      return true;
    default:
      return false;
  }
}

export function partnerIconPath(code: string = ''): string {
  return isPartner(code)
    ? `./assets/lib-util/partners/${code.toLowerCase()}/favicon.ico`
    : './favicon.ico';
}

export function partnerLogoPath(darkMode: boolean, code: string = ''): string {
  return isPartner(code)
    ? `./assets/lib-util/partners/${code.toLowerCase()}/logo-on-white.png`
    : darkMode
      ? './assets/lib-util/images/logo-white.png'
      : './assets/lib-util/images/logo-dark.png';
}

export const setPartnerFavIcon = (partner: string): void => {
  if (!partner || partner.length === 0) {
    return;
  }

  // For the partners below there is a favicon.ico available in the repo.
  const faviconUrl: string = isPartner(partner.toLowerCase())
    ? `./assets/lib-util/partners/${partner.toLowerCase()}/favicon.ico`
    : './favicon.ico';

  const fav: HTMLElement = document.getElementById('favicon');

  if (fav) {
    fav.setAttribute('href', faviconUrl);
  }
};
