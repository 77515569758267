<ng-template #userPhoto let-photoBase64="photoBase64" let-initials="initials" let-size="size">
  <!-- If photo -->
  <img *ngIf="photoBase64; else showInitials"
       [src]="photoBase64"
       [alt]="initials"
       [class]="size"
       [ngClass]="{'disabled': (sessionExpired$ | async)}"
       class="profile-menu__profile__avatar__img">
  <!-- Else show initials -->
  <ng-template #showInitials>
    <lib-user-initials [ngClass]="{'disabled': (sessionExpired$ | async)}" [initials]="initials" [size]="size"></lib-user-initials>
  </ng-template>
</ng-template>

<div class="profile-menu__trigger"
     *ngIf="(userInfo$ |async) as user"
     [matMenuTriggerFor]="profileMenu"
     appReadOnly>
  <ng-container
    *ngTemplateOutlet="userPhoto; context:{ photoBase64: user?.photoBase64, initials: user?.initials, size: 'small' }">
  </ng-container>
  <ng-container *ngIf="(unreadAnnouncements$ | async) as unread">
    <span class="notification" *ngIf="unread > 0">{{ unread }}</span>
  </ng-container>
</div>

<mat-menu #profileMenu="matMenu" class="profile-menu">
  <ng-container *ngIf="!(sessionExpired$ | async)">
    <div class="profile-menu__profile" *ngIf="(userInfo$ | async) as user">
      <div class="userinfo u-flex-vertical">
        <h3>{{ user?.displayName }}</h3>
        <div class="userinfo-item u-ellipsis">
          <div class="userinfo-label" i18n>Username:</div>
          {{ user?.userName }}
        </div>
        <div class="userinfo-item u-ellipsis">
          <div class="userinfo-label" i18n>Email:</div>
          {{ user?.emailAddress }}
        </div>
      </div>
      <div class="profile-menu__profile__avatar">
        <div class="profile-menu__profile__avatar__input">
          <ng-container
            *ngTemplateOutlet="userPhoto; context:{ photoBase64: user?.photoBase64, initials: user?.initials }">
          </ng-container>
          <input type="file"
                id="file"
                accept="image/x-png,image/jpeg,image/jpg"
                #fileInput
                (change)="uploadPhoto($event)">
        </div>

        <!--Buttons to change / delete photo-->
        <div class="profile-menu__profile__avatar__actions">
          <mat-icon fontSet="fas" fontIcon="fa-pencil"
                    class="profile-menu__profile__avatar__actions__icon profile-menu__profile__avatar__actions__icon--edit"
                    (click)="fileInput.click()">
          </mat-icon>
          <mat-icon fontSet="fas" fontIcon="fa-trash-can"
                    [class.hidden]="!user?.photoBase64"
                    class="profile-menu__profile__avatar__actions__icon profile-menu__profile__avatar__actions__icon--delete"
                    (click)="deletePhoto()">
          </mat-icon>
        </div>
      </div>
    </div>

    <!-- Drop down menu with Settings, Announcements and Sign Out-->
    <button mat-menu-item routerLink="/settings"
            *ngIf="showSettingsLink">
      <mat-icon fontSet="fas" fontIcon="fa-gear"></mat-icon>
      <ng-container i18n="@@common.settings">Settings</ng-container>
    </button>
    <button mat-menu-item
            (click)="openAnnouncements()">
      <mat-icon fontSet="fas" fontIcon="fa-bell"></mat-icon>
      <ng-container i18n="@@profile.announcements">Announcements</ng-container>
      <ng-container *ngIf="(unreadAnnouncements$ | async) as unread">
        <span class="notification" *ngIf="unread > 0">{{ unread }}</span>
      </ng-container>
    </button>
    <button mat-menu-item
            *ngIf="slaUrl$ | async as slaUrl"
            (click)="openServiceLevelAgreement(slaUrl)">
      <mat-icon fontSet="fas" fontIcon="fa-file"></mat-icon>
      <ng-container i18n="@@profile.sla">Service Level Agreement</ng-container>
    </button>
    <button mat-menu-item
            *ngIf="(isSignOutButtonVisible$ | async)"
            (click)="signOut()">
      <mat-icon fontSet="fas" fontIcon="fa-right-from-bracket"></mat-icon>
      <ng-container i18n="@@common.signOut">Sign out</ng-container></button>
  </ng-container>
</mat-menu>
