<ng-container *ngIf="confirmText?.title; else defaultConfirm">
  <div class="modal-header" mat-dialog-title>
    <h2 class="u-bold">{{confirmText.title}}</h2>
  </div>
  <div class="modal-content" mat-dialog-content>
    <div class="caution u-bold" i18n="@common.caution">Caution</div>
    <div>{{confirmText.message}}</div>
  </div>
  <div class="modal-footer" mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>{{confirmText.cancelText}}</button>
    <button mat-flat-button cdkFocusRegionstart color="accent" [mat-dialog-close]="true">{{confirmText.confirmText}}</button>
  </div>
</ng-container>

<ng-template #defaultConfirm>
  <div class="modal-header" mat-dialog-title>
    <h2 i18n="@@common.areYouSure" class="u-bold">Are you sure?</h2>
  </div>
  <div class="modal-content" mat-dialog-content>
    <ng-container *ngIf="confirmText?.message; then message; else cannotBeUndone"></ng-container>
    <ng-template #message>
      <ng-container>{{confirmText?.message}}</ng-container>
    </ng-template>
    <ng-template #cannotBeUndone>
      <ng-container i18n="@@action-cannot-be-undone">This action cannot be undone.</ng-container>
    </ng-template>
  </div>
  <div class="modal-footer" mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close i18n="@@common.cancel">Cancel</button>
    <button mat-flat-button color="accent" [mat-dialog-close]="true" i18n="@@common.yes">Yes</button>
  </div>
</ng-template>
