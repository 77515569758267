<div class="iframe-spinner-background">
 <i class="fas fa-spinner fa-pulse" [appHideAfter]="200" aria-hidden="true"></i>
</div>
<iframe
  [src]="url | safeUrl"
  [appFadeInDelay]="2000"
  allowfullscreen="allowfullscreen"
  mozallowfullscreen="mozallowfullscreen"
  msallowfullscreen="msallowfullscreen"
  oallowfullscreen="oallowfullscreen"
  webkitallowfullscreen="webkitallowfullscreen"
></iframe>
