export interface PeriodModel {
  startDate?: string;
  endDate?: string;
  periodType: PeriodType;
  pinnedPeriodType?: PeriodType;
}

export enum PeriodType {
  None,
  Last24Hours,
  LastWeek,
  Last4Weeks,
  LastHalfYear,
  LastYear,
  Custom
}
