<div class="modal-header" mat-dialog-title>
  <h2 i18n>Announcements</h2>
</div>
<div class="modal-content" mat-dialog-content [@.disabled]="disableAnimation">
  <ng-container *ngIf="announcements$ | async as announcements">
    <mat-accordion *ngIf="announcements.length; else noAnnouncements">
      <mat-expansion-panel *ngFor="let announcement of announcements"
                           [expanded]="lastOpenedAnnouncementId === announcement.id"
                           (afterExpand)="markIsRead(announcement)"
                           [class.task]="announcement.type === announcementTypes.task">
        <mat-expansion-panel-header [class.unread]="!announcement.isRead" cdkFocusInitial>
          <mat-panel-title [innerHTML]="announcement.html | safeHtml"></mat-panel-title>
          <mat-panel-description>{{  announcement.date | date }}</mat-panel-description>
        </mat-expansion-panel-header>
        <div class="content">
          <div [innerHTML]="announcement.html"></div>
          <button *ngIf="announcement.type === announcementTypes.announcement"
                  mat-flat-button class="with-mat-icon"
                  (click)="delete(announcement)">
            <mat-icon fontSet="fas" fontIcon="fa-trash-can"></mat-icon>
            <span i18n="@@deleteAnnouncement">Delete announcement</span>
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <ng-template #noAnnouncements>
      <p i18n>You don't have any announcements</p>
    </ng-template>
  </ng-container>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close i18n="@@common.close">Close</button>
</div>
