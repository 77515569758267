import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { dashboardStateDefaults } from '@LG_ROOT/app/state/dashboard/dashboard.state.defaults';
import {
  DashboardCreateModel,
  DashboardCropModel,
  DashboardModel,
  DashboardOrderProperties,
  DashboardSelectionDashboardModel,
  DashboardSelectionGroupModel,
  DashboardSelectionModel,
  DashboardTileCropSelection,
  DashboardTileData,
  DashboardTileDataModel,
  DashboardTileModel,
  DashboardTileTypeGaugeModel,
  DashboardTileTypes,
  DashboardTileTypeShortcutModel,
  DashboardTranslation,
  DashboardTypes
} from '@LG_CORE/dashboards/dashboards.model';
import {
  AddDashboardTilesAction,
  CreateDashboardAction,
  DeleteDashboardAction,
  GetDashboardAction,
  GetDashboardsAction,
  GetDashboardTileAction,
  HideCropGroupAction,
  HideDashboardTimelapseAction,
  PinCropGroupAction,
  PinTileCropAction,
  SetActiveCropsAction,
  SetDashboardHasChangesAction,
  SetDashboardHoveredTimestampAction,
  SetDashboardPeriodAction,
  SetDashboardTimelapseConfigurationAction,
  SetDashboardTimelapseTimestampAction,
  SetFavoriteDashboardIndex,
  SetGaugeTileSelectedCropId,
  SetIsFavoriteAction,
  ToggleCropSelectorVisibility,
  UpdateDashboardNameAction,
  UpdateDashboardTilesAction
} from '@LG_ROOT/app/state/dashboard/dashboard.action';
import { DashboardsApiService } from '@LG_CORE/dashboards/dashboards-api.service';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import {
  DashboardActiveCropsModel,
  DashboardStateModel
} from '@LG_ROOT/app/state/dashboard/dashboard.state.model';
import { SignalRService } from '@LG_CORE/signal-r/signal-r.service';
import { patch, updateItem } from '@ngxs/store/operators';
import { find } from 'lodash';
import { AddDashboardChartAction, SetDashboardChartsAction } from '@LIB_UTIL/chart/state/charts.action';
import { getDefaultCropId } from '@LG_DASHBOARD/components/crop-select/crop-select.helper';
import { Observable, of, Subject } from 'rxjs';
import { Router, Event, NavigationEnd } from '@angular/router';
import { PeriodModel, PeriodType } from '@LIB_UTIL/model/period.model';
import { getPeriodByType } from '@LIB_UTIL/util/period';
import { ChartCompareSettings, ChartModel } from '@LIB_UTIL/chart/model/charts-api.model';
import { TimelapseModel } from '@LIB_UTIL/model/timelapse';
import { HttpErrorResponse } from '@angular/common/http';
import {
  getAllDashboards,
  setSelectedCropInTiles
} from './dashboard.state.util';
import { CookieService } from 'ngx-cookie-service';
import { isTheSameDashboard } from '@LG_ROOT/app/shared/utils/dashboard.util';

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: dashboardStateDefaults,
})
@Injectable()
export class DashboardState {

  private currentRoute$: Subject<string> = new Subject();

  constructor(
    private dashboardService: DashboardsApiService,
    private signalRService: SignalRService,
    private router: Router,
    private cookieService: CookieService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute$.next(event.url);
      }
    });
  }

  @Selector()
  public static dashboard(state: DashboardStateModel): DashboardSelectionDashboardModel {
    return state.dashboard;
  }

  @Selector()
  public static dashboardName(state: DashboardStateModel): string {
    return state.dashboardData.name;
  }

  @Selector()
  public static dashboardGroups(state: DashboardStateModel): DashboardSelectionGroupModel[] {
    return state.dashboards.dashboardGroups;
  }

  @Selector()
  public static allDashboards(state: DashboardStateModel): DashboardSelectionDashboardModel[] {
    return getAllDashboards(state);
  }

  @Selector()
  public static dashboardTiles(state: DashboardStateModel): DashboardTileModel[] {
    return state.dashboardData.tiles;
  }

  @Selector()
  public static dashboardPeriod(state: DashboardStateModel): PeriodModel {
    return state.dashboardData.period;
  }

  @Selector()
  public static dashboardTimelapse(state: DashboardStateModel): TimelapseModel {
    return state.timelapse;
  }

  @Selector()
  public static dashboardHoveredTimestamp(state: DashboardStateModel): number {
    return state.hoveredTimestamp;
  }

  @Selector()
  public static dashboardCrops(state: DashboardStateModel): DashboardCropModel[] {
    return state.dashboardData.crops;
  }

  @Selector()
  public static dashboardType(state: DashboardStateModel): DashboardTypes {
    return state.dashboardData.type;
  }

  @Selector()
  public static dashboardModuleTemplateId(state: DashboardStateModel): number {
    return state.dashboardData.moduleTemplateId;
  }

  @Selector()
  public static dashboardCropGroupId(state: DashboardStateModel): number {
    return state.dashboardData.cropGroupId;
  }

  @Selector()
  public static activeCrops(state: DashboardStateModel): DashboardActiveCropsModel {
    return state.activeCrops;
  }

  @Selector()
  public static translations(state: DashboardStateModel): DashboardTranslation {
    return state.dashboards.translations;
  }

  @Selector()
  public static hasCrops(state: DashboardStateModel): boolean {
    return Array(state.dashboardData.crops) && state.dashboardData.crops.length > 0;
  }

  @Selector()
  public static isFavorite(state: DashboardStateModel): boolean {
    const { id, moduleDefinitionId }: DashboardSelectionDashboardModel = state.dashboard;
    const group: DashboardSelectionGroupModel = find(state.dashboards.dashboardGroups, {
      dashboards: [{ id: id, moduleDefinitionId: moduleDefinitionId }],
    });

    return group ? find(group.dashboards, { id: id, moduleDefinitionId: moduleDefinitionId }).isFavorite : false;
  }

  @Selector()
  public static isEditable(state: DashboardStateModel): boolean {
    return state.dashboardData.isEditable;
  }

  @Selector()
  public static isExportable(state: DashboardStateModel): boolean {
    return state.dashboardData.isExportable;
  }

  @Selector()
  public static isLoading(state: DashboardStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  public static isNewUser(state: DashboardStateModel): boolean {
    return (state.dashboards === null) ? false : state.dashboards.dashboardGroups.length === 0;
  }

  @Selector()
  public static isNotFound(state: DashboardStateModel): boolean {
    return !state.dashboardData;
  }

  @Selector()
  public static errorMessage(state: DashboardStateModel): string {
    return state.errorMessage;
  }

  @Selector()
  public static dashboardHasChanges(state: DashboardStateModel): boolean {
    return state.dashboardHasChanges;
  }

  // eslint-disable-next-line max-lines-per-function
  @Action(GetDashboardAction)
  public getDashboard(
    { getState, patchState, dispatch }: StateContext<DashboardStateModel>,
    { dashboard }: GetDashboardAction
  ): Observable<DashboardModel> {
    const state: DashboardStateModel = getState();
    // Updated selected dashboard en reset all other data
    patchState({
      dashboard: dashboard,
      timelapse: { ...state.timelapse, displayControls: false },
      isLoading: true,
    });

    return this.dashboardService.getDashboard(dashboard)
      .pipe(
        takeUntil(this.currentRoute$),
        tap((dashboardData: DashboardModel) => {
          dashboardData.period.pinnedPeriodType = dashboardData.period.periodType;

          // Calculate the range based on the period type.
          dashboardData = { ...dashboardData, period: getPeriodByType(dashboardData.period) };

          // The backend now decides what the selected crop is,
          // so only get the selected crop so it can be shown in the frontend
          const activeCrop: DashboardCropModel = getDefaultCropId(dashboardData.crops);

          // Store the chart related info in the charts store.
          this.storeDashboardChartInfo(dashboardData, activeCrop?.id, dispatch);

          patchState({
            dashboardData: dashboardData,
            activeCrops: activeCrop ? { cropId: activeCrop.id, cropCompareId: null } : null,
            isLoading: false,
            errorMessage: null,
          });
          this.signalRService.initSignalR(dashboardData);
        }),
        catchError((error: HttpErrorResponse) => {
          patchState({
            dashboardData: null,
            activeCrops: null,
            isLoading: false,
            errorMessage: error.error,
          });

          return of(null);

        }));
  }

  public storeDashboardChartInfo(
    dashboard: DashboardModel,
    activeCropId: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: (actions: any | any[]) => Observable<void>
  ): void {

    const compare: Record<string, ChartCompareSettings> = {};

    // Get the compare crop values.
    const activeCompareCropId: number = dashboard.crops.find(crop => crop.isCompared)?.id;

    let newCharts: ChartModel[] = [];
    const charts: ChartModel[] = dashboard.tiles
      .filter(tile => tile.type === DashboardTileTypes.chart)
      .map(tile => tile.data as ChartModel);

    charts.forEach((chart: ChartModel) => {
      // If the dashboard has an active crop pass this info to the chart.
      // Otherwise check the crops returned by the backend.
      compare[chart.id] = {
        cropId: (!!activeCropId || !!activeCompareCropId)
          ? activeCropId
          : chart.crops?.find(crop => crop.isSelected)?.id,
        comparedCropId: (!!activeCropId || !!activeCompareCropId)
          ? activeCompareCropId
          : chart.crops?.find(crop => crop.isCompared)?.id,

      };

      const hiddenCropIds: number[] = dashboard.crops
        .filter((crop: DashboardCropModel) => crop.isHidden).map(x => x.id);
      chart = { ...chart, hiddenCrops: hiddenCropIds };
      // When the dashboard period is set, use this period instead of the chart period.
      if (dashboard.period.periodType !== PeriodType.None) {
        chart.period = dashboard.period;
      }

      newCharts.push(chart);
    });

    dispatch(new SetDashboardChartsAction(newCharts, compare, activeCropId));
  }

  @Action(UpdateDashboardNameAction)
  public updateDashboardName(
    { getState, patchState }: StateContext<DashboardStateModel>,
    { dashboardId, body }: UpdateDashboardNameAction
  ): Observable<DashboardSelectionDashboardModel> {
    return this.dashboardService.updateDashboardName(dashboardId, body)
      .pipe(tap((response: DashboardSelectionDashboardModel) => {

        // get dashboards from state
        const { dashboards, dashboardData, dashboard }: DashboardStateModel = getState();

        let dashGroups: DashboardSelectionGroupModel[] = [];

        // if dashboards were already loaded, update
        if (dashboards) {
          dashGroups = dashboards.dashboardGroups
            .reduce((accGroup: DashboardSelectionGroupModel[], dashGroup: DashboardSelectionGroupModel) => {
              const items: DashboardSelectionDashboardModel[] = dashGroup.dashboards
                .reduce((accDashboards: DashboardSelectionDashboardModel[], db: DashboardSelectionDashboardModel) => [
                  ...accDashboards, {
                    ...db,
                    name: db.id === dashboardId ? response.name : db.name,
                  }], []);

              return [
                ...accGroup,
                {
                  ...dashGroup,
                  dashboards: items,
                },
              ];
            }, []);
        }
        patchState({
          dashboards: dashboards ? { ...dashboards, dashboardGroups: dashGroups } : null,
          dashboard: dashboard ? { ...dashboard, name: body.name } : null,
          dashboardData: dashboardData ? { ...dashboardData, name: body.name } : null,
        });
      }));
  }

  @Action(CreateDashboardAction)
  public createDashboard(
    { dispatch }: StateContext<DashboardStateModel>,
    { body, fromSelector }: CreateDashboardAction
  ): Observable<DashboardCreateModel> {
    return this.dashboardService.createDashboard(body)
      .pipe(
        tap(async (response: DashboardCreateModel) => {
          const dash: DashboardSelectionDashboardModel = {
            id: response.id,
            name: response.name,
            prefix: response.prefix,
            isFavorite: false,
            isDefault: false,
            moduleDefinitionId: 0,
            subMenuId: 0,
            cropId: 0,
            cropGroupId: response.cropGroupId,
          };

          // Alright, debugging the code below really gave me a headache...
          // If I used dispatch/subscribe, the dashboard selector worked fine, but
          // the folders/crop group pages did not. And if I used await, then the
          // folders/crop group pages worked fine, but the crop selector did not.
          // So now I fixed id by adding a 'fromSelector' boolean, and implemented it
          // both ways.
          // I'm sure in the future the dashboard selector will be redesigned and rewritten,
          // so then we don't need this option anymore.
          //
          // When calling from the dashboard selector, first fetch all dashboards again,
          // then fetch the dashboard that we just created.
          // This is to make sure that the dashboard selector has all data available in
          // the drop down, before selecting the dashboard that we just created.
          if (fromSelector) {
            dispatch(new GetDashboardsAction()).subscribe(() =>
              dispatch(new GetDashboardAction(dash))
            );
          } else {
            // When calling from folders or crop groups, use await, so we make sure that
            // both these actions are done before the subscription to this action ends.
            // This way, we make sure that the state has loaded the new dashboard in property
            // 'dashboard',  and the folders/group component can request that dashboard from
            // the store and use it to open the dashboard in a new window.
            await dispatch(new GetDashboardsAction());
            await dispatch(new GetDashboardAction(dash));
          }

        })
      );
  }

  @Action(DeleteDashboardAction)
  public deleteDashboard(
    { dispatch, patchState }: StateContext<DashboardStateModel>,
    { dashboardId }: DeleteDashboardAction
  ): Observable<void> {
    return this.dashboardService.deleteDashboard(dashboardId)
      .pipe(
        tap(() => {
          this.deleteDefaultDashboard(dashboardId);

          dispatch(new GetDashboardsAction()).subscribe(() => {

            // patch the state of the dashboard and the data.
            // But do this in two phases, first the data and then
            // the dashboard. Otherwise the dasbhoard: null gets
            // triggered twice and causes a never ending loop.
            patchState({
              dashboardData: null,
            });

            patchState({
              dashboard: null,
            });
          });
        })
      );
  }

  private deleteDefaultDashboard(dashboardId: number): void {
    const cookieDashboardId: string = this.cookieService.get('defaultDashboardId');

    if (+cookieDashboardId === dashboardId) {
      this.cookieService.delete('defaultDashboardId');
      this.cookieService.delete('defaultModuleDefinitionId');
      this.cookieService.delete('defaultCropGroupId');
    }
  }

  @Action(GetDashboardsAction)
  public getDashboards(
    { patchState }: StateContext<DashboardStateModel>
  ): Observable<DashboardSelectionModel> {
    return this.dashboardService.getDashboards()
      .pipe(
        takeUntil(this.currentRoute$),
        tap((dashboards: DashboardSelectionModel) => {
          patchState({
            dashboards: {
              ...dashboards,
              dashboardGroups: dashboards.dashboardGroups,
            },
          });
        }));
  }

  @Action(GetDashboardTileAction)
  public getDashboardTile(
    { setState }: StateContext<DashboardStateModel>,
    { dashboardId, tileId, params }: GetDashboardTileAction
  ): Observable<DashboardTileDataModel> {
    return this.dashboardService.getDashboardTile(dashboardId, tileId, params)
      .pipe(
        takeUntil(this.currentRoute$),
        tap(({ data }: DashboardTileDataModel) => {
          setState(
            patch({
              dashboardData: patch({
                tiles: updateItem<DashboardTileModel>(object => object.id === tileId, patch({ data: data })),
              }),
            })
          );
        }));
  }

  /**
   * Add the tile returned by the POST /tiles endpoint in
   * both the dashboard and charts state. The dashboard state
   * makes sure the tile gets rendered on the dashboard. The
   * charts state handles the GET /data call with the right
   * parameters. We also keep track of the dashboards active crop.
   */
  @Action(AddDashboardTilesAction)
  public addDashboardTiles(
    { getState, patchState, dispatch }: StateContext<DashboardStateModel>,
    { dashboardId, body }: AddDashboardTilesAction
  ): Observable<DashboardTileModel[]> {
    return this.dashboardService.addDashboardTile(dashboardId, body)
      .pipe(tap((tiles: DashboardTileModel[]) => {
        const { dashboardData, activeCrops }: DashboardStateModel = getState();

        patchState({
          dashboardData: {
            ...dashboardData,
            tiles: [...dashboardData.tiles, ...tiles],
          },
        });

        // Add the new chart to the charts state so the right parameters
        // will automatically be appended to the GET /data request.
        tiles.forEach((tile: DashboardTileModel) => {
          dispatch(new AddDashboardChartAction(
            tile.data as DashboardTileModel[0],
            activeCrops?.cropId,
            activeCrops?.cropCompareId,
            dashboardData.period
          ));
        });
      }));
  }

  @Action(UpdateDashboardTilesAction)
  public updateDashboardTiles(
    { getState, patchState }: StateContext<DashboardStateModel>,
    { dashboardId, body }: UpdateDashboardTilesAction
  ): Observable<DashboardTileModel[]> {
    return this.dashboardService.updateDashboardTiles(dashboardId, body)
      .pipe(tap((tiles: DashboardTileModel[]) => {
        const dashData: DashboardModel = getState().dashboardData;
        patchState({
          dashboardData: {
            ...dashData,
            tiles: tiles,
          },
        });
      }));
  }

  @Action(SetDashboardPeriodAction)
  public setDashboardPeriod(
    { getState, patchState }: StateContext<DashboardStateModel>,
    { period }: SetDashboardPeriodAction
  ): void {
    const dashData: DashboardModel = getState().dashboardData;
    // Only trigger signalR if period is changed
    if (period.startDate !== dashData.period.startDate || period.endDate !== dashData.period.endDate) {
      this.signalRService.changeDashboardPeriod(dashData.subMenuDefinitionId, period);
    }
    patchState({
      dashboardData: {
        ...dashData,
        period: period,
      },
    });
  }

  /**
   * Store the timelapse configuration in the store, open the timelapse
   * controls when the configuration gets sets.
   */
  @Action(SetDashboardTimelapseConfigurationAction)
  public setDashboardTimelapse(
    { getState, patchState }: StateContext<DashboardStateModel>,
    { timelapse }: SetDashboardTimelapseConfigurationAction
  ): void {
    patchState({
      ...getState(),
      timelapse: {
        ...timelapse,
        displayControls: true,
      },
    });
  }

  /**
   * Store the timelapse configuration in the
   */
  @Action(HideDashboardTimelapseAction)
  public hideDashboardTimelapse({ getState, patchState }: StateContext<DashboardStateModel>): void {
    const state: DashboardStateModel = getState();
    patchState({
      ...state,
      timelapse: {
        ...state.timelapse,
        displayControls: false,
      },
    });
  }

  /**
   * Store the current timelapse timestamp.
   */
  @Action(SetDashboardTimelapseTimestampAction)
  public setDashboardTimelapseTimestamp(
    { getState, patchState }: StateContext<DashboardStateModel>,
    { timestamp }: SetDashboardTimelapseTimestampAction
  ): void {
    const state: DashboardStateModel = getState();

    patchState({
      ...state,
      timelapse: {
        ...state.timelapse,
        timestamp: timestamp,
      },
    });
  }

  /**
   * Store the current timelapse timestamp.
   */
  @Action(SetDashboardHoveredTimestampAction)
  public setDashboardHoveredTimestamp(
    { patchState }: StateContext<DashboardStateModel>,
    { timestamp }: SetDashboardHoveredTimestampAction
  ): void {
    patchState({ hoveredTimestamp: timestamp });
  }

  @Action(SetIsFavoriteAction)
  public setIsFavorite(
    { dispatch, getState, patchState }: StateContext<DashboardStateModel>,
    { dashboard, isFavorite }: SetIsFavoriteAction
  ): Observable<boolean> {
    return this.dashboardService.setIsFavorite(dashboard, isFavorite)
      .pipe(tap((favorite: boolean) => {
        const dashData: DashboardModel = getState().dashboardData;

        // Only update local state if the favorite change is for active dashboard
        if (dashboard.id === dashData.id) {
          patchState({
            dashboardData: {
              ...dashData,
              isFavorite: favorite,
            },
          });
        }
        dispatch(new GetDashboardsAction());
      }));
  }


  @Action(SetActiveCropsAction)
  public setActiveCrops(
    { getState, patchState }: StateContext<DashboardStateModel>,
    { cropId, cropCompareId }: SetActiveCropsAction
  ): void {

    const dashboardCrops: DashboardCropModel[] = getState().dashboardData.crops.reduce((acc, crop) => [
      ...acc,
      {
        ...crop,
        isSelected: crop.id === cropId,
      },
    ], []);

    // update the tiles in the dashboard to show the selected crop
    const updatedDashboardData: DashboardModel = setSelectedCropInTiles(getState().dashboardData, cropId);

    patchState({
      activeCrops: cropId
        ? {
          cropId: cropId,
          cropCompareId: cropCompareId,
        }
        : null,
      dashboardData: {
        ...updatedDashboardData,
        crops: dashboardCrops,
      },
    });
  }


  @Action(HideCropGroupAction)
  public hideCropGroup(
    { dispatch, getState, patchState }: StateContext<DashboardStateModel>,
    { dashboardId, cropId, isHidden }: HideCropGroupAction
  ): Observable<DashboardCropModel> {
    return this.dashboardService.hideCropGroup(dashboardId, cropId, isHidden)
      .pipe(tap((dashboardCrop: DashboardCropModel) => {
        const dashboardCrops: DashboardCropModel[] = getState().dashboardData.crops.reduce((acc, crop) => [
          ...acc,
          {
            ...crop,
            isHidden: crop.id === dashboardCrop.id ? dashboardCrop.isHidden : crop.isHidden,
          },
        ], []);
        patchState({
          dashboardData: {
            ...getState().dashboardData,
            crops: dashboardCrops,
          },
        }
        );

        // Store the chart related info in the charts store.
        this.storeDashboardChartInfo(getState().dashboardData, cropId, dispatch);
      }));
  }

  @Action(PinCropGroupAction)
  public pinCropGroup(
    { getState, patchState }: StateContext<DashboardStateModel>,
    { dashboardId, cropId, isPinned, cropGroupId }: PinCropGroupAction
  ): Observable<DashboardCropModel> {
    return this.dashboardService.pinCropGroup(dashboardId, cropId, isPinned, cropGroupId)
      .pipe(tap((cropGroup: DashboardCropModel) => {
        const cropGroups: DashboardCropModel[] = getState().dashboardData.crops.reduce((acc, crop) => [
          ...acc,
          {
            ...crop,
            isPinned: crop.id === cropGroup.id ? cropGroup.isPinned : false,
          },
        ], []);
        patchState({
          dashboardData: {
            ...getState().dashboardData,
            crops: cropGroups,
          },
        });
      }));
  }

  @Action(PinTileCropAction)
  public pinShortcutTileCrop(
    ctx: StateContext<DashboardStateModel>,
    { dashboardId, tileId, pinnedCropId, selectedCropId, cropGroupId }: PinTileCropAction
  ): Observable<DashboardTileData> {


    const cropSelection: DashboardTileCropSelection = {
      pinnedCropId: pinnedCropId,
      selectedCropId: selectedCropId,
    };

    return this.dashboardService.pinAndSelectTileCrop(dashboardId, tileId, cropSelection, cropGroupId)
      .pipe(
        takeUntil(this.currentRoute$),
        tap((resultTileData: DashboardTileData) => {

          // get the original tile
          const originalTile: DashboardTileModel = this.getTileFromStore(ctx, tileId);
          if (originalTile.type === DashboardTileTypes.shortcut) {
            resultTileData = this.updateShortcutName(originalTile, resultTileData as DashboardTileTypeShortcutModel);
          }

          if (originalTile.type === DashboardTileTypes.gauge) {
            const updatedData: DashboardTileTypeGaugeModel = {
              ...resultTileData as DashboardTileTypeGaugeModel,
              selectedCropId: selectedCropId,
            };

            resultTileData = { ...(updatedData as DashboardTileData) };
          }

          ctx.setState(
            patch({
              dashboardData: patch({
                tiles: updateItem<DashboardTileModel>((tile: DashboardTileModel) => tile.id === tileId,
                  patch({ data: resultTileData as DashboardTileData })
                ),
              }),
            })
          );
        })
      );
  }

  @Action(SetGaugeTileSelectedCropId)
  public setGaugeTileSelectedCropId(
    ctx: StateContext<DashboardStateModel>,
    { tileId, cropId }: SetGaugeTileSelectedCropId
  ): void {

    // find original tile
    const originalTile: DashboardTileModel = this.getTileFromStore(ctx, tileId);

    const updatedData: DashboardTileTypeGaugeModel = {
      ...originalTile.data as DashboardTileTypeGaugeModel,
      selectedCropId: cropId,
    };

    const newData: DashboardTileData = { ...(updatedData as DashboardTileData) };

    ctx.setState(
      patch({
        dashboardData: patch({
          tiles: updateItem<DashboardTileModel>((tile: DashboardTileModel) => tile.id === tileId,
            patch({ data: newData as DashboardTileData })
          ),
        }),
      })
    );
  }

  /**
   * Set 'dashboard has changes' boolean
   */
  @Action(SetDashboardHasChangesAction)
  public setDashboardHasChangesAction(
    ctx: StateContext<DashboardStateModel>,
    { hasChanges }: SetDashboardHasChangesAction
  ): void {
    ctx.patchState({
      dashboardHasChanges: hasChanges,
    });
  }

  @Action(SetFavoriteDashboardIndex)
  public setFavoriteDashboardIndex(
    { getState, patchState }: StateContext<DashboardStateModel>,
    { dashboard, placeBeforeDashboard }: SetFavoriteDashboardIndex
  ): Observable<boolean> {
    const favoriteDashboards: DashboardSelectionDashboardModel[] = getState().dashboards.dashboardGroups
      .filter((group: DashboardSelectionGroupModel) => group.type === DashboardTypes.Favorite)
      .flatMap((group: DashboardSelectionGroupModel) => group.dashboards);

    const oldIndex: number = favoriteDashboards.findIndex((db: DashboardSelectionDashboardModel) =>
      isTheSameDashboard(db, dashboard));
    const placeBeforeIndex: number = favoriteDashboards.findIndex((db: DashboardSelectionDashboardModel) =>
      isTheSameDashboard(db, placeBeforeDashboard));

    const oldBase: number = Math.floor(oldIndex / 10) * 10;
    const newBase: number = Math.floor(placeBeforeIndex / 10) * 10;

    const newIndex: number = oldBase < newBase ? placeBeforeIndex - 1 : placeBeforeIndex;
    const oldDashboard: DashboardSelectionDashboardModel = { ...favoriteDashboards[oldIndex] };

    favoriteDashboards.splice(oldIndex, 1);
    favoriteDashboards.splice(newIndex, 0, oldDashboard);

    const order: DashboardOrderProperties[] = favoriteDashboards.map(it => ({
      dashboardId: it.id, moduleDefinitionId: it.moduleDefinitionId, cropGroupId: it.cropGroupId }));

    return this.dashboardService.updateFavoriteDashboardOrder(order).pipe(
      tap((result: boolean) => {
        if (result) {
          const newGroups: DashboardSelectionGroupModel[] = getState().dashboards.dashboardGroups
            .map((group: DashboardSelectionGroupModel) => ({
              ...group,
              dashboards: group.type === DashboardTypes.Favorite
                ? favoriteDashboards
                : group.dashboards,
            }));

          patchState({
            dashboards: {
              ...getState().dashboards,
              dashboardGroups: newGroups,
            },
          });
        }
      })
    );


  }

  /**
   * Toggle the visibilitiy of the crop selector in the gauge tile.
   */
  @Action(ToggleCropSelectorVisibility)
  public toggleCropSelectorVisibility(
    ctx: StateContext<DashboardStateModel>,
    { dashboardId, tileId, show }: ToggleCropSelectorVisibility
  ): Observable<boolean> {

    return this.dashboardService.showCropSelector(dashboardId, tileId, show)
      .pipe(
        takeUntil(this.currentRoute$),
        tap(() => {

          const originalTile: DashboardTileModel = ctx.getState().dashboardData.tiles
            .find((tile: DashboardTileModel) => tile.id === tileId);

          // currently this action will only be used for gauge tiles
          const originalData: DashboardTileTypeGaugeModel = originalTile.data as DashboardTileTypeGaugeModel;
          const updatedData: DashboardTileTypeGaugeModel = {
            ...originalData,
            selectedCropId: originalData.selectedCropId,
            showCropSelector: show,
          };

          ctx.setState(
            patch({
              dashboardData: patch({
                tiles: updateItem<DashboardTileModel>((tile: DashboardTileModel) => tile.id === tileId,
                  patch({ data: updatedData as DashboardTileData })
                ),
              }),
            })
          );
        })
      );
  }


  /**
   * If we change the selected/pinned crop for a shortcut tile, then we need to adjust the name and selectedCropName,
   * so this can be displayed in the tile.
   */
  private updateShortcutName(tile: DashboardTileModel, newData: DashboardTileTypeShortcutModel): DashboardTileData {

    const originalData: DashboardTileTypeShortcutModel = tile.data as DashboardTileTypeShortcutModel;
    const originalName: string = originalData.name;

    const selectedCrop: DashboardCropModel = newData.crops?.find((crop: DashboardCropModel) => crop.isSelected);

    return selectedCrop
      ? { ...newData, name: originalName }
      : { ...newData, name: originalName, selectedCropName: `(${ selectedCrop.name })` };
  }

  private getTileFromStore(ctx: StateContext<DashboardStateModel>, tileId: number): DashboardTileModel {
    const tiles: DashboardTileModel[] = ctx.getState().dashboardData.tiles;

    return tiles.find((tile: DashboardTileModel) => tile.id === tileId);
  }
}
