import { PlatformSettings } from '@LG_SETTINGS/platform/platform.model';

export class GetLanguages {
  public static readonly type: string = '[Settings API] Get Languages';
}

export class SetLanguage {
  public static readonly type: string = '[Settings API] Set Language';

  constructor(public language: string) {
  }
}

export class GetPlatformSettings {
  public static readonly type: string = '[Settings API] Get platform settings';
}

export class SetPlatformSettings {
  public static readonly type: string = '[Settings API] Set platform settings';

  constructor(public platformSettings: PlatformSettings) {
  }
}

export class GetCountries {
  public static readonly type: string = '[Settings API] Get country list';
}
