import { AfterViewInit, Component, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AnnouncementsModel, AnnouncementTypes } from '@LIB_UTIL/services/users-api/users-api.model';
import { DeleteAnnouncement, UpdateAnnouncementRead } from '@LIB_UTIL/profile/state/profile.state.actions';
import { ProfileState } from '@LIB_UTIL/profile/state/profile.state';

@Component({
  selector: 'app-announcements-modal',
  templateUrl: './announcements-modal.component.html',
  styleUrls: ['./announcements-modal.component.scss'],
})
export class AnnouncementsModalComponent implements AfterViewInit {

  private store: Store = inject(Store);

  public announcements$: Observable<AnnouncementsModel[]> = this.store.select(ProfileState.announcements);

  public announcementTypes: typeof AnnouncementTypes = AnnouncementTypes;
  public disableAnimation: boolean = true;
  public lastOpenedAnnouncementId: number;

  // Workaround for angular component issue #13870
  public ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
  }

  public markIsRead({ id, isRead }: AnnouncementsModel): void {
    if (!isRead) {
      this.lastOpenedAnnouncementId = id;
      this.store.dispatch(new UpdateAnnouncementRead(id));
    }
  }

  public delete({ id }: AnnouncementsModel): void {
    this.store.dispatch(new DeleteAnnouncement(id));
  }
}
