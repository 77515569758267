import { Component, EventEmitter, HostBinding, Input, LOCALE_ID, OnInit, Output, inject } from '@angular/core';
import { CropGroupDetails, CropGroupDetailsType } from '@LG_CORE/crop-groups/crop-groups.model';
import { Store } from '@ngxs/store';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { get } from 'lodash';
import { Module, ModuleDetailsType, ModuleTemplate } from '@LG_CORE/modules/modules.model';
import { Folder, FoldersDetailsType } from '@LG_CORE/folders/folders.model';
import { Definition, Parent, SelectDefinitionConfig } from '@LG_CORE/page-title/shared.model';
import {
  DashboardSelectionDashboardModel,
  DashboardTileTypes,
  DashboardTypes
} from '@LG_CORE/dashboards/dashboards.model';
import { ModulesState } from '@LG_ROOT/app/state/modules/modules.state';
import { FoldersState } from '@LG_ROOT/app/state/folders/folders.state';
import { GetFolder } from '@LG_ROOT/app/state/folders/folders.actions';
import { DashboardState } from '@LG_ROOT/app/state/dashboard/dashboard.state';
import {
  GetCropGroup,
  GetCropGroupArchives,
  GetCropGroupFolder
} from '@LG_ROOT/app/state/cropgroups/crop-groups.actions';
import { GetModuleEntityDetails } from '@LG_ROOT/app/state/modules/modules.actions';
import { environment } from '@LIB_UTIL/environments/environment';
import { Role } from '@LIB_UTIL/model/role.model';
import { isRtl, LocaleID } from '@LIB_UTIL/util/locale';
import { DefinitionType } from '@LIB_UTIL/model/definitiontype';
import { CropGroupsState } from '@LG_ROOT/app/state/cropgroups/crop-groups.state';
import { CropGroup } from '@LIB_UTIL/model/cropgroup.model';

export interface SelectDefinitionModel {
  selectedDefinitions: Definition[];
  selectedFolders: string[];
  cropGroupId: number;
  moduleTemplateId: number;
  moduleDefinitionId: number;
  folderId: number;
  parentId: number;
  parents: [];
  archiveYear: number;
  selectedDefinitionType: DefinitionType;
}

const getRootParent = (): Parent => {
  return { id: '', name: 'MyLetsGrow', type: 'root' };
};

const getCropGroupsParent = (): Parent => {
  return { id: '', name: '[Crop Groups]', type: null };
};

const getArchivesParent = (): Parent => {
  return { id: '', name: '[Crop Group Archives]', type: 'archive' };
};

const getModulesParent = (): Parent => {
  return { id: '', name: '[Modules]', type: 'null' };
};

@Component({
  selector: 'app-select-definition',
  templateUrl: './select-definition.component.html',
  styleUrls: ['./select-definition.component.scss'],
})
export class SelectDefinitionComponent implements OnInit {

  private store: Store = inject(Store);
  private locale: LocaleID = inject(LOCALE_ID) as LocaleID;


  @HostBinding('class.rtl') public rtl: boolean = isRtl(this.locale);
  @Input() public config: SelectDefinitionConfig;
  @Input() public tileType: DashboardTileTypes;
  @Input() public definitionType: DefinitionType;
  @Output() public readonly changed: EventEmitter<SelectDefinitionModel> = new EventEmitter<SelectDefinitionModel>();
  public moduleTemplates$: Observable<ModuleTemplate[]> = this.store.select(ModulesState.moduleTemplates);
  public folders$: Observable<Folder[]> = this.store.select(FoldersState.folders);
  public cropGroupId: number;
  public cropGroupName: string;
  public folderId: number;
  public folderName: string;
  public archiveYear: number;
  public cropGroupDetailsType: CropGroupDetailsType;
  public cropGroups: CropGroup[] = [];
  public definitions: Definition[] = [];
  public loading: boolean = false;
  public showArchive: boolean = false;
  public moduleOrCropGroupBreadcrumbName: string;
  public parents: any = [];

  public _archivesSub: Subscription;
  public archives: string[] = [];
  public selectedDefinitions: Definition[] = [];
  public selectedFolders: string[] = [];

  public moduleDetailsType: ModuleDetailsType;
  public moduleTemplateId: number;
  public moduleDefinitionId: number;
  public moduleTemplateName: string;
  public moduleDefinitions: Module[] = [];

  public pageIndex: number = 1;
  public isSingleClick: boolean = true;
  public allDefinitionsSelected: boolean = false;

  public definitionsBlacklist: string[] = [];
  public whitelist: number[] = [];
  public cropGroupBlacklist: number[] = [];

  public selectDefinitionMode: typeof SelectDefinitionConfig = SelectDefinitionConfig;
  public dashboardTileTypes: typeof DashboardTileTypes = DashboardTileTypes;
  public definitionTypes: typeof DefinitionType = DefinitionType;
  public details: any;
  public folderDetailsType: FoldersDetailsType;
  public folders: Folder[] = [];
  public parentId: number;
  public dashboardType: DashboardTypes;
  public dashboardCropGroupId: number;
  public dashboardModuleTemplateId: number;
  public dashboard: DashboardSelectionDashboardModel;

  public selectedDefinitionType: DefinitionType;
  public cropGroupDefinitionsBlacklist: string[] = ['dataentry', 'dashboard', 'cropmanager', 'url'];
  public folderFromCropGroupDefinitionBlacklist: string[] = ['plan', 'url', 'export', 'dataentry'];
  public cropGroupDefinitionFromFolderBlacklist: string[] = [
    'dashboard',
    'dataentry',
    'export',
    'folder',
    'gauge',
    'plan',
    'report',
    'url'];
  public folderDefinitionsBlacklist: string[] = ['dashboard', 'plan', 'url'];

  public isRtl: boolean = isRtl(this.locale);
  public folderArrow: string = this.isRtl ? 'fa-angle-left' : 'fa-angle-right';

  public dummyDef: Definition = {
    canBeMailed: false,
    id: 0,
    name: '',
    type: undefined,
    url: '',
    role: Role.viewer,
    hidden: false,
    path: '',
  };

  public newElementDef: Definition = {
    canBeMailed: false,
    id: -1,
    name: '',
    type: undefined,
    url: '',
    role: Role.viewer,
    hidden: false,
    path: '',
  };

  public ngOnInit(): void {
    this.dashboardType = this.store.selectSnapshot(DashboardState.dashboardType);
    switch (this.config) {
      case SelectDefinitionConfig.Folder:
        this.definitionsBlacklist = this.folderDefinitionsBlacklist;
        this.pageIndex = 1;
        break;
      case SelectDefinitionConfig.CropGroups:
        this.definitionsBlacklist = this.cropGroupDefinitionsBlacklist;
        this.pageIndex = 1;
        break;
      case SelectDefinitionConfig.Dashboard:
        if (this.tileType === DashboardTileTypes.shortcut) {
          this.pageIndex = 3;
        } else {
          this.showDefinitionSelection(this.definitionType);
        }
        break;
      default:
        // No action required
        break;
    }
  }

  public get showNewElementOption(): boolean {
    // option 'new' is only available when adding tile to dashboard
    if (this.config !== SelectDefinitionConfig.Dashboard) {
      return false;
    }

    // option 'new' is only available when adding specific tiles to dashboard
    if (this.tileType !== DashboardTileTypes.chart
      && this.tileType !== DashboardTileTypes.report
      && this.tileType !== DashboardTileTypes.gauge) {
      return false;
    }

    if (this.moduleDefinitionId || this.moduleTemplateId) {
      return false;
    } else if (this.cropGroupId) {
      const cropGroups: Map<string, CropGroupDetails> = this.store.selectSnapshot(CropGroupsState.cropGroup);
      const cropGroup: CropGroupDetails = cropGroups[this.cropGroupId];

      return cropGroup.role === Role.admin || cropGroup.role === Role.editor;
    }

    return true;
  }

  public showDefinitionSelection(definitionType: DefinitionType): void {
    this.selectedDefinitionType = definitionType;
    this.pageIndex = 1;
    this.definitionsBlacklist = ['graph',
      'gauge',
      'report',
      'plan',
      'dataentry',
      'cropmanager',
      'visualization',
      'cropgroupui',
      'dashboard',
      'announcement',
      'export',
      'url'];

    if (this.tileType === DashboardTileTypes.meteo) {
      this.whitelist = environment.allowedModuleTemplatesForMeteoTile;
    }
    const index: number = this.definitionsBlacklist.indexOf(definitionType.toLowerCase());
    this.definitionsBlacklist.splice(index, 1);
    this.dashboardCropGroupId = this.store.selectSnapshot(DashboardState.dashboardCropGroupId);
    this.dashboardModuleTemplateId = this.store.selectSnapshot(DashboardState.dashboardModuleTemplateId);
    this.dashboard = this.store.selectSnapshot(DashboardState.dashboard);
    if (this.dashboardCropGroupId > 0) {
      this.getCropGroups(this.dashboardCropGroupId, null, null);
    } else if (this.dashboardModuleTemplateId > 0) {
      this.getModules(this.dashboardModuleTemplateId, this.dashboard.moduleDefinitionId);
    } else {
      if (this.tileType === DashboardTileTypes.text) {
        this.cropGroupBlacklist = environment.disallowedCropGroupsForTextTile;
      }
    }
  }

  // eslint-disable-next-line max-lines-per-function
  public getCropGroups(cropGroupId: number, folderId: number, archiveYear: string | number): void {
    this.loading = true;

    let resetIncomingCropGroupIdForShortCutDashboardTileType: boolean = !this.cropGroupId;

    this.cropGroupId = cropGroupId;
    this.folderId = folderId;

    this.archiveYear = (typeof archiveYear === 'string') ? parseInt(archiveYear, 10) : archiveYear;

    this.moduleTemplateId = null;
    this.moduleDefinitionId = null;
    this.selectedDefinitions = [];
    this.selectedFolders = [];

    let action: GetCropGroup | GetCropGroupFolder;

    if (this.dashboardType === DashboardTypes.CropGroup && this.tileType === DashboardTileTypes.dashboard) {
      if (resetIncomingCropGroupIdForShortCutDashboardTileType) {
        //If the user wants to make a shortcut from a dashboard,
        //you want him to be able to select from other crop group dashboards as well.
        //To make this possible, the croup group id must be reset here,
        //so that the code must come into this flow `if (!cropGroupId && !folderId) {...}`.
        cropGroupId = undefined;
      }
    }

    if (this.config === SelectDefinitionConfig.Folder) {
      this.definitionsBlacklist = this.cropGroupDefinitionFromFolderBlacklist;
    }

    if (!cropGroupId && !folderId) {
      this.cropGroupDetailsType = 'root';
      action = new GetCropGroup(null, false, this.archiveYear);
      this.pageIndex = 4;
      this.cropGroups = [];
    } else if (cropGroupId && !folderId) {
      if (this.showModuleOrCropGroupSelection()) {
        this.loading = false;
        this.selectedDefinitions = [this.dummyDef];
        this.emitUpdate();

        return;
      } else {
        this.cropGroupDetailsType = 'cropgroup';
        action = new GetCropGroup(cropGroupId);
        this.pageIndex = 6;
        this.definitions = [];
        this.folders = [];
      }
    } else {
      if (this.showModuleOrCropGroupSelection()) {
        this.loading = false;
        this.selectedDefinitions = [this.dummyDef];
        this.emitUpdate();

        return;
      } else {
        this.cropGroupDetailsType = 'folder';
        action = new GetCropGroupFolder(cropGroupId, folderId);
        this.pageIndex = 6;
        this.definitions = [];
        this.folders = [];
      }
    }

    this.store.dispatch(action).subscribe(cropGroupDetails => {
      if (archiveYear) {
        this.cropGroups = cropGroupDetails.cropgroups.archive[archiveYear].cropGroups;
      } else {
        this.cropGroups = cropGroupDetails.cropgroups.root.cropGroups?.filter(item => !this.cropGroupBlacklist.includes(item.id));
      }

      const path = folderId ? cropGroupDetails.cropgroups.folder[`${ cropGroupId }_${ folderId }`]
        : cropGroupDetails.cropgroups.cropgroup[cropGroupId];

      this.definitions = path?.definitions?.filter(item => !this.definitionsBlacklist.includes(item.type));

      this.folders = path?.folders;
      this.showArchive = cropGroupDetails.cropgroups.root.hasArchive && !archiveYear;

      if (folderId) {
        this.folderName = path?.name;
      } else {
        this.cropGroupName = path?.name;
      }

      this.moduleOrCropGroupBreadcrumbName = path?.name;

      this.parents = path?.parents;

      // fake archive parents have to be created, since they are by default not part of the parents passed from backend
      if (!this.parents && this.archiveYear && !this.cropGroupId) {
        this.parents = [];
        this.parents.push(getCropGroupsParent());
        this.parents.push(getArchivesParent());
        this.moduleOrCropGroupBreadcrumbName = this.archiveYear.toString();
      } else if (!this.parents && !this.archiveYear && !this.cropGroupId) {
        this.moduleOrCropGroupBreadcrumbName = getCropGroupsParent()?.name;
      }

      if (this.dashboardCropGroupId > 0) {
        // In a crop group dashboard the user is only allowed to select definitions within that specific crop group.
        // Therefor it is not allowed to click the back button to select another crop group
        this.parents = this.parents?.filter(parent => parent.id === this.dashboardCropGroupId);
      } else {
        this.parents = [getRootParent(), ...this.parents ? this.parents : []];
      }

      this.loading = false;
      this.emitUpdate();
    });
  }

  public getCropGroupArchives(): any {
    const action: GetCropGroupArchives = new GetCropGroupArchives();
    const s = this.store.selectSnapshot(store => get(store, 'cropgroups.archives', []));
    let obs: Observable<any>;

    this.pageIndex = 5;
    this.selectedDefinitions = [];
    this.selectedFolders = [];
    this.archiveYear = null;

    this.loading = get(s, 'length', 0) === 0;
    if (this.loading) {
      obs = this.store.dispatch(action);
    } else {
      this.store.dispatch(action);
      obs = of({});
    }

    this._archivesSub = obs.pipe(
      switchMap(() => this.store.select(store => get(store, 'cropgroups.archives', [])))
    ).subscribe((archives: string[]) => {
      this.archives = archives;
      this.loading = false;

      this.parents = [];
      this.parents.push(getRootParent());
      this.parents.push(getCropGroupsParent());
      this.moduleOrCropGroupBreadcrumbName = getArchivesParent()?.name;

      this.emitUpdate();
    });
  }

  public getFolder(folderId: number): any {
    this.moduleTemplateId = null;
    this.moduleDefinitionId = null;

    // user selects a crop group folder from his folder.
    // It is therefore necessary to switch to the crop group flow
    if (this.cropGroupId && folderId) {
      this.getCropGroups(this.cropGroupId, folderId, this.archiveYear);

      return;
    }

    this.cropGroupId = null;
    this.loading = true;
    this.folderDetailsType = folderId ? 'folder' : 'root';
    this.folderId = folderId || null;
    this.selectedDefinitions = [];
    this.selectedFolders = [];

    // If user is selecting folder definitions to copy to crop group,
    // overwrite the config. This way we know we originally came from
    // crop groups but are now selecting personal definitions
    if (this.config === SelectDefinitionConfig.CropGroups) {
      this.config = SelectDefinitionConfig.FolderFromCropGroups;
      this.definitionsBlacklist = this.folderFromCropGroupDefinitionBlacklist;
    }

    this.store.dispatch(new GetFolder(this.folderId))
      .subscribe(folders => {
        const folderDetails = get(folders, ['folders', ...(this.folderId ? ['folder', this.folderId] : ['root'])]);
        this.details = folderDetails;
        this.folders = get(folderDetails, 'folders', []);
        //filter out the evaluation mode folders
        if (this.config === SelectDefinitionConfig.Dashboard || !get(folderDetails, 'isEvaluation')) {
          this.folders = this.folders.filter((folder: Folder) => !folder.onlyShowInEvaluationMode);
        }

        this.definitions = get(folderDetails, 'definitions', [])?.filter(item => !this.definitionsBlacklist.includes(item.type));
        this.parents = get(folderDetails, 'parents', []);
        if (this.parents.length === 0
          && (this.config === SelectDefinitionConfig.Dashboard
            || this.config === SelectDefinitionConfig.FolderFromCropGroups)) {
          this.parents = [getRootParent(), ...this.parents ? this.parents : []];
        }
        this.parentId = this.parents.length === 0 ? undefined : this.parents[this.parents.length - 1]?.id;
        this.folderId = folderId;
        this.loading = false;
        this.emitUpdate();
      });
    this.pageIndex = 6;
  }

  public getModules(moduleTemplateId: number, moduleDefinitionId?: number): void {
    this.moduleTemplateId = moduleTemplateId || null;
    this.moduleDefinitionId = moduleDefinitionId || null;
    this.cropGroupId = null;
    this.selectedDefinitions = [];
    this.selectedFolders = [];
    this.parents = [];

    if (moduleDefinitionId) {
      if (this.showModuleOrCropGroupSelection()) {
        this.selectedDefinitions = [this.dummyDef];
        this.emitUpdate();

        return;
      } else {
        this.loading = true;
        this.moduleDetailsType = 'module';
        this.pageIndex = 6;
      }
    } else if (moduleTemplateId) {
      this.loading = true;
      this.moduleDetailsType = 'moduletemplate';
      this.pageIndex = 7;
    } else {
      const folders: Folder[] = this.store.selectSnapshot(FoldersState.folders);
      this.loading = folders === undefined || folders.length === 0;
      this.moduleDetailsType = 'root';
      this.pageIndex = 2;
    }

    const entityId: number | null = moduleDefinitionId || moduleTemplateId || null;
    this.store.dispatch(new GetModuleEntityDetails(entityId, this.moduleDetailsType))
      .subscribe((moduleDetails) => {
        if (moduleTemplateId) {
          this.definitions = moduleDetails.modules?.moduletemplate[moduleTemplateId]?.definitions
            .filter(item => !this.definitionsBlacklist.includes(item.type));
          this.parents = moduleDetails.modules?.moduletemplate[moduleTemplateId]?.parents;
          this.moduleDefinitions = moduleDetails.modules?.moduletemplate[moduleTemplateId]?.modules;
          this.moduleTemplateName = moduleDetails.modules?.moduletemplate[moduleTemplateId]?.name;
          this.moduleOrCropGroupBreadcrumbName = moduleDetails.modules?.moduletemplate[moduleTemplateId]?.name;
        }

        if (moduleDefinitionId) {
          this.definitions = moduleDetails.modules?.module[moduleDefinitionId]?.definitions
            ?.filter(item => !this.definitionsBlacklist.includes(item.type));
          this.parents = moduleDetails.modules?.module[moduleDefinitionId]?.parents;
          this.moduleOrCropGroupBreadcrumbName = moduleDetails.modules?.module[moduleDefinitionId]?.name;
        }

        if (!moduleTemplateId && !moduleDefinitionId) {
          this.moduleOrCropGroupBreadcrumbName = getModulesParent().name;
        }

        if (this.dashboardModuleTemplateId > 0) {
          // On a module dashboard it is only allowed to add definitions from a specific module template.
          // This prevents the user from choosing a different module template to select definitions from.
          this.parents = this.parents.filter(parent => parent.id === this.dashboard.moduleDefinitionId);
        } else {
          this.parents = [getRootParent(), ...this.parents ? this.parents : []];
        }

        this.loading = false;
        this.emitUpdate();
      });
  }

  public addDefinitionToList(definition: Definition): void {
    // always remove the 'add new element' when selecting existing elements
    const newDefIndex: number = this.selectedDefinitions.indexOf(this.newElementDef);
    if (newDefIndex > -1) {
      this.selectedDefinitions.splice(newDefIndex, 1);
    }

    const index: number = this.selectedDefinitions.indexOf(definition);
    if (index > -1) {
      this.selectedDefinitions.splice(index, 1);
    } else {
      this.selectedDefinitions.push(definition);
    }

    const selectedCount: number = this.selectedDefinitions.length + this.selectedFolders.length;
    const totalCount: number = this.definitions.length + this.folders.length;
    this.allDefinitionsSelected = (selectedCount === totalCount);
    this.emitUpdate();
  }

  public addFolderToList(folderId: string): void {
    if (this.config === SelectDefinitionConfig.Dashboard) {
      this.selectedFolders = [];
      this.getFolderDefinitions(null, folderId, null);
    } else {
      const index: number = this.selectedFolders.indexOf(folderId);
      if (index > -1) {
        this.selectedFolders.splice(index, 1);
      } else {
        this.selectedFolders.push(folderId);
      }

      const selectedCount: number = this.selectedDefinitions.length + this.selectedFolders.length;
      const totalCount: number = this.definitions.length + this.folders.length;
      this.allDefinitionsSelected = (selectedCount === totalCount);
    }
    this.emitUpdate();
  }

  public selectAll(selectAll: boolean): void {
    this.selectedDefinitions = [];
    this.selectedFolders = [];
    if (selectAll) {
      this.definitions.forEach(definition => {
        this.selectedDefinitions.push(definition);
      });
      this.folders.forEach(folder => {
        this.selectedFolders.push(folder.id.toString());
      });
    }
    this.allDefinitionsSelected = selectAll;
    this.emitUpdate();
  }

  public navigateTo(id: any, parentType: string, archiveYear: string | number): void {
    // @ts-ignore
    const rootPath: string = 'root';
    if (id !== undefined) {
      switch (this.config) {
        case SelectDefinitionConfig.Folder:
          this.goToRoot();
          break;
        case SelectDefinitionConfig.FolderFromCropGroups:
          if (parentType === rootPath) {
            this.config = SelectDefinitionConfig.CropGroups;
            this.definitionsBlacklist = this.cropGroupDefinitionsBlacklist;
            this.goToRoot();
          } else {
            this.getFolder(id);
          }
          break;
        case SelectDefinitionConfig.CropGroups:
          switch (parentType) {
            case 'cropGroup': {
              this.getCropGroups(id, null, archiveYear);
              break;
            }
            case 'folder': {
              this.getCropGroups(null, id, archiveYear);
              break;
            }
            case 'archive': {
              this.getCropGroupArchives();
              break;
            }
            case 'archiveYear': {
              this.getCropGroups(null, null, archiveYear);
              break;
            }
            case 'module': {
              this.getModules(id);
              break;
            }
            case rootPath: {
              this.goToRoot();
              break;
            }
            default: {
              this.getCropGroups(null, null, null);
              break;
            }
          }
          break;
        case SelectDefinitionConfig.Dashboard:
          if (parentType === rootPath) {
            this.goToRoot();
          } else if (this.cropGroupId !== null) {
            this.getCropGroups(id, undefined, undefined);
          } else if (this.moduleTemplateId !== null) {
            this.getModules(id);
          } else {
            this.getFolder(id);
          }
          break;
        default:
          // No action required
          break;
      }
    }
  }

  public addFolderToDefinitionsList(cropGroupId, folderId, archiveYear): void {
    if (this.config === this.selectDefinitionMode.Dashboard) {
      this.getFolderDefinitions(cropGroupId, folderId, archiveYear);
      this.emitUpdate();
    } else {
      this.isSingleClick = true;
      setTimeout(() => {
        if (this.isSingleClick) {
          this.addFolderToList(folderId.toString());
        }
      }, 250);
    }
  }

  public createNewElement(): void {
    const index: number = this.selectedDefinitions.indexOf(this.newElementDef);
    this.selectedDefinitions = [];
    if (index === -1) {
      this.newElementDef.type = this.selectedDefinitionType;
      this.selectedDefinitions.push(this.newElementDef);
    }

    this.allDefinitionsSelected = false;
    this.emitUpdate();
  }

  public getFolderDefinitions(cropGroupId, folderId, archiveYear): void {
    this.isSingleClick = false;
    switch (this.config) {
      case SelectDefinitionConfig.Folder:
      case SelectDefinitionConfig.FolderFromCropGroups:
        this.getFolder(folderId);
        break;
      case SelectDefinitionConfig.CropGroups:
        this.getCropGroups(cropGroupId, folderId, archiveYear);
        break;
      default:
        if (!cropGroupId && !archiveYear && folderId) {
          this.getFolder(folderId);
        } else {
          this.getCropGroups(cropGroupId, folderId, archiveYear);
        }
        break;
    }
  }

  public get showCropGroups(): boolean {
    switch (this.config) {
      case this.selectDefinitionMode.CropGroups:
        return true;
      case this.selectDefinitionMode.Dashboard:
        if (this.dashboardType === DashboardTypes.Personal) {
          return !(this.tileType === DashboardTileTypes.plan || this.tileType === DashboardTileTypes.meteo);
        }

        if (this.dashboardType === DashboardTypes.CropGroup) {
          return true;
        }

        return false;
      case this.selectDefinitionMode.Folder:
        return true;
      default:
        return false;
    }
  }

  public get showModules(): boolean {
    switch (this.config) {
      case this.selectDefinitionMode.CropGroups:
        return true;
      case this.selectDefinitionMode.Dashboard:
        if (this.dashboardType === DashboardTypes.Personal) {

          return !(this.tileType === DashboardTileTypes.plan || this.tileType === DashboardTileTypes.text);
        } else if (this.dashboardType === DashboardTypes.Module) {
          return true;
        }

        return false;
      default:
        return false;
    }
  }

  public get showFolders(): boolean {
    if (this.config === this.selectDefinitionMode.Dashboard) {
      return !this.showModuleOrCropGroupSelection();
    }

    return true;
  }

  public get showSelectAll(): boolean {
    if (this.config === this.selectDefinitionMode.Dashboard) {
      return false;
    }
    if (this.loading) {
      return false;
    }
    if (this.pageIndex === 6) {
      return false;
    }
    if (!this.folders || this.folders.length === 0 && !this.definitions || this.definitions.length === 0) {
      return false;
    }
    if (!this.folders || this.folders.length === 0) {
      return false;
    }

    return true;
  }

  public goToRoot(): void {
    this.pageIndex = 1;
    this.selectedDefinitions = [];
    this.selectedFolders = [];
    this.definitions = [];
    // CropGroup properties reset
    this.archiveYear = null;
    this.cropGroupId = null;
    this.cropGroupName = null;
    this.folderName = null;
    this.cropGroups = [];
    this.folders = [];
    this.parents = [];
    // Module properties reset
    this.moduleTemplateId = null;
    this.moduleDefinitionId = null;
    this.moduleTemplateName = null;
    this.moduleOrCropGroupBreadcrumbName = null;
    this.emitUpdate();
  }

  public emitUpdate(): void {
    this.changed.emit({
      folderId: this.folderId,
      selectedDefinitions: this.selectedDefinitions,
      selectedFolders: this.selectedFolders,
      cropGroupId: this.cropGroupId,
      moduleTemplateId: this.moduleTemplateId,
      moduleDefinitionId: this.moduleDefinitionId,
      parentId: this.parentId,
      parents: this.parents,
      archiveYear: this.archiveYear,
      selectedDefinitionType: this.selectedDefinitionType,
    });
  }

  public showModuleOrCropGroupSelection(): boolean {
    // For specific tile types it is not needed to select a specific definition, since this does not exist
    // for these types. In this case it is enough to select a specific module definition or crop group
    let showModuleOrCropGroupSelection: boolean = this.tileType === DashboardTileTypes.media
      || this.tileType === DashboardTileTypes.meteo;

    if (this.dashboardType === DashboardTypes.Module) {
      return showModuleOrCropGroupSelection;
    }

    showModuleOrCropGroupSelection = showModuleOrCropGroupSelection || this.tileType === DashboardTileTypes.text;
    if (this.dashboardType === DashboardTypes.Personal) {
      return showModuleOrCropGroupSelection;
    }

    showModuleOrCropGroupSelection = showModuleOrCropGroupSelection || this.tileType === DashboardTileTypes.plan;
    if (this.dashboardType === DashboardTypes.CropGroup) {
      return showModuleOrCropGroupSelection;
    }

    return false;
  }
}
