import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@LIB_UTIL/services/config/config.service';
import { Observable } from 'rxjs';
import { KpiDefinitionModel } from '@LIB_UTIL/kpi/model/kpi.model';

@Injectable({
  providedIn: 'root',
})
export class KpiApiService {

  constructor(private http: HttpClient, private cfg: ConfigService) {
  }

  /**
   * Retrieves for the current user all kpis
   */
  public getKpis(): Observable<KpiDefinitionModel[]> {
    const url: string = `${ this.cfg.endpoints.kpis }`;

    return this.http.get<KpiDefinitionModel[]>(url);
  }

  public addKpi(kpiDefinitionConfigurationModel: KpiDefinitionModel): Observable<KpiDefinitionModel> {
    const url: string = `${ this.cfg.endpoints.kpis }`;

    return this.http.post<KpiDefinitionModel>(url, kpiDefinitionConfigurationModel);
  }

  public copyKpis(kpiDefinitionIds: number[]): Observable<KpiDefinitionModel[]> {
    const url: string = `${ this.cfg.endpoints.kpis }/copy`;

    return this.http.post<KpiDefinitionModel[]>(url, kpiDefinitionIds);
  }

  public renameKpi(kpiDefinitionId: number, name: string): Observable<boolean> {
    const url: string = `${ this.cfg.endpoints.kpis }/${ kpiDefinitionId }/name`;

    return this.http.patch<boolean>(url, { name: name });
  }

  public deleteKpi(kpiDefinitionId: number): Observable<boolean> {
    const url: string = `${ this.cfg.endpoints.kpis }/${ kpiDefinitionId }`;

    return this.http.delete<boolean>(url);
  }
}
