
export interface TimelapseModel {
  displayControls: boolean;
  timestamp: number; // Unix timestamp.
  stepSize: number;
  unit: TimelapseUnits;
  speedInSeconds: number;
}

export enum TimelapseUnits {
  minute = 0,
  hour = 1,
  week = 2,
}
