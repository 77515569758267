import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Definition, SelectDefinitionConfig } from '@LG_ROOT/app/core/page-title/shared.model';
import { SelectDefinitionComponent, SelectDefinitionModel } from '@LG_SHARED/components/select-definition/select-definition.component';

@Component({
  selector: 'app-select-existing-entity-modal',
  templateUrl: './select-existing-entity-modal.component.html',
})

export class SelectExistingEntityModalComponent {

  @ViewChild(SelectDefinitionComponent) public selectDefinitionComponent: SelectDefinitionComponent;

  public cropGroupId: number;
  public moduleTemplateId: number;
  public selectedDefinitions: Definition[] = [];
  public selectedFolders: string[] = [];
  public selectDefinitionConfig: typeof SelectDefinitionConfig = SelectDefinitionConfig;
  public parents: SelectDefinitionModel['parents'] = [];
  public archiveYear: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { blacklist: string[] },
    private dialogRef: MatDialogRef<SelectExistingEntityModalComponent>
  ) {}

  public copy(): void {
    const moduleTemplateId: number = (this.moduleTemplateId > 0) ? this.moduleTemplateId : 0;
    const cropGroupId: number = (this.cropGroupId > 0) ? this.cropGroupId : 0;
    const selectedDefinitionIds: number[] = this.selectedDefinitions.map((definition: Definition) => definition.id);

    this.dialogRef.close({
      fromCropGroupId: cropGroupId,
      fromModuleTemplateId: moduleTemplateId,
      definitionIds: selectedDefinitionIds,
      folderIds: this.selectedFolders,
    });
  }

  public onSelectDefinitionChanged(data: SelectDefinitionModel): void {
    this.cropGroupId = data.cropGroupId;
    this.moduleTemplateId = data.moduleTemplateId;
    this.parents = data.parents;
    this.archiveYear = data.archiveYear;
    this.selectedDefinitions = data.selectedDefinitions;
    this.selectedFolders = data.selectedFolders;
  }

  /**
   * handler for the 'back' button
   */
  public onStepBack(): void {
    this.selectDefinitionComponent.navigateTo(
      this.parents[this.parents.length - 1]['id'],
      this.parents[this.parents.length - 1]['type'],
      this.archiveYear
    );
  }
}
