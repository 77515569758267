import { Component, HostBinding, Inject, Input, LOCALE_ID, OnDestroy, OnInit, inject } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { LayoutState } from '@LIB_UTIL/layout/state/layout.state';
import { ToggleNavigationExpanded } from '@LIB_UTIL/layout/state/layout.actions';
import { serialUnsubscriber } from '@LIB_UTIL/util/rx';
import { UserInfo } from '@LIB_UTIL/auth/model/auth.model';
import { AuthState } from '@LIB_UTIL/auth/state/auth.state';
import { LocaleID } from '@LIB_UTIL/util/locale';
import { map } from 'rxjs/operators';
import { environment } from '@LIB_UTIL/environments/environment';
import { copyUrlWithSidToClipboard, removeSidFromCurrentUrl } from '@LIB_UTIL/util/navigation';
import { AuthService } from '@LIB_UTIL/auth/services/auth.service';
import { SnackbarService } from '@LIB_UTIL/services/snackbar.service';
import { ModalService } from '@LIB_UTIL/services/modal.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare function initializeZohoAsapComponent(locale: string, descriptionTemplate: string): any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare function openZohoAsap(): any;

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  private store: Store = inject(Store);
  private authService: AuthService = inject(AuthService);
  private snackbarService: SnackbarService = inject(SnackbarService);
  private modalService: ModalService = inject(ModalService);
  @Inject(LOCALE_ID) private locale: LocaleID = inject(LOCALE_ID) as LocaleID;


  /**
   * Flag to determine whether we want to show the user's avatar.
   */
  @Input() public hideProfileMenu: boolean = false;

  /**
   * Flag to determine whether to show the mobile menu toggle.
   */
  @Input() public showMobileMenuToggle: boolean = true;

  /**
   * Flag to determin if this header should show a profile menu with settings or without settings
   */
  @Input() public showSettingsLink: boolean = true;

  /**
   * The profile of the logged-in user.
   */
  public userInfo$: Observable<UserInfo> = this.store.select(AuthState.userInfo);

  /**
   * The full screen mode from the app.
   */
  public isFullScreen$: Observable<boolean> = this.store.select(LayoutState.isFullScreen);

  /**
   * The css class of the host element.
   */
  @HostBinding('class.full-screen') public isFullScreen: boolean;

  public showSupportButton$: Observable<boolean>;


  /**
   * Keep track of our subscriptions.
   */
  private subs: Record<string, Subscription> = {};

  private serviceDeskInfo: string = 'Service desk information';
  private descriptionTemplate: string = `<br/><br/><br/><br/><br/><b>${ this.serviceDeskInfo }:</b><br/>`;

  /**
   * Add a shortcut to copy the url including the sid to clipboard.
   */
  public onMouseUp(event: MouseEvent): void {
    copyUrlWithSidToClipboard(event, this.authService, this.snackbarService, this.modalService);
  }

  /**
   * Set the css class of the host element when the app is in full screen mode.
   */
  public ngOnInit(): void {
    this.subs['isFullScreen'] = this.isFullScreen$.subscribe(fullScreen => this.isFullScreen = fullScreen);

    this.showSupportButton$ = this.userInfo$.pipe(
      map((userInfo: UserInfo) => environment.showSupportButtonForBrands
        .toLowerCase()
        .split(',')
        .includes(userInfo?.brandName?.toLowerCase())
      )
    );
  }

  /**
   * Cleanup the subscription.
   */
  public ngOnDestroy(): void {
    serialUnsubscriber(...Object.values(this.subs));
  }

  /**
   * Toggle the menu on mobile.
   */
  public toggleNavigation(): void {
    this.store.dispatch(new ToggleNavigationExpanded());
  }

  public toggleZoho(): void {

    const applicationContext: string = this.store.selectSnapshot(LayoutState.applicationContext);
    const userInfo: UserInfo = this.store.selectSnapshot(AuthState.userInfo);

    let newApplicationContext: string = `${ this.descriptionTemplate } LG display name and username:
      ${ userInfo.displayName } - ${ userInfo.userName }`;

    if (applicationContext) {
      newApplicationContext = `${ newApplicationContext }<br/>${ applicationContext }`;
    }

    newApplicationContext = `${ newApplicationContext }<br/> URL: ${ removeSidFromCurrentUrl() }`;

    initializeZohoAsapComponent(this.locale, newApplicationContext);
    openZohoAsap();
  }

}
