import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { isSameDay, parseISO } from 'date-fns';
import { PeriodModel, PeriodType } from '@LIB_UTIL/model/period.model';
import { serialUnsubscriber } from '@LIB_UTIL/util/rx';
import { LocaleID } from '@LIB_UTIL/util/locale';
import { getPeriodDate } from '@LIB_UTIL/util/period';
import { formatDateToApiFormat, formatDateToPeriodFormat } from '@LIB_UTIL/util/date-formatting';
import { formatDateToTime } from '@LIB_UTIL/util/date-formatting/date-formatting';

import dayjs, { Dayjs } from 'dayjs';
// looks like  unused import but used for getting week/month names
import * as localeData from 'dayjs/plugin/localeData';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as week from 'dayjs/plugin/weekOfYear';
import { DaterangepickerComponent, LocaleConfig } from '@LIB_UTIL/ngx-daterangepicker';
import { LayoutState } from '@LIB_UTIL/layout/state/layout.state';
import { Store } from '@ngxs/store';
import { removeTime } from '@LIB_UTIL/util/date';
import { MixPanelService } from '@LIB_UTIL/mixpanel/mixpanel.service';

dayjs.extend(localeData);
dayjs.extend(week);
dayjs.extend(isoWeek);


interface DateRangeFormValues {
  periodType: PeriodType;
  pinnedPeriodType: PeriodType;
  startDate: Dayjs;
  endDate: Dayjs;
  showWholeDays: boolean;
}

interface ParseDateRangeFormValues {
  periodType: PeriodType;
  pinnedPeriodType: PeriodType;
  startDate: string;
  endDate: string;
  showWholeDays?: boolean;
}

type SelectedDate = { startDate: Dayjs }|{ endDate: Dayjs };

@Component({
  selector: 'lib-date-range-picker-form',
  templateUrl: './date-range-picker-form.component.html',
  styleUrls: ['./date-range-picker-form.component.scss'],
})
export class DateRangePickerFormComponent implements OnInit, OnDestroy {

  private formBuilder: UntypedFormBuilder = inject(UntypedFormBuilder);
  private changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
  private store: Store = inject(Store);
  private locale: LocaleID = inject(LOCALE_ID) as LocaleID;
  public mixPanelService: MixPanelService = inject(MixPanelService);

  public screenWidth$: Observable<number> = this.store.select(LayoutState.screenWidth);

  /**
   * The ngx-daterangepicker-material in the template.
   */
  @ViewChild(DaterangepickerComponent) public picker: DaterangepickerComponent;
  @ViewChild('startPicker') public startPicker: DaterangepickerComponent;
  @ViewChild('endPicker') public endPicker: DaterangepickerComponent;

  /**
   * Date range passed from the parent.
   */
  @Input() public set period(period: PeriodModel) {
    this.initialPeriod = period;
    this.initializeForm(period);
  }

  /**
   * Option to enable/disable the feature to pin
   * the date.
   */
  @Input() public enablePin: boolean = false;

  /**
   * Option to show or hide the period selector.
   */
  @Input() public showPeriodSelector: boolean = true;

  /**
   * Option to show or hide the time selector;
   */
  @Input() public showTimeSelector: boolean = true;

  /**
   * Option to show only a single date picker
   */
  @Input() public singleDate: boolean = false;

  /**
   * Minimal date (optional)
   */
  @Input() public minDate: Date | null = null;

  /**
   * Maximal date (optional)
   */
  @Input() public maxDate: Date | null = null;

  /**
   * Emit the event when the user pins a date.
   */
  @Output() public readonly pin: EventEmitter<PeriodModel> = new EventEmitter<PeriodModel>();

  /**
   * Emit the event when the user wants to close the overlay.
   */
  @Output() public readonly cancel: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Emit the event when the user submits the form.
   */
  @Output() public readonly submit: EventEmitter<PeriodModel> = new EventEmitter<PeriodModel>();

  public showMobileCalendar: boolean = false;

  public refresh: boolean = false;

  /**
   * Build the date range form.
   */
  public form: FormGroup = this.formBuilder.group({
    periodType: new FormControl<PeriodType>(null),
    pinnedPeriodType: new FormControl<PeriodType>(null),
    startDate: new FormControl<Dayjs>(null, [Validators.required]),
    endDate: new FormControl<Dayjs>(null, [Validators.required]),
    showWholeDays: new FormControl<boolean>(false),
  });

  private initialPeriod: PeriodModel;

  /**
   * Expose the PeriodType enum to the template.
   */
  public periodType: typeof PeriodType = PeriodType;

  public maxStartDate: Dayjs;
  public minEndDate: Dayjs;

  /**
   * Locale configuration for the datepicker.
   * (any is the type defined by the ngx-range-picker library.
   */
  public datepickerLocale: LocaleConfig = {
    daysOfWeek: dayjs().locale(this.locale).localeData().weekdaysMin(),
    monthNames: dayjs().locale(this.locale).localeData().monthsShort(),
    firstDay: 1,
  };

  public toDayJs(date: Date): Dayjs | null {
    return date ? dayjs(date) : null;
  }

  /**
   * Keep track of our subscriptions.
   */
  private subs: Record<string, Subscription> = {};

  /**
   * Convert the dayjs object in the form to a string.
   *
   * @example
   * 05/10/2021
   */
  public get startDate(): string {
    if (this.form.get('startDate').value) {
      return formatDateToPeriodFormat(this.form.get('startDate').value, this.locale);
    }
  }

  public get startTime(): string {
    if (this.form.get('showWholeDays').value) {
      return this.startOfDay;
    }

    if (this.form.get('startDate').value) {
      return formatDateToTime(this.form.get('startDate').value, this.locale);
    }

    return '';
  }

  public get startOfDay(): string {
    let now: Dayjs = dayjs();
    now = now.set('hour', 0);
    now = now.set('minute', 0);

    return formatDateToTime(now, this.locale);
  }

  public get endOfDay(): string {
    let now: Dayjs = dayjs();
    now = now.set('hour', 23);
    now = now.set('minute', 55);

    return formatDateToTime(now, this.locale);
  }

  /**
   * Convert the dayjs object in the form to a string.
   *
   * @example
   * 05/10/2021
   */
  public get endDate(): string {
    if (this.form.get('endDate').value) {
      return formatDateToPeriodFormat(this.form.get('endDate').value, this.locale);
    }
  }

  public get endTime(): string {
    if (this.form.get('showWholeDays').value) {
      return this.endOfDay;
    }
    if (this.form.get('endDate').value) {
      return formatDateToTime(this.form.get('endDate').value, this.locale);
    }
  }

  /**
   * Parse the dayjs objects to the api format.
   */
  public get formValues(): ParseDateRangeFormValues {
    let { showWholeDays, periodType, startDate, endDate }: DateRangeFormValues = this.form.value;

    // adjust the start/end time if the user selected 'show Whole Days'.
    if (showWholeDays && periodType === PeriodType.Custom) {
      startDate = startDate.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
      endDate = endDate.set('hour', 23).set('minute', 55).set('second', 0).set('millisecond', 0);
    }

    return {
      ...this.form.value,
      startDate: formatDateToApiFormat(startDate),
      endDate: formatDateToApiFormat(endDate),
    };
  }

  /**
   * When the user selects a period type preset from the dropdown
   * update the data-range in the calendars.
   */
  public ngOnInit(): void {
    this.subs['form'] = this.form.get('periodType').valueChanges
      .subscribe((period: PeriodType) => this.onPeriodTypePresetChanges(period));

    // the min-width of the form is 870 pixels, so we need to check for that,
    // otherwise we show the mobile version, that only includes one calendar
    this.subs['screenwidth'] = this.screenWidth$.subscribe((width: number) =>
      this.showMobileCalendar = width <= 1024
    );
  }

  /**
   * Destroy the subscriptions.
   */
  public ngOnDestroy(): void {
    serialUnsubscriber(...Object.values(this.subs));
  }

  /**
   * When the user selects a period type from the select box,
   * Update the values in de form and sync the calendar picker.
   */
  private onPeriodTypePresetChanges(periodType: PeriodType): void {
    const startDate: Dayjs = dayjs(getPeriodDate(periodType));
    const endDate: Dayjs = dayjs();

    let eventPeriodType: string;
    switch (periodType) {
      case PeriodType.Last24Hours:
        eventPeriodType = 'Last24Hours';
        break;

      case PeriodType.LastWeek:
        eventPeriodType = 'LastWeek';
        break;

      case PeriodType.Last4Weeks:
        eventPeriodType = 'Last4Weeks';
        break;

      case PeriodType.LastHalfYear:
        eventPeriodType = 'LastHalfYear';
        break;

      case PeriodType.LastYear:
        eventPeriodType = 'LastYear';
        break;

      case PeriodType.Custom:
        eventPeriodType = 'Custom';
        break;
    }
    this.mixPanelService.pushEventsToMixPanel('period_selected', eventPeriodType);

    // Update calendars
    // let onSelectDate handle setting the correct form value.
    if (this.picker) {
      this.picker.setStartDate(startDate);
      this.picker.setEndDate(endDate);
      this.picker.updateCalendars();
      this.picker.updateView();
    }

    // Set the correct periodType since
    // onSelectDate set the type to custom.
    this.form.patchValue({
      periodType: periodType,
    }, { emitEvent: false });

    // When default range is selected, automatically apply choice
    if (periodType !== PeriodType.Custom) {
      this.emitSubmit();
    }
  }

  /**
   * When the user selected the date range manually.
   */
  public onSelectDate(emittedDate: Object): void {
    const value: SelectedDate = emittedDate as SelectedDate; // Actual emitted type.

    this.form.patchValue({
      ...this.dateHasChanged(value) && { periodType: PeriodType.Custom },
      ...value,
    }, {
      emitEvent: false,
      onlySelf: true,
    });
  }

  public onSelectStartDate(emittedDate: Object): void {

    const value: SelectedDate = emittedDate as SelectedDate; // Actual emitted type.

    this.form.patchValue({
      ...this.dateHasChanged(value) && { periodType: PeriodType.Custom },
      ...value,
    }, {
      emitEvent: false,
      onlySelf: true,
    });

    this.setMinEndDate(value['startDate']);
  }

  public onSelectEndDate(emittedDate: Object): void {

    const value: SelectedDate = emittedDate as SelectedDate; // Actual emitted type.

    this.form.patchValue({
      ...this.dateHasChanged(value) && { periodType: PeriodType.Custom },
      endDate: value['startDate'],
    }, {
      emitEvent: false,
      onlySelf: true,
    });

    this.setMaxStartDate(value['startDate']);
  }


  /**
   * Determine whether the data is different from the initial dashboard period.
   */
  private dateHasChanged(selectedDate: SelectedDate): boolean {
    if ('startDate' in selectedDate) {
      return !isSameDay(selectedDate.startDate.toDate(), parseISO(this.initialPeriod.startDate));
    }
    if ('endDate' in selectedDate) {
      return !isSameDay(selectedDate.endDate.toDate(), parseISO(this.initialPeriod.endDate));
    }
  }

  /**
   * Fill the angular form, that is used in the background.
   */
  private initializeForm(period: PeriodModel): void {
    const { periodType, pinnedPeriodType, startDate, endDate }: PeriodModel = period;

    const start: Dayjs = startDate
      ? dayjs(startDate)
      : dayjs(getPeriodDate(periodType));

    const end: Dayjs = endDate
      ? dayjs(endDate)
      : dayjs();

    const showWholeDays: boolean
      = start.hour() === 0
      && start.minute() === 0
      && end.hour() === 23
      && end.minute() >= 55;

    // Set initial form fields
    this.form.setValue({
      periodType: periodType === PeriodType.None
        ? PeriodType.LastWeek
        : periodType,
      pinnedPeriodType: pinnedPeriodType || null,
      startDate: start,
      endDate: end,
      showWholeDays: showWholeDays,
    });

    this.setMaxStartDate(end);
    this.setMinEndDate(start);
  }

  /**
   * When the "Pin period" button is clicked,
   * emit the current value form value.
   */
  public emitPin(): void {
    const { periodType, startDate, endDate }: ParseDateRangeFormValues = this.formValues;

    const period: PeriodModel = {
      startDate: formatDateToApiFormat(new Date(startDate)),
      endDate: formatDateToApiFormat(new Date(endDate)),
      periodType: periodType,
      pinnedPeriodType: periodType,
    };

    this.form.patchValue({
      pinnedPeriodType: periodType,
    });

    // Save the pinned date in the backend.
    this.pin.emit(period);
  }

  /**
   * Reset the pinned date in the backend.
   */
  public emitPinReset(): void {

    this.form.patchValue({
      pinnedPeriodType: null,
    });

    this.pin.emit({
      periodType: 0,
      pinnedPeriodType: 0,
    });
  }

  /**
   * Emit the selected date range.
   */
  public emitSubmit(): void {
    const values: ParseDateRangeFormValues = this.formValues;

    const period: PeriodModel = {
      ...values,
      startDate: formatDateToApiFormat(new Date(values.startDate)),
      endDate: formatDateToApiFormat(new Date(values.endDate)),
    };

    // Close and destroy overlay
    this.submit.emit(period);
  }

  /**
   * Close and destroy the overlay.
   */
  public emitCancel(): void {
    this.cancel.emit();
  }

  public setMaxStartDate(endDate: Dayjs): void {
    const originalMax: Dayjs = this.toDayJs(this.maxDate);


    this.maxStartDate = (!originalMax || originalMax > endDate )
      ? endDate
      : originalMax;

    if (this.startPicker) {
      this.startPicker.maxDate = this.maxStartDate;
      this.startPicker.hide();
      this.startPicker.show();
    }

  }

  public setMinEndDate(startDate: Dayjs): void {
    const originalMin: Dayjs = this.toDayJs(this.minDate);

    this.minEndDate = !originalMin || originalMin > startDate
      ? startDate
      : originalMin;


    if (this.endPicker) {
      this.endPicker.minDate = this.minEndDate;
      this.endPicker.hide();
      this.endPicker.show();
      this.changeDetector.detectChanges();
    }
  }

  public get showEndBeforeStartError(): boolean {
    if (this.showMobileCalendar) {
      return false;
    }

    if (this.singleDate) {
      return false;
    }

    const start: Dayjs = this.form.get('startDate').value;
    const end: Dayjs = this.form.get('endDate').value;

    if (this.showTimeSelector) {
      if (start > end) {
        return true;
      }
    } else {
      if (removeTime(start) > removeTime(end)) {
        return true;
      }
    }

    return false;
  }

  public get showOutOfRangeError(): boolean {
    const start: Dayjs = this.form.get('startDate').value;
    const end: Dayjs = this.form.get('endDate').value;

    if (this.showMobileCalendar) {
      return false;
    }

    if (this.isBeforeMinDate(start) || (!this.singleDate && this.isBeforeMinDate(end))) {
      return true;
    }

    if (this.isAfterMaxDate(start) || (!this.singleDate && this.isAfterMaxDate(end))) {
      return true;
    }

    return false;
  }

  private isBeforeMinDate(date: Dayjs): boolean {
    if (!this.minDate) {
      return false;
    }

    const minDate: Dayjs = dayjs(this.minDate);

    if (this.showTimeSelector) {
      if (date < minDate) {
        return true;
      }
    } else {
      if (removeTime(date) < removeTime(minDate)) {
        return true;
      }
    }

    return false;
  }

  private isAfterMaxDate(date: Dayjs): boolean {
    if (!this.maxDate) {
      return false;
    }

    const maxDate: Dayjs = dayjs(this.maxDate);

    if (this.showTimeSelector) {
      if (date > maxDate) {
        return true;
      }
    } else {
      if (removeTime(date) > removeTime(maxDate)) {
        return true;
      }
    }

    return false;
  }

  public sendMixPanelEvent(component: string): void {

    switch (component) {

      case 'showWholeDays':
        this.mixPanelService.pushEventsToMixPanel('show_whole_days_box_selected',`${this.form.get('showWholeDays').value}`);

      case 'pinPeriod':
      case 'unPinPeriod':
        this.mixPanelService.pushEventsToMixPanel('pin_period_clicked',`${component}`);
    }
  }
}
