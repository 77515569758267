<div class="modal-header" mat-dialog-title>
  <h2 i18n>Which element(s) would you like to copy to this folder?</h2>
</div>
<div class="modal-content" mat-dialog-content>
  <app-select-definition (changed)="onSelectDefinitionChanged($event)"
                         [config]="selectDefinitionConfig.Folder"></app-select-definition>
</div>
<div class="modal-footer" mat-dialog-actions>
  <button *ngIf="this.parents?.length > 0"
          mat-button
          class="with-mat-icon"
          (click)="this.selectDefinitionComponent.navigateTo(this.parents[parents.length - 1].id,null,null)">
    <mat-icon fontSet="fas" fontIcon="fa-angle-left"></mat-icon>
    <ng-container i18n="@@back">Back</ng-container>
  </button>
  <div class="u-flex-divider"></div>
  <button mat-flat-button mat-dialog-close i18n="@@common.close" class="close">Close</button>
  <button mat-flat-button
          color="accent"
          cdkFocusInitial
          [mat-dialog-close]="true"
          (click)="copy()"
          [disabled]="!selectedDefinitions.length && !selectedFolders.length">
    <ng-container i18n="@@common.copy">Copy</ng-container>
  </button>
</div>

