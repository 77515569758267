import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { get } from 'lodash';
import { ModulesApiService } from '@LG_CORE/modules/modules-api.service';
import {
  DeleteInstanceGroup,
  GetAvailableDefinitionTypesInModule,
  GetModuleEntityDetails,
  GetInstanceGroups,
  RenameInstanceGroup,
  GetInstancesAction,
  CreateInstanceAction,
  UpdateInstancesAction,
  CreateInstanceGroup
} from '@LG_ROOT/app/state/modules/modules.actions';
import {
  ModuleInstance,
  ModuleDetails,
  ModuleTemplate,
  ModuleTemplateDetails,
  InstanceGroup
} from '@LG_CORE/modules/modules.model';
import { Injectable } from '@angular/core';
import { ModulesStateModel } from '@LG_ROOT/app/state/modules/modules.state.model';
import { Observable } from 'rxjs';
import { DashboardTileTypes } from '@LG_CORE/dashboards/dashboards.model';
import { getBackendErrorMessage } from '@LIB_UTIL/util/errorhandling';

@State<ModulesStateModel>({
  name: 'modules',
  defaults: {
    root: null,
    moduletemplate: {},
    module: {},
    availableDefinitions: [],
    instances: [],
  },
})
@Injectable()
export class ModulesState {

  @Selector()
  public static moduleTemplate(state: ModulesStateModel): Record<string, ModuleTemplateDetails> {
    return state.moduletemplate;
  }

  @Selector()
  public static moduleTemplates(state: ModulesStateModel): ModuleTemplate[] {
    return state.root.moduleTemplates;
  }

  @Selector()
  public static modules(state: ModulesStateModel): Record<string, ModuleDetails> {
    return state.module;
  }

  @Selector()
  public static module(state: ModulesStateModel): (id: string) => ModuleDetails {
    return (id: string): ModuleDetails => state.module[id] ?? null;
  }

  @Selector()
  public static instances(state: ModulesStateModel): ModuleInstance[] {
    return state.instances;
  }

  constructor(
    private modulesApiService: ModulesApiService
  ) { }

  /*
   * ACTIONS
   */
  @Action(GetModuleEntityDetails)
  public getModules(
    ctx: StateContext<ModulesStateModel>,
    { entityId, type, getArchived }: GetModuleEntityDetails
  ): Observable<Object> {
    return this.modulesApiService.getModuleDetails(entityId, type, getArchived)
      .pipe(tap((details: any) => {
        switch (type) {
          case 'root':
            ctx.patchState({ root: details });
            break;
          case 'moduletemplate':
          case 'module':
            const prevState = get(ctx.getState(), type, {});
            ctx.patchState({
              [type]: {
                ...prevState,
                [entityId]: details,
              },
            });
            break;
        }
      }));
  }

  @Action(GetInstancesAction)
  public getInstances(
    { patchState }: StateContext<ModulesStateModel>,
    { moduleId }: GetInstancesAction
  ): Observable<ModuleInstance[]> {
    return this.modulesApiService.getInstances(moduleId)
      .pipe(tap((instances: ModuleInstance[]) => {
        patchState({
          instances: instances,
        });
      }));
  }

  @Action(CreateInstanceAction)
  public createInstance(
    { getState, patchState }: StateContext<ModulesStateModel>,
    { moduleId, instance }: CreateInstanceAction
  ): Observable<ModuleInstance> {
    if (instance) {
      return this.modulesApiService.createInstance(moduleId, instance)
        .pipe(
          catchError((error) => {
            // throw a new error with the error message from the backend.
            throw new Error(getBackendErrorMessage(error));
          }),
          tap((result: ModuleInstance) => {
            const newInstances: ModuleInstance[] = {
              ...getState().instances,
            };

            patchState({
              instances: newInstances,
            });
          })
        );
    }
  }

  @Action(UpdateInstancesAction)
  public updateInstances(
    { getState, patchState }: StateContext<ModulesStateModel>,
    { moduleId, instances }: UpdateInstancesAction
  ): Observable<ModuleInstance[]> {
    return this.modulesApiService.updateInstances(moduleId, instances)
      .pipe(
        catchError((error) => {
          // throw a new error with the error message from the backend.
          throw new Error(getBackendErrorMessage(error));
        }),
        tap((result: ModuleInstance[]) => {
          let allInstances: ModuleInstance[] = Object.values(getState().instances);
          const updatedInstances: ModuleInstance[] = allInstances.map((instance: ModuleInstance) => {
            const foundInstance: ModuleInstance = result.find((i: ModuleInstance) => i.id === instance.id);

            return foundInstance === undefined ? instance : {
              ...foundInstance,
              expirationDate: foundInstance.active ? foundInstance.expirationDate : null,
            };
          });

          patchState({
            instances: updatedInstances,
          });
        })
      );
  }

  @Action(GetInstanceGroups)
  public getInstanceGroups(
    ctx: StateContext<ModulesStateModel>,
    { moduleId }: GetInstanceGroups
  ): Observable<Object> {
    return this.modulesApiService.getInstanceGroups(moduleId)
      .pipe(tap((details: InstanceGroup[]) => {
        const prevState = get(ctx.getState(), 'module', {});
        const storedModules = get(ctx.getState(), 'module');
        const storedModule = get(storedModules, moduleId);

        ctx.patchState({
          ['module']: {
            ...prevState,
            [moduleId]: {
              ...storedModule,
              ['instanceGroups']: details,
            },
          },
        });
      }));
  }

  @Action(CreateInstanceGroup)
  public createInstanceGroup(
    ctx: StateContext<ModulesStateModel>,
    { moduleId, moduleInstance }: CreateInstanceGroup
  ): Observable<Object> {
    return this.modulesApiService.createInstanceGroup(moduleId, moduleInstance)
      .pipe(
        catchError((error) => {
          // throw a new error with the error message from the backend.
          throw new Error(getBackendErrorMessage(error));
        }),
        tap((details: InstanceGroup) => {
          const prevState = get(ctx.getState(), 'module', {});
          const storedModules = get(ctx.getState(), 'module');
          const storedModule = get(storedModules, moduleId);

          ctx.patchState({
            ['module']: {
              ...prevState,
              [moduleId]: {
                ...storedModule,
                ['instanceGroups']: [
                  ...storedModule['instanceGroups'],
                  details,
                ],
              },
            },
          });
          ctx.dispatch(new GetInstanceGroups(moduleId));
        })
      );
  }

  @Action(RenameInstanceGroup)
  public renameInstanceGroup(
    ctx: StateContext<ModulesStateModel>,
    { moduleId, instanceGroupId, name }: RenameInstanceGroup
  ): Observable<void> {
    return this.modulesApiService.renameInstance(moduleId, instanceGroupId, name)
      .pipe(tap(() => {
        ctx.dispatch(new GetInstanceGroups(moduleId));
      }));
  }

  @Action(DeleteInstanceGroup)
  public deleteInstanceGroup(
    ctx: StateContext<ModulesStateModel>,
    { moduleId, instanceGroupId }: DeleteInstanceGroup
  ): Observable<void> {
    return this.modulesApiService.deleteInstance(moduleId, instanceGroupId)
      .pipe(tap(() => {
        ctx.dispatch(new GetInstanceGroups(moduleId));
      }));
  }

  @Action(GetAvailableDefinitionTypesInModule)
  public getAvailableDefinitionTypesInModule(
    ctx: StateContext<ModulesStateModel>,
    { moduleTemplateId }: GetAvailableDefinitionTypesInModule
  ): Observable<DashboardTileTypes[]> {
    return this.modulesApiService.getAvailableDefinitionTypesInModule(moduleTemplateId)
      .pipe(tap((availableDefinitions: DashboardTileTypes[]) => {
        ctx.patchState({ availableDefinitions: availableDefinitions });
      }));
  }
}
