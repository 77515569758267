import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CopyDefinition } from '../page-title/shared.model';
import { PostDefinitionResponse } from '@LG_CORE/folders/folders.model';
import { Observable } from 'rxjs';
import { ConfigService } from '@LIB_UTIL/services/config/config.service';
import { DefinitionType } from '@LIB_UTIL/model/definitiontype';

@Injectable({
  providedIn: 'root',
})
export class FoldersApiService {

  constructor(private http: HttpClient, private cfg: ConfigService) {
  }

  public getFolder(folderId?: number) {
    return this.http.get(`${this.cfg.endpoints.folders}/${folderId ? folderId : ''}`);
  }

  public addFolder(name: string, parentId: number = null) {
    return this.http.post(`${this.cfg.endpoints.folders}/`, { name, parentId });
  }

  public addDefinition(folderId: number, name: string, type: DefinitionType): Observable<PostDefinitionResponse> {
    const folderPath: string|number = folderId === undefined ? 'root' : folderId;
    const url: string = `${this.cfg.endpoints.folders}/${folderPath}/definitions`;

    return this.http.post<PostDefinitionResponse>(url, {
      name: name,
      type: type,
    });
  }

  public deleteFolder(folderId: number) {
    return this.http.delete(`${this.cfg.endpoints.folders}/${folderId}`);
  }

  public deleteDefinition(folderId: number, definitionId: number) {
    const folderPath = folderId === undefined ? 'root' : folderId;

    return this.http.delete(`${this.cfg.endpoints.folders}/${folderPath}/definitions/${definitionId}`);
  }

  public renameFolder(folderId: number, name: string) {
    return this.http.put(`${this.cfg.endpoints.folders}/${folderId}`, { name });
  }

  public renameDefinition(folderId: number, definitionId: number, name: string) {
    const folderPath: string = folderId === undefined ? 'root' : folderId.toString();

    return this.http.put(`${this.cfg.endpoints.folders}/${folderPath}/definitions/${definitionId}`, { name });
  }

  public copyDefinitionToFolder(folderId: number, definitionsAndFoldersToCopy: CopyDefinition) {
    const folderPath: string = folderId ? `${folderId}/` : '';

    return this.http.post(`${this.cfg.endpoints.folders}/${folderPath}copy`, definitionsAndFoldersToCopy);
  }
}
