import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { get } from 'lodash';
import { UserInfo } from '../model/auth.model';
import { AuthApiService } from '../services/auth-api.service';
import {
  GetSlaUrl,
  GetUserInfoAction,
  SetTermsAndConditionsAcceptedAction,
  SetViewModeAction,
  ToggleDarkThemeAction,
  UpdatePhotoAction
} from './auth.actions';
import { AuthStateModel } from './auth.state.model';
import { UsersApiService } from '@LIB_UTIL/services/users-api/users-api.service';
import { environment } from '@LIB_UTIL/environments/environment';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    userInfo: null,
    slaUrl: null,
  },
})
@Injectable()
export class AuthState {

  private authApiService: AuthApiService = inject(AuthApiService);
  private usersApiService: UsersApiService = inject(UsersApiService);

  @Selector()
  public static userInfo(state: AuthStateModel): UserInfo { return state.userInfo; }

  @Selector()
  public static brand(state: AuthStateModel): string { return state.userInfo.brandName; }

  @Selector()
  public static darkTheme(state: AuthStateModel): boolean{ return state.userInfo.isDarkTheme; }

  @Selector()
  public static slaUrl(state: AuthStateModel): string { return state.slaUrl; }

  @Action(GetUserInfoAction)
  public getUserInfoAction({ patchState }: StateContext<AuthStateModel>): Observable<UserInfo> {
    return this.authApiService.getUserInfo()
      .pipe(
        tap((userInfo: UserInfo) => this.checkDarkThemeAvailable(userInfo)),
        tap((userInfo: UserInfo) => patchState({ userInfo: userInfo }))
      );
  }

  /**
   * Set the view mode that the user selected.
   */
  @Action(SetViewModeAction)
  public setViewModeAction(
    { patchState, getState }: StateContext<AuthStateModel>,
    { viewMode }: SetViewModeAction
  ): Observable<Object> {
    return this.authApiService.setViewMode(viewMode)
      .pipe(tap(() => {
        const userInfo: UserInfo = get(getState(), 'userInfo');

        patchState({
          userInfo: {
            ...userInfo,
            viewMode: viewMode,
          },
        });
      }));
  }

  /**
   * Update the photo in the store
   */
  @Action(UpdatePhotoAction)
  public updatePhotoAction(
    { patchState, getState }: StateContext<AuthStateModel>,
    { photoBase64 }: UpdatePhotoAction
  ): void {

    const userInfo: UserInfo = getState().userInfo;

    patchState({
      userInfo: {
        ...userInfo,
        photoBase64: photoBase64,
      },
    });
  }

  /**
   * Let bakcend know that the user accepted the terms and conditions
   */
  @Action(SetTermsAndConditionsAcceptedAction)
  public setTermsAndConditionsAcceptedAction(
    {}: StateContext<AuthStateModel>,
    { isTermsConditionsAccepted }: SetTermsAndConditionsAcceptedAction
  ): Observable<Object> {
    return this.authApiService.setTermsAndConditionsAccepted(isTermsConditionsAccepted);
  }

  /**
   * Get the SLA URL based on user manager module status
   */
  @Action(GetSlaUrl)
  public getSlaUrl({ patchState }: StateContext<AuthStateModel>): Observable<string> {
    return this.authApiService.isUserManagerModule()
      .pipe(
        switchMap((isModuleManager: boolean) => {
          if (isModuleManager) {
            return this.authApiService.getServiceLevelAgreementUrl(isModuleManager);
          } else {
            return of(null);
          }
        }),
        tap((slaUrl: string | null) => {
          patchState({ slaUrl: slaUrl });
        })
      );
  }

  /**
   * Update the photo in the store
   */
  @Action(ToggleDarkThemeAction)
  public toggleDarkThemeAction(
    { patchState, getState }: StateContext<AuthStateModel>
  ): Observable<void> {

    const userInfo: UserInfo = getState().userInfo;

    return this.usersApiService.setTheme(!userInfo.isDarkTheme).pipe(
      tap(() => {
        patchState({
          userInfo: {
            ...userInfo,
            isDarkTheme: !userInfo.isDarkTheme,
          },
        });

      })
    );
  }

  private checkDarkThemeAvailable(userInfo: UserInfo): UserInfo {
    const darkThemeAvailbable: boolean = environment
      .showDarkThemeForBrands?.toLocaleLowerCase()
      .includes(userInfo.brandName.toLocaleLowerCase());

    return !darkThemeAvailbable
      ? { ...userInfo, isDarkTheme: false }
      : userInfo;
  }
}
