import { AfterViewInit, Component, ElementRef, Input, ViewChild, inject } from '@angular/core';

@Component({
  selector: 'lib-colored-icon',
  templateUrl: './colored-icon.component.html',
  styleUrls: ['./colored-icon.component.scss'],
})
export class ColoredIconComponent implements AfterViewInit {

  private elementRef: ElementRef = inject(ElementRef);

  @Input() public fontIcon: string = '';
  @Input() public fontSet: string = 'fas';
  @Input() public size: number = 24;

  @ViewChild('iconContainer') public menuIcon: ElementRef<HTMLElement>;

  public ngAfterViewInit(): void {
    const element: HTMLElement = this.elementRef?.nativeElement;

    if (!element) {
      return;
    }

    element.style.setProperty('--icon-size', `${this.size}px`);
  }
}
